import { get, head } from 'lodash';
import { useParams } from 'react-router-dom';

import { Selectors as ClaimsSelectors } from '@savgroup-front-common/core/src/domains/claims';
import { Selectors as WorkflowSelectors } from '@savgroup-front-common/core/src/domains/workflow';
import { useMyAccountTypedSelector } from 'myaccount/hooks';

import { Selectors as CustomerSelectors } from '../../../../../../domains/Customer';
import { customerState } from '../../../../../../domains/Customer/selectors';
import { handlingSummary as handlingSummarySelector } from '../../../../../../domains/Views/handlingSummary';

const useGetClientData = () => {
  const { fileId } = useParams<{ fileId: string }>();

  const customer = useMyAccountTypedSelector(customerState);
  const { fileStates } = customer;
  const isLoading = useMyAccountTypedSelector(
    (state) => state.fileTrackingPage.isLoading,
  );
  const invoiceToPay = useMyAccountTypedSelector(
    CustomerSelectors.invoiceToPay,
  );
  const handlingSummary = useMyAccountTypedSelector((state) =>
    handlingSummarySelector(state, fileId),
  );
  const appointment = useMyAccountTypedSelector(
    WorkflowSelectors.appointmentsSelector,
  );

  const latestState = head(fileStates);

  const claimGroupId = get(customer, [
    'customerFileSummary',
    'claimInfoSummary',
    'claimGroupId',
  ]);
  const confirmationClaimGroupValue = useMyAccountTypedSelector((state) =>
    ClaimsSelectors.getClaimGroupsConfirmationValue(state, claimGroupId),
  );

  const firstAction = head(customer?.availableFileActions);

  const loadingStatuses = {
    fileDownloadInProgress: customer.fileDownloadInProgress,
    isLoading,
  };
  const fileActions = customer.availableFileActions;
  const workflowInfos = {
    currentStatus: latestState?.state,
    currentModule: latestState?.module,
    fileId,
    file: customer.file,
  };
  const fileSummary = customer.customerFileSummary;
  const invoice = invoiceToPay;
  const { claimConfirmationData } = customer;
  const appointmentStatus = appointment.getIn([fileId]);
  const { isStateUpdatePending } = customer;

  const transitions = firstAction?.transitions;
  const module = firstAction?.module;
  const fileStatusName = firstAction?.fileStatus?.name;
  const invoiceId = invoice?.id;

  const startTime = new Date();

  startTime.setMinutes(0, 0, 0);

  const endTime = new Date();

  endTime.setMonth(endTime.getMonth() + 1);
  endTime.setMinutes(0, 0, 0);

  return {
    action: firstAction,
    confirmationClaimGroupValue: confirmationClaimGroupValue?.value,
    loadingStatuses,
    fileActions,
    workflowInfos,
    fileSummary,
    handlingSummary,
    invoice,
    claimConfirmationData,
    appointmentStatus,
    isStateUpdatePending,
    fileId,
    module,
    transitions,
    fileStatusName,
    invoiceId,
  };
};

export default useGetClientData;
