import React, { FC, PropsWithChildren } from 'react';

import { $PanelBodyLine } from './PanelBodyLine.styles';

export interface PanelBodyLineProps {
  isActive?: boolean;
  isSelected?: boolean;
  gridTemplateColumns: string;
}

export const PanelBodyLine: FC<PropsWithChildren<PanelBodyLineProps>> = ({
  children,
  isActive = false,
  isSelected = true,
  gridTemplateColumns,
}) => {
  return (
    <$PanelBodyLine
      $isActive={isActive}
      $isSelected={isSelected}
      $gridTemplateColumns={gridTemplateColumns}
    >
      {children}
    </$PanelBodyLine>
  );
};
