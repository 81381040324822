import styled from 'styled-components';

export const $PanelBodyLine = styled.div<{
  $isActive: boolean;
  $isSelected: boolean;
  $gridTemplateColumns: string;
}>`
  text-align: left;
  opacity: ${({ $isSelected }) => ($isSelected ? undefined : 0.4)};

  & > div {
    padding: 1rem;
    color: ${({ theme, $isActive }) =>
      $isActive ? theme.colors.mainTextColor : theme.colors.paragraphTextColor};
  }

  display: grid;
  grid-template-columns: ${({ $gridTemplateColumns }) => $gridTemplateColumns};
`;

export const $AmountCell = styled.div`
  text-align: right;
`;
