import * as Yup from 'yup';

import { globalMessages } from '@savgroup-front-common/core/src/helpers';

const chooseProductSchema = Yup.object().shape({
  selectedOwnerProductIds: Yup.array()
    .of(Yup.string())
    .min(1, globalMessages.form.required),
});

export default chooseProductSchema;
