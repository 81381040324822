import * as Yup from 'yup';

import { AdditionalInformationSchema } from '@savgroup-front-common/core/src/components/AdditionalInformationWithReactHookForm';
import { FullClaim } from '@savgroup-front-common/types';
import { AdditionalInformationExtended } from 'myaccount/types';

const newClaimGroupDocumentPageRelatedToProductSchema = ({
  claims,
  neededInformationDocumentOnlyRelatedToProduct,
}: {
  claims: FullClaim[];
  neededInformationDocumentOnlyRelatedToProduct?: AdditionalInformationExtended[];
}) => {
  const schema = claims?.reduce((acc, curr) => {
    if (curr) {
      const neededInformationOnlyRelatedToProductFiltered =
        neededInformationDocumentOnlyRelatedToProduct?.filter(
          (item) =>
            item.currentClaimId === curr.claimId &&
            item.claimIds.includes(curr.claimId),
        );

      const reasonAdditionalInformationSchema = AdditionalInformationSchema({
        neededInformationList: neededInformationOnlyRelatedToProductFiltered,
      });

      return {
        ...acc,
        [curr.claimId]: Yup.object().shape({
          reasonAdditionalInformationDocumentRelatedToProduct:
            reasonAdditionalInformationSchema,
        }),
      };
    }

    return acc;
  }, {});

  return Yup.object().shape({
    ...schema,
  });
};

export default newClaimGroupDocumentPageRelatedToProductSchema;
