import React, { FC, PropsWithChildren } from 'react';

import { BaseLoader } from '@savgroup-front-common/core/src/molecules/BaseLoader';
import { useRoutedStepsOrchestratorContext } from '@savgroup-front-common/core/src/molecules/RoutedStepsOrchestrator/RoutedStepsOrchestrator.context';

import useGetClaimGroupSummary from '../hooks/useGetClaimGroupSummary';
import { useInitializeClaimGroupId } from '../hooks/useInitializeClaimGroupId';
import { useInitializeIssue } from '../hooks/useInitializeIssue';
import { useInitializeReason } from '../hooks/useInitializeReason';
import { ClaimGroupReasonPageStepValues } from '../NewClaimGroupReasonPage/NewClaimGroupReasonPage.types';

export const NewClaimGroupProductInfoWrapper: FC<PropsWithChildren> = ({
  children,
}) => {
  const { values } =
    useRoutedStepsOrchestratorContext<ClaimGroupReasonPageStepValues>();

  const claimGroupId = values?.claimGroupId;

  const { claims } = useGetClaimGroupSummary({
    claimGroupId,
    suspense: true,
  });

  const firstClaim = claims?.at(0);
  const reason = values?.reason;

  useInitializeIssue({
    claim: firstClaim,
  });
  useInitializeClaimGroupId();
  useInitializeReason({
    claimGroupId,
    claim: firstClaim,
  });

  const isClaimsLoaded = claimGroupId && !!claims?.length;

  const hasReasonToLoad = isClaimsLoaded && firstClaim?.reasonId;
  const isReasonOk = hasReasonToLoad ? !!reason : true;

  if (!isClaimsLoaded || !isReasonOk) {
    return <BaseLoader />;
  }

  return <>{children}</>;
};
