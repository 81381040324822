import moment from 'moment';

import {
  ADDITIONAL_INFORMATION_TYPES,
  AdditionalInformation,
} from '@savgroup-front-common/types';

const DEFAULT_DATETIME_FORMAT = 'YYYY-MM-DDT00:00:00.000ZZ';

export const computeAdditionalInformationValue = ({
  value,
  type,
}: {
  value?: AdditionalInformation;
  type: ADDITIONAL_INFORMATION_TYPES;
}) => {
  const additionalInformationEnumValue = value?.additionalInformationEnumValue;
  const additionalInformationFileValue =
    value?.additionalInformationFileValue as any;
  const additionalInformationStringValue =
    value?.additionalInformationStringValue;

  if (additionalInformationEnumValue) {
    return {
      value: additionalInformationEnumValue,
    };
  }

  if (type === ADDITIONAL_INFORMATION_TYPES.MULTI_FILES) {
    return value?.additionalInformationFilesValue
      ?.map((item: any) => {
        if (!item || !item.data || !item.filename || !item.data?.type) {
          return undefined;
        }

        return {
          additionalInformationStringValue,
          value: new File([item.data], item.filename, {
            lastModified: new Date().getTime(),
            type: item.data.type,
          }),
        };
      })
      .filter((x) => !!x) as { value: File }[];
  }

  if (type === ADDITIONAL_INFORMATION_TYPES.FILE) {
    if (
      additionalInformationFileValue &&
      (!additionalInformationFileValue.data ||
        !additionalInformationFileValue.filename ||
        !additionalInformationFileValue.data?.type)
    ) {
      return undefined;
    }

    return additionalInformationFileValue
      ? {
          additionalInformationStringValue,
          value: new File(
            [additionalInformationFileValue.data],
            additionalInformationFileValue.filename,
            {
              lastModified: new Date().getTime(),
              type: additionalInformationFileValue.data.type,
            },
          ),
        }
      : undefined;
  }

  if (
    additionalInformationStringValue &&
    (type === ADDITIONAL_INFORMATION_TYPES.IRIS_CONDITION_CODE ||
      type === ADDITIONAL_INFORMATION_TYPES.IRIS_EXTENDED_CONDITION_CODE ||
      type === ADDITIONAL_INFORMATION_TYPES.IRIS_SYMPTOM_CODE ||
      type === ADDITIONAL_INFORMATION_TYPES.IRIS_SECTION_CODE ||
      type === ADDITIONAL_INFORMATION_TYPES.IRIS_DEFECT_CODE ||
      type === ADDITIONAL_INFORMATION_TYPES.IRIS_REPAIR_CODE)
  ) {
    return {
      value: additionalInformationStringValue,
    };
  }

  if (type === ADDITIONAL_INFORMATION_TYPES.DATE) {
    return additionalInformationStringValue
      ? moment(additionalInformationStringValue, DEFAULT_DATETIME_FORMAT)
      : undefined;
  }

  if (additionalInformationStringValue) {
    return additionalInformationStringValue;
  }

  return undefined;
};
