import { useDeepCompareEffect } from 'react-use';

import { useRoutedStepsOrchestratorContext } from '@savgroup-front-common/core/src/molecules/RoutedStepsOrchestrator/RoutedStepsOrchestrator.context';
import { ClaimContext } from '@savgroup-front-common/types';

import { IrshStepValues } from '../IrshPages.types';

import { useGetSolutionsByClaim } from './useGetSolutionsByClaim';

export const useInitializeSolution = ({
  claimGroupId,
  claim,
}: {
  claimGroupId?: string;
  claim?: ClaimContext;
}) => {
  const { values, onValuesUpdate } =
    useRoutedStepsOrchestratorContext<IrshStepValues>();

  const { solutions } = useGetSolutionsByClaim({
    claimGroupId,
    suspense: true,
  });

  const solution = solutions?.find(
    (solution) => claim && solution.solutionTypeId === claim.solutionTypeId,
  );

  useDeepCompareEffect(() => {
    if (!values?.solution && solution) {
      onValuesUpdate({
        newValues: {
          solution,
        },
      });
    }
  }, [onValuesUpdate, values?.solution, solution]);

  return { solutions };
};
