import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { useRoutedStepsOrchestratorContext } from '@savgroup-front-common/core/src/molecules/RoutedStepsOrchestrator/RoutedStepsOrchestrator.context';

import { IrshStepValues } from '../IrshPages.types';

export const useInitializeClaimGroupId = () => {
  const { claimGroupId } = useParams<{ claimGroupId: string }>();

  const { values, onValuesUpdate } =
    useRoutedStepsOrchestratorContext<IrshStepValues>();

  useEffect(() => {
    if (claimGroupId && !values.claimGroupId) {
      onValuesUpdate({
        newValues: {
          claimGroupId,
        },
      });
    }
  }, [claimGroupId, onValuesUpdate, values.claimGroupId]);
};
