import React from 'react';
import { Controller, FormProvider } from 'react-hook-form';
import { generatePath, useHistory, useParams } from 'react-router-dom';

import { BUTTON_TYPES } from '@savgroup-front-common/constants';
import { Button } from '@savgroup-front-common/core/src/atoms/button';
import {
  Autocomplete,
  FormGroup,
} from '@savgroup-front-common/core/src/atoms/Form';
import {
  safeFormattedIntlString,
  SafeFormattedMessageWithoutSpread,
} from '@savgroup-front-common/core/src/formatters';
import { SUPPORTED_ICON_POSITIONS } from '@savgroup-front-common/core/src/helpers';
import { BaseLoader } from '@savgroup-front-common/core/src/molecules/BaseLoader';
import { useRoutedStepsOrchestratorContext } from '@savgroup-front-common/core/src/molecules/RoutedStepsOrchestrator/RoutedStepsOrchestrator.context';
import { RoutedStepProps } from '@savgroup-front-common/core/src/molecules/RoutedStepsOrchestrator/RoutedStepsOrchestrator.types';
import ArrowNakedLeftIcon from '@savgroup-front-common/core/src/protons/icons/ArrowNaked/ArrowNakedLeft.icon';
import { ArrowNakedRightIcon } from '@savgroup-front-common/core/src/protons/icons/ArrowNaked/ArrowNakedRight.icon';

import { ROUTES_NEW_DESIGN } from '../../../app';
import { STEP_CONFIG } from '../../../app/NewLayout/ClassiqueRoutes.config';
import {
  NewStepLayout,
  PageFooterContainer,
} from '../../../app/NewLayout/NewStepLayout';
import { IrshStepValues } from '../IrshPages.types';

import ClaimGroupReasonPageAdditionalInformationsContent from './ClaimGroupReasonPageAdditionalInformationsContent';
import ClaimGroupReasonPageCommentContent from './ClaimGroupReasonPageCommentContent';
import ClaimGroupReasonPageDiagnosticTreeEntry from './ClaimGroupReasonPageDiagnosticTreeEntry/ClaimGroupReasonPageDiagnosticTreeEntry';
import { neededInformationFiltered } from './helpers/reason.adapters';
import messages from './messages';
import useClaimGroupReason from './NewClaimGroupReasonPage.hooks';

export const NewClaimGroupReasonPage: React.FC<
  RoutedStepProps<IrshStepValues>
> = ({ onPreviousStep, onNextStep }) => {
  const history = useHistory();

  const { claimGroupId } = useParams<{
    claimGroupId: string;
  }>();

  const { onValuesUpdate } =
    useRoutedStepsOrchestratorContext<IrshStepValues>();

  const {
    reasonOptions,
    reasonAdapted,
    isMultiProduct,
    onSubmit,
    formContext,
    isLoading,
    isLoadingSubmit,
    isDiagnosticTreeAvailable,
  } = useClaimGroupReason({
    onNextStep,
  });

  const {
    control,
    formState: { errors, isValid },
  } = formContext;

  const neededInformationFilteredResult = neededInformationFiltered(
    reasonAdapted?.neededInformation,
    isMultiProduct,
  );

  const isDiagnosticTreeUnavailable = !isDiagnosticTreeAvailable;

  const shouldShowReasonContent = Boolean(
    neededInformationFilteredResult?.length && isDiagnosticTreeUnavailable,
  );

  const shouldShowReasonComment = Boolean(
    reasonAdapted?.mandatoryComment && isDiagnosticTreeUnavailable,
  );

  if (isLoading) {
    return <BaseLoader />;
  }

  return (
    <FormProvider {...formContext}>
      <form onSubmit={onSubmit}>
        <NewStepLayout stepName={STEP_CONFIG.CLAIM_GROUP_REASON}>
          <FormGroup dataTestId="dropdown-select-reason">
            <Controller
              control={control}
              name="reason"
              render={({ field }) => (
                <Autocomplete
                  name={field.name}
                  options={reasonOptions}
                  isRequired
                  ref={field.ref}
                  onChange={(option) => {
                    if (option && option?.data) {
                      onValuesUpdate({
                        newValues: {
                          reason: option?.data,
                        },
                      });

                      field.onChange(option);
                    }
                  }}
                  value={field.value}
                  errors={errors}
                  label={safeFormattedIntlString(messages.selectReason)}
                  shouldAutoSelectFirstOptions={reasonOptions.length === 1}
                />
              )}
            />
          </FormGroup>

          {shouldShowReasonContent && (
            <ClaimGroupReasonPageAdditionalInformationsContent
              formContext={formContext}
              neededInformation={neededInformationFilteredResult as any}
            />
          )}

          {shouldShowReasonComment && (
            <ClaimGroupReasonPageCommentContent formContext={formContext} />
          )}

          {isDiagnosticTreeAvailable && (
            <ClaimGroupReasonPageDiagnosticTreeEntry
              onRefuseDiagnosticTree={() =>
                onValuesUpdate({
                  newValues: {
                    diagnosticTree: {
                      hasRefusedDiagnosticTree: true,
                    },
                  },
                })
              }
              onAcceptDiagnosticTree={() => {
                history.push(
                  generatePath(ROUTES_NEW_DESIGN.CLAIM_GROUP_DIAGNOSTIC_TREE, {
                    claimGroupId,
                    questionId: reasonAdapted?.diagnosticTreeUniqueName || '',
                    step: `step1`,
                  }),
                );
              }}
            />
          )}
        </NewStepLayout>

        {!isDiagnosticTreeAvailable && (
          <PageFooterContainer>
            <Button
              type={BUTTON_TYPES.BUTTON}
              secondary
              naked
              onClick={() => {
                onPreviousStep({
                  newValues: {
                    diagnosticTree: {
                      isDiagnosticIsAlreadyMade: false,
                      diagnosticTreeUniqueName: undefined,
                      hasRefusedDiagnosticTree: false,
                    },
                    claims: undefined,
                    claimGroupId: undefined,
                    reason: undefined,
                    handling: undefined,
                    handlingDelivery: undefined,
                    handlingDeposit: undefined,
                    solution: undefined,
                  },
                });
              }}
              icon={<ArrowNakedLeftIcon />}
              position={SUPPORTED_ICON_POSITIONS.LEFT}
            >
              <SafeFormattedMessageWithoutSpread
                message={messages.previousButton}
              />
            </Button>

            <Button
              primary
              type={BUTTON_TYPES.BUTTON}
              icon={<ArrowNakedRightIcon />}
              position={SUPPORTED_ICON_POSITIONS.RIGHT}
              disabled={!isValid}
              isLoading={isLoadingSubmit}
              onClick={onSubmit}
            >
              <SafeFormattedMessageWithoutSpread
                message={messages.nextButton}
              />
            </Button>
          </PageFooterContainer>
        )}
      </form>
    </FormProvider>
  );
};
