import React, { createContext, ReactElement, useContext } from 'react';

import { RoutedStepsOrchestratorConfig } from './RoutedStepsOrchestrator.types';

export type StepOrchestratorContextArgs<Values, Context> = {
  values: Values;
  context: Context;
  valuesFromParent?: Record<string, unknown>;
  config: RoutedStepsOrchestratorConfig<Values, Context>;
  onValuesUpdate: ({ newValues }: { newValues: Partial<Values> }) => void;
  onContextUpdate: ({ newContext }: { newContext: Partial<Context> }) => void;
  onValuesReset: () => void;
};

export const createStepOrchestratorContext = <Values, Context>() => {
  return createContext<{
    values: Values;
    context: Context;
    valuesFromParent?: Record<string, unknown>;
    config: RoutedStepsOrchestratorConfig<Values, Context>;
    onValuesUpdate: ({ newValues }: { newValues: Partial<Values> }) => void;
    onContextUpdate: ({ newContext }: { newContext: Partial<Context> }) => void;
    onValuesReset: () => void;
  }>({
    config: [],
    context: {} as Context,
    values: {} as Values,
    valuesFromParent: {},
    onValuesUpdate: () => undefined,
    onContextUpdate: () => undefined,
    onValuesReset: () => undefined,
  });
};

const StepOrchestratorContext = createStepOrchestratorContext<any, any>();

function StepOrchestratorProvider<
  Values = Record<string, unknown>,
  Context = Record<string, unknown>,
>({
  children,
  values,
}: React.PropsWithChildren<{
  values: StepOrchestratorContextArgs<Values, Context>;
}>): ReactElement {
  return (
    <StepOrchestratorContext.Provider value={values}>
      {children}
    </StepOrchestratorContext.Provider>
  );
}

export const useRoutedStepsOrchestratorContext = <
  Values = Record<string, unknown>,
  Context = Record<string, unknown>,
>() =>
  useContext<StepOrchestratorContextArgs<Values, Context>>(
    StepOrchestratorContext,
  );

export default StepOrchestratorProvider;
