import { useEffect, useState } from 'react';

import { useRoutedStepsOrchestratorContext } from '@savgroup-front-common/core/src/molecules/RoutedStepsOrchestrator/RoutedStepsOrchestrator.context';
import { RoutedStepProps } from '@savgroup-front-common/core/src/molecules/RoutedStepsOrchestrator/RoutedStepsOrchestrator.types';
import { OrderSummary } from '@savgroup-front-common/types';

import useGetIssuesByOrder from '../../../app/hooks/useGetIssuesByOrder';
import { IrshStepValues } from '../IrshPages.types';

interface UseIssuesPageArgs {
  orders: OrderSummary[] | undefined;
  onNextStep: RoutedStepProps<IrshStepValues>['onNextStep'];
}

const useIssuesPage = ({ orders, onNextStep }: UseIssuesPageArgs) => {
  const { values } = useRoutedStepsOrchestratorContext<IrshStepValues>();
  const issue = values?.issue;

  const [isSkipLoading, setIsSkipLoading] = useState(false);

  const orderIds = orders?.map((order) => order.orderId);
  const orderId = orderIds?.at(0) || undefined;

  const { issues, isIssuesLoading } = useGetIssuesByOrder({
    orderId,
  });

  useEffect(() => {
    if (issues?.length === 1 && !issue?.id) {
      const issue = issues[0];

      setIsSkipLoading(true);

      onNextStep({
        newValues: { issue },
      });
    }
  }, [issues, issue?.id, onNextStep]);

  return {
    issues: issues || [],
    isIssuesLoading,
    isSkipLoading,
  };
};

export default useIssuesPage;
