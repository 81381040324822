import React, { useState } from 'react';
import { FormProvider } from 'react-hook-form';
import { useMedia } from 'react-use';

import { BUTTON_TYPES, media } from '@savgroup-front-common/constants';
import { Button } from '@savgroup-front-common/core/src/atoms/button';
import { Modal } from '@savgroup-front-common/core/src/atoms/modal';
import SwipeableDrawer, {
  DRAWER_SIZE,
} from '@savgroup-front-common/core/src/atoms/SwipeableDrawer/SwipeableDrawer';
import { SafeFormattedMessageWithoutSpread } from '@savgroup-front-common/core/src/formatters';
import { SUPPORTED_ICON_POSITIONS } from '@savgroup-front-common/core/src/helpers';
import { BaseLoader } from '@savgroup-front-common/core/src/molecules/BaseLoader';
import { useRoutedStepsOrchestratorContext } from '@savgroup-front-common/core/src/molecules/RoutedStepsOrchestrator/RoutedStepsOrchestrator.context';
import { RoutedStepProps } from '@savgroup-front-common/core/src/molecules/RoutedStepsOrchestrator/RoutedStepsOrchestrator.types';
import ArrowNakedLeftIcon from '@savgroup-front-common/core/src/protons/icons/ArrowNaked/ArrowNakedLeft.icon';
import { ArrowNakedRightIcon } from '@savgroup-front-common/core/src/protons/icons/ArrowNaked/ArrowNakedRight.icon';
import { MyAccountIcon } from '@savgroup-front-common/core/src/protons/IconsNewDesign/MyAccount.icon';
import { PickupPoint } from '@savgroup-front-common/types';
import { ICONS_TYPE } from '@savgroup-front-common/types/src/Icon';
import useGetPickupPoints from 'myaccount/view/app/hooks/useGetPickupPoints';
import { ResumeCard } from 'myaccount/view/components/ResumeCard';
import { ResumeCardButton } from 'myaccount/view/components/ResumeCardButton';

import { STEP_CONFIG } from '../../../app/NewLayout/ClassiqueRoutes.config';
import {
  NewStepLayout,
  PageFooterContainer,
} from '../../../app/NewLayout/NewStepLayout';
import { AddressManagement } from '../../../components/AddressManagement';
import HandlingCard from '../../../components/HandlingCard';
import PickupPointSelector from '../../../components/PickupPointSelector';
import { ResumeDeposit } from '../../../components/ResumeDeposit';
import { ResumeSolution } from '../../../components/ResumeSolution';
import { IrshStepValues } from '../IrshPages.types';

import messages from './messages';
import useClaimGroupDeliveryPage from './NewClaimGroupDeliveryPage.hooks';
import {
  $DeliveryItemWrapper,
  $DeliveryResultWrapper,
  $DeliveryWrapper,
  $Heading,
  $HeadingIcon,
  $LeftColumn,
} from './NewClaimGroupDeliveryPage.styles';

export const NewClaimGroupDeliveryPage: React.FC<
  RoutedStepProps<IrshStepValues>
> = ({ onNextStep, onPreviousStep }) => {
  const isMobileView = useMedia(media.maxWidth.xs);
  const [openResumeModal, setOpenResumeModal] = useState(false);
  const {
    claimGroupConfirmation,
    groupedCarriers,
    claimGroupId,
    formContext,
    setChangedValue,
    changeSelection,
    onSubmit,
    isDeliveryHandlingLoading,
    isLoadingSubmit,
    chooseHandlingSelected,
    handlingSelected,
    homeSelectedAddressChanged,
    openModalRelayPointDelivery,
    handleOpenDeliveryModal,
  } = useClaimGroupDeliveryPage({ onNextStep });

  const { values } = useRoutedStepsOrchestratorContext<IrshStepValues>();
  const handling = values?.handling;

  const hasDeposit = handling?.hasDeposit;

  const {
    setValue,
    formState: { isValid },
  } = formContext;

  const {
    sellerId,
    carriers,
    addressSelected,
    pickupPoints,
    noPickupPoints,
    addresses,
    handleSetAddressSelected,
    selectedPickupPoint,
    pickupPointsIsLoading,
  } = useGetPickupPoints({
    selectedCarrierType: chooseHandlingSelected,
    groupedCarriers,
    claimGroupId,
  });

  const handleSetSelectedPickupPoint = (pickupPointSelected: PickupPoint) => {
    Promise.resolve(setValue('pickupPointSelected', pickupPointSelected)).then(
      () => onSubmit(),
    );
  };

  const handleOpenModal = () => setOpenResumeModal((prev) => !prev);

  return (
    <>
      <FormProvider {...formContext}>
        <form onSubmit={onSubmit}>
          <NewStepLayout stepName={STEP_CONFIG.CLAIM_GROUP_DELIVERY}>
            <$DeliveryWrapper>
              <$LeftColumn>
                {claimGroupId && (
                  <ResumeSolution
                    claimGroupId={claimGroupId}
                    claimGroupConfirmation={claimGroupConfirmation}
                  />
                )}
                {hasDeposit && (
                  <ResumeDeposit
                    claimGroupConfirmation={claimGroupConfirmation}
                  />
                )}
                <$Heading data-testid="handling-delivery-title">
                  <$HeadingIcon>
                    <MyAccountIcon icon={ICONS_TYPE.DELIVERY_ICON} />
                  </$HeadingIcon>
                  <SafeFormattedMessageWithoutSpread
                    message={messages.handlingTitle}
                  />
                </$Heading>
                <$DeliveryItemWrapper>
                  {isDeliveryHandlingLoading && <BaseLoader />}

                  {!isDeliveryHandlingLoading && (
                    <HandlingCard
                      groupedCarriers={groupedCarriers}
                      setChangedValue={setChangedValue}
                      handleOpenModal={handleOpenDeliveryModal}
                    />
                  )}
                </$DeliveryItemWrapper>
              </$LeftColumn>
              <$DeliveryResultWrapper>
                {isMobileView && (
                  <ResumeCardButton
                    claimGroupConfirmation={claimGroupConfirmation}
                    handleOpenResumeModal={handleOpenModal}
                  />
                )}
                {!isMobileView && (
                  <ResumeCard
                    claimGroupConfirmation={claimGroupConfirmation}
                    hasPrice
                    hasDepositPrice
                  />
                )}
              </$DeliveryResultWrapper>
            </$DeliveryWrapper>
          </NewStepLayout>

          <PageFooterContainer>
            <Button
              type={BUTTON_TYPES.BUTTON}
              secondary
              naked
              onClick={() => {
                onPreviousStep({});
              }}
              icon={<ArrowNakedLeftIcon />}
              position={SUPPORTED_ICON_POSITIONS.LEFT}
            >
              <SafeFormattedMessageWithoutSpread
                message={messages.previousButton}
              />
            </Button>

            <Button
              primary
              type={BUTTON_TYPES.BUTTON}
              icon={<ArrowNakedRightIcon />}
              position={SUPPORTED_ICON_POSITIONS.RIGHT}
              disabled={!isValid}
              isLoading={isLoadingSubmit}
              onClick={onSubmit}
            >
              <SafeFormattedMessageWithoutSpread
                message={messages.nextButton}
              />
            </Button>
          </PageFooterContainer>

          <SwipeableDrawer
            isOpen={handlingSelected || openModalRelayPointDelivery}
            onClose={changeSelection}
            hasBackdrop
            hasPadding={false}
            hasBackdropScroll={false}
            hasCloseButton={false}
          >
            {handlingSelected && (
              <PickupPointSelector
                maxDistance={200}
                pickupPoints={pickupPoints}
                selectedPickupPointId={
                  selectedPickupPoint ? selectedPickupPoint.id : undefined
                }
                onSelectPickupPoint={handleSetSelectedPickupPoint}
                searchAddress={addressSelected}
                userAddresses={addresses}
                onAddressSelected={handleSetAddressSelected}
                carriers={carriers}
                onClose={changeSelection}
                pickupPointsIsLoading={pickupPointsIsLoading}
                sellerId={sellerId}
                noPickupPoints={noPickupPoints}
              />
            )}
          </SwipeableDrawer>

          <SwipeableDrawer
            isOpen={homeSelectedAddressChanged}
            size={DRAWER_SIZE.SMALL}
            onClose={changeSelection}
            hasBackdrop
            hasBackdropScroll={false}
          >
            <AddressManagement />
          </SwipeableDrawer>
        </form>
      </FormProvider>
      <Modal
        isOpen={openResumeModal}
        onClose={handleOpenModal}
        shouldShowPadding={false}
      >
        <ResumeCard claimGroupConfirmation={claimGroupConfirmation} />
      </Modal>
    </>
  );
};
