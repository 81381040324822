import { transparentize } from 'polished';
import styled from 'styled-components';

import {
  getCustomComponentProperty,
  rem,
} from '@savgroup-front-common/core/src/helpers';
import {
  LOGO_POSITION,
  SUPPORTED_PROPERTIES,
} from '@savgroup-front-common/types';

interface HeaderContainerProps {
  componentThemeName: string;
  enableServicePortal?: boolean;
}

export const $HeaderContainer = styled('div')<HeaderContainerProps>`
  width: 100%;
  padding-left: 24px;
  padding-right: 24px;
  height: 64px;
  background-color: ${({ theme, componentThemeName }) => {
    return getCustomComponentProperty({
      theme,
      componentName: componentThemeName,
      property: SUPPORTED_PROPERTIES.BACKGROUND_COLOR,
      fallback: theme.colors.white,
    });
  }};
  border-bottom: ${({ theme }) =>
    `${theme.borders.thickness} solid ${transparentize(
      0.75,
      theme.colors.default,
    )}`};
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.06);
`;

export const $MenuContainer = styled.div<{ $position: LOGO_POSITION }>`
  position: relative;
  align-items: center;
  height: ${rem(64)};
  display: flex;

  flex-direction: ${({ $position }) => {
    if ($position === LOGO_POSITION.RIGHT) {
      return 'row-reverse';
    }

    return 'row';
  }};

  justify-content: space-between;
`;

interface LogoContainerProps {
  componentThemeName: string;
  $isMobileView?: boolean;
}

export const $LogoContainer = styled('div')<LogoContainerProps>`
  display: block;
  width: 100%;
  height: 45px;
  cursor: pointer;

  padding: ${({ componentThemeName, theme }) => {
    const themeValue = getCustomComponentProperty({
      theme,
      componentName: componentThemeName,
      property: SUPPORTED_PROPERTIES.PADDING,
    });

    return themeValue || '0px 0px';
  }};

  position: relative;
  text-align: ${({ componentThemeName, theme, $isMobileView }) => {
    if ($isMobileView) {
      return 'left';
    }

    return getCustomComponentProperty({
      theme,
      componentName: componentThemeName,
      property: SUPPORTED_PROPERTIES.TEXT_ALIGN,
      fallback: 'left',
    });
  }};
`;

export const $RightSide = styled.div`
  display: flex;
`;

export const $EmptyDiv = styled.div<{ $width: number }>`
  width: ${({ $width }) => `${$width}px`};
`;
