import { ActionTypeIsNotSupportedError } from '@savgroup-front-common/exceptions';

import {
  STEPS_ORCHESTRATOR_ACTION_TYPES as ACTION_TYPES,
  RoutedStepsOrchestratorConfig,
} from './RoutedStepsOrchestrator.types';

export interface RoutedStepsOrchestratorState<Values, Context> {
  config: RoutedStepsOrchestratorConfig<Values, Context>;
  context: Partial<Context>;
  values: Partial<Values>;
}

export type RoutedStepsOrchestratorAction<Values, Context> =
  | {
      type: ACTION_TYPES.UPDATE_VALUES;
      payload: { newValues: Partial<Values> };
    }
  | {
      type: ACTION_TYPES.UPDATE_CONTEXT;
      payload: { newContext: Partial<Context> };
    }
  | {
      type: ACTION_TYPES.RESET;
      payload: {
        initialContext?: Partial<Context>;
        initialValues?: Partial<Values>;
      };
    }
  | {
      type: ACTION_TYPES.NEXT_STEP;
      payload: {
        context?: Partial<Context>;
        values?: Partial<Values>;
      };
    }
  | {
      type: ACTION_TYPES.PREVIOUS_STEP;
      payload: {
        context?: Partial<Context>;
        values?: Partial<Values>;
      };
    };

export interface StepsOrchestratorInitArgs<Values, Context> {
  config: RoutedStepsOrchestratorConfig<Values, Context>;
  initialContext: Partial<Context>;
  initialValues: Partial<Values>;
}

export const initializeRoutedStepsOrchestrator = <Values, Context>({
  config,
  initialContext,
  initialValues,
}: StepsOrchestratorInitArgs<Values, Context>): RoutedStepsOrchestratorState<
  Values,
  Context
> => {
  return {
    config,
    context: initialContext,
    values: initialValues,
  };
};

export const routedStepsOrchestratorReducer = <Values, Context>(
  state: RoutedStepsOrchestratorState<Values, Context>,
  action: RoutedStepsOrchestratorAction<Values, Context>,
): RoutedStepsOrchestratorState<Values, Context> => {
  switch (action.type) {
    case ACTION_TYPES.NEXT_STEP: {
      const { context = {}, values = {} } = action.payload;

      return {
        ...state,
        context: { ...state.context, ...context },
        values: { ...state.values, ...values },
      };
    }
    case ACTION_TYPES.PREVIOUS_STEP: {
      const { context = {}, values = {} } = action.payload;

      return {
        ...state,
        context: { ...state.context, ...context },
        values: { ...state.values, ...values },
      };
    }

    case ACTION_TYPES.RESET: {
      return {
        ...state,
        context: action.payload?.initialContext ?? {},
        values: action.payload?.initialValues ?? {},
      };
    }

    case ACTION_TYPES.UPDATE_CONTEXT: {
      return {
        ...state,
        context: { ...state.context, ...action.payload.newContext },
      };
    }

    case ACTION_TYPES.UPDATE_VALUES: {
      return {
        ...state,
        values: { ...state.values, ...action.payload.newValues },
      };
    }

    default: {
      throw new ActionTypeIsNotSupportedError();
    }
  }
};
