import { createRequest } from '@savgroup-front-common/core/src/services/request';

export const APPROVE_REJECT_QUOTE = createRequest('APPROVE_REJECT_QUOTE');
export const LOAD_CURRENT_FILE_TRACKING_ITEM_DATA = createRequest(
  'LOAD_CURRENT_FILE_TRACKING_ITEM_DATA',
);
export const FILE_TRACKING_PAGE_LOADING = createRequest(
  'FILE_TRACKING_PAGE_LOADING',
);
export const SUBMIT_ADDITIONAL_INFO = createRequest('SUBMIT_ADDITIONAL_INFO');

export const FORK_LOAD_FILE_TRACKING_PAGE = createRequest(
  'FORK_LOAD_FILE_TRACKING_PAGE',
);
