import React from 'react';
import { FormProvider } from 'react-hook-form';

import { AdditionalInformation } from '@savgroup-front-common/core/src/components/AdditionalInformationWithReactHookForm';
import { BaseLoader } from '@savgroup-front-common/core/src/molecules/BaseLoader';
import StepLayout from 'myaccount/view/app/NewLayout/StepLayout/StepLayout';

import { useRedirectToNonNewDesignRoute } from '../irshPages/hooks/useRedirectToNonNewDesignRoute';

import useClaimGroupDocumentRelatedToClaim from './ClaimGroupDocumentPageRelatedToClaim.hooks';

const ClaimGroupDocumentPageRelatedToClaim: React.FC = () => {
  const {
    isLoading,
    neededInformationOnlyFileType,
    onSubmit,
    formContext,
    isLoadingSubmit,
  } = useClaimGroupDocumentRelatedToClaim();

  useRedirectToNonNewDesignRoute();

  if (isLoading) {
    return <BaseLoader />;
  }

  return (
    <FormProvider {...formContext}>
      <form onSubmit={onSubmit}>
        <StepLayout isLoading={isLoadingSubmit}>
          <AdditionalInformation
            neededInformation={neededInformationOnlyFileType}
            formContext={formContext}
            prefixFormName="reasonAdditionalInformationDocumentRelatedToClaim"
            isNewDesign
            shouldAllowQrCode={false}
          />
        </StepLayout>
      </form>
    </FormProvider>
  );
};

ClaimGroupDocumentPageRelatedToClaim.displayName =
  'ClaimGroupDocumentPageRelatedToClaim';

export default ClaimGroupDocumentPageRelatedToClaim;
