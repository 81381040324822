import React, { useContext } from 'react';
import { FormProvider } from 'react-hook-form';

import { BUTTON_TYPES } from '@savgroup-front-common/constants';
import { Button } from '@savgroup-front-common/core/src/atoms/button';
import { SafeFormattedMessageWithoutSpread } from '@savgroup-front-common/core/src/formatters';
import { SUPPORTED_ICON_POSITIONS } from '@savgroup-front-common/core/src/helpers';
import { BaseLoader } from '@savgroup-front-common/core/src/molecules/BaseLoader';
import { RoutedStepProps } from '@savgroup-front-common/core/src/molecules/RoutedStepsOrchestrator/RoutedStepsOrchestrator.types';
import ArrowNakedLeftIcon from '@savgroup-front-common/core/src/protons/icons/ArrowNaked/ArrowNakedLeft.icon';
import { ArrowNakedRightIcon } from '@savgroup-front-common/core/src/protons/icons/ArrowNaked/ArrowNakedRight.icon';

import { STEP_CONFIG } from '../../../app/NewLayout/ClassiqueRoutes.config';
import { InitContext } from '../../../app/NewLayout/InitProvider/InitProvider.context';
import {
  NewStepLayout,
  PageFooterContainer,
} from '../../../app/NewLayout/NewStepLayout';
import { IrshStepValues } from '../IrshPages.types';

import { ChooseProductPageContent } from './ChooseProductPageContent';
import messages from './messages';
import useChooseProduct from './NewChooseProductPage.hooks';

export const NewChooseProductPage: React.FC<
  RoutedStepProps<IrshStepValues>
> = ({ onPreviousStep, onNextStep }) => {
  const { openedFiles, orders = [] } = useContext(InitContext);

  const { formContext, onSubmit, isLoadingSubmit, isIssuesLoading } =
    useChooseProduct({ onNextStep });

  const {
    formState: { isValid },
  } = formContext;

  if (isIssuesLoading) {
    return <BaseLoader />;
  }

  return (
    <FormProvider {...formContext}>
      <form onSubmit={onSubmit}>
        <NewStepLayout stepName={STEP_CONFIG.CHOOSE_PRODUCTS}>
          {orders.map((order) => {
            return (
              <ChooseProductPageContent
                key={order.orderId}
                orderId={order.orderId}
                openedFiles={openedFiles}
              />
            );
          })}
        </NewStepLayout>

        <PageFooterContainer>
          <Button
            type={BUTTON_TYPES.BUTTON}
            secondary
            naked
            onClick={() => {
              onPreviousStep({});
            }}
            icon={<ArrowNakedLeftIcon />}
            position={SUPPORTED_ICON_POSITIONS.LEFT}
          >
            <SafeFormattedMessageWithoutSpread
              message={messages.previousButton}
            />
          </Button>

          <Button
            primary
            type={BUTTON_TYPES.BUTTON}
            icon={<ArrowNakedRightIcon />}
            position={SUPPORTED_ICON_POSITIONS.RIGHT}
            disabled={!isValid}
            isLoading={isLoadingSubmit}
            onClick={onSubmit}
          >
            <SafeFormattedMessageWithoutSpread message={messages.nextButton} />
          </Button>
        </PageFooterContainer>
      </form>
    </FormProvider>
  );
};
