import styled from 'styled-components';

import { media } from '@savgroup-front-common/constants';
import { Button } from '@savgroup-front-common/core/src/atoms/button';

export const $ItemContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  @media ${media.minWidth.sm} {
    flex-direction: row;
    align-items: center;
  }
  align-items: start;
  justify-content: space-between;
`;

export const $ItemTitle = styled.div`
  font-weight: ${({ theme }) => theme.fonts.weight.mediumFont};
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.newUI.defaultColors.menu};
`;

export const $ItemHighlighting = styled.div`
  font-weight: ${({ theme }) => theme.fonts.weight.normal};
  font-size: ${({ theme }) => theme.fonts.size.ultraSmall};
  line-height: 16px;
  display: flex;
  align-items: center;
  text-align: center;
  margin: 0.25rem 0;
  color: ${({ theme }) => theme.colors.success};
`;

export const $ItemHighlightingIcon = styled.span`
  margin-right: 0.5rem;
  display: flex;
`;

export const $ItemDescription = styled.div`
  width: 100%;
  font-weight: ${({ theme }) => theme.fonts.weight.normal};
  font-size: 14px;
  line-height: 18px;
  display: flex;
  align-items: center;
  color: #8a94a6;
  margin: 0.25rem 0;
`;

export const $ItemDistance = styled.div`
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  margin: 0.25rem 0;
  color: ${({ theme }) => theme.colors.primary};
`;

export const $ItemLogo = styled.div`
  width: 60px;
  display: flex;
  justify-content: center;

  & img {
    max-height: 36px;
    max-width: 100%;
  }
`;

export const $ItemPrice = styled.div<{
  $hasPrice: boolean;
  $hasPickupPointType: boolean;
}>`
  text-align: left;
  @media ${media.minWidth.sm} {
    text-align: ${({ $hasPickupPointType }) =>
      $hasPickupPointType ? 'left' : 'right'};
  }
  ${({ $hasPrice, theme }) =>
    $hasPrice
      ? `
        font-weight: ${theme.fonts.weight.mediumBold};
        font-size: ${({
          $hasPickupPointType,
        }: {
          $hasPickupPointType: boolean;
        }) => ($hasPickupPointType ? '14px' : '16px')};
        line-height: 16px;
        color: ${theme.colors.primary};
        @media ${media.maxWidth.sm} {
          margin-top: unset;
          font-weight: ${theme.fonts.weight.mediumFont};
        }
      `
      : `
        color: ${theme.colors.paragraphTextColor};
        @media ${media.maxWidth.sm} {
          color: ${theme.colors.black};
          margin-top: 1rem;
          font-weight: ${theme.fonts.weight.mediumFont};
        }
      `}
`;

export const $Item = styled.div`
  display: flex;
  align-items: center;

  &:hover {
    ${$ItemTitle} {
      color: ${({ theme }) => theme.colors.primary};
    }
  }
`;

export const $ItemContentTextWrapper = styled.div`
  width: 80%;
  @media ${media.maxWidth.sm} {
    width: 100%;
  }
`;

export const $Button = styled(Button)`
  &&,
  &&:visited,
  &&:active,
  &&:focus {
    background: transparent;
    padding: 0;
    height: auto;
    min-height: auto;
    justify-content: flex-start;
    text-align: left;
    font-weight: ${({ theme }) => theme.fonts.weight.normal};
    font-size: 14px;
    line-height: 16px;
    text-decoration-line: underline;

    &&:hover {
      background: transparent;
      color: ${({ theme }) => theme.colors.primary};
    }
  }
`;
