import React, { FC } from 'react';
import { FormProvider } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { Col } from 'react-styled-flexboxgrid';

import { BUTTON_TYPES } from '@savgroup-front-common/constants';
import { Button } from '@savgroup-front-common/core/src/atoms/button';
import { SafeFormattedMessageWithoutSpread } from '@savgroup-front-common/core/src/formatters';
import { SUPPORTED_ICON_POSITIONS } from '@savgroup-front-common/core/src/helpers';
import { BaseLoader } from '@savgroup-front-common/core/src/molecules/BaseLoader';
import { RoutedStepProps } from '@savgroup-front-common/core/src/molecules/RoutedStepsOrchestrator/RoutedStepsOrchestrator.types';
import ArrowNakedLeftIcon from '@savgroup-front-common/core/src/protons/icons/ArrowNaked/ArrowNakedLeft.icon';

import { STEP_CONFIG } from '../../../app/NewLayout/ClassiqueRoutes.config';
import {
  NewStepLayout,
  PageFooterContainer,
} from '../../../app/NewLayout/NewStepLayout';
import { IrshStepValues } from '../IrshPages.types';

import StepAnswers from './components/stepAnswers/StepAnswers';
import StepFinal from './components/stepFinal/StepFinal';
import StepInformations from './components/stepInformations/StepInformations';
import StepNumber from './components/stepNumber/StepNumber';
import messages from './messages';
import useClaimGroupDiagnosticTreePage from './NewClaimGroupDiagnosticTreePage.hooks';
import { $RowContent } from './NewClaimGroupDiagnosticTreePage.styles';
import { DIAGNOSTIC_STATE_TYPE } from './NewClaimGroupDiagnosticTreePage.types';

export const NewClaimGroupDiagnosticTreePage: FC<
  RoutedStepProps<IrshStepValues>
> = ({ onPreviousStep, onNextStep }) => {
  const { questionId: diagnosticTreeUniqueName } = useParams<{
    questionId: string;
  }>();

  const {
    diagnosticTreeState,
    isLoading,
    formContext,
    handleSubmitResponse,
    handlePreviousStep,
  } = useClaimGroupDiagnosticTreePage({
    diagnosticTreeUniqueName,
    onPreviousStep,
  });

  const hasQuestionType =
    diagnosticTreeState?.currentStep?.type === DIAGNOSTIC_STATE_TYPE.QUESTION ||
    diagnosticTreeState?.currentStep?.type === DIAGNOSTIC_STATE_TYPE.ACTION;

  if (!diagnosticTreeState) {
    return <></>;
  }

  if (isLoading) {
    return <BaseLoader />;
  }

  const stepNumberLength = diagnosticTreeState.latestStep.length + 1;

  return (
    <NewStepLayout stepName={STEP_CONFIG.CLAIM_GROUP_DIAGNOSTIC_TREE}>
      {hasQuestionType && (
        <FormProvider {...formContext}>
          <form
            onSubmit={(event) => {
              event.preventDefault();

              return undefined;
            }}
          >
            <$RowContent $stepNumberLength={stepNumberLength}>
              <StepNumber currentDiagnosticTreeStep={diagnosticTreeState} />

              <Col xs={12} sm={12} md={9}>
                <StepInformations
                  currentDiagnosticTreeStep={diagnosticTreeState}
                />

                <StepAnswers
                  currentDiagnosticTreeStep={diagnosticTreeState}
                  onSubmit={handleSubmitResponse as any}
                />
              </Col>
            </$RowContent>

            <PageFooterContainer>
              <Button
                type={BUTTON_TYPES.BUTTON}
                secondary
                naked
                onClick={() => handlePreviousStep()}
                icon={<ArrowNakedLeftIcon />}
                position={SUPPORTED_ICON_POSITIONS.LEFT}
              >
                <SafeFormattedMessageWithoutSpread
                  message={messages.previousButton}
                />
              </Button>
            </PageFooterContainer>
          </form>
        </FormProvider>
      )}

      {diagnosticTreeState?.currentStep?.type ===
        DIAGNOSTIC_STATE_TYPE.FINAL_STATE && (
        <StepFinal
          currentDiagnosticTreeStep={diagnosticTreeState}
          onNextStep={onNextStep}
        />
      )}
    </NewStepLayout>
  );
};
