import React from 'react';

import { BaseLoader } from '@savgroup-front-common/core/src/molecules/BaseLoader';

import { useIsBTobEnabled } from '../hooks/useIsBTobEnabled';

import ClassiqueLayout from './ClassiqueLayout';
import useLoadOrder from './hooks/useLoadOrder';
import InitProvider from './InitProvider/InitProvider.context';
import { useLayout } from './NewLayout.hooks';
import ProfessionalLayout from './ProfessionalLayout';

const NewLayout: React.FC<unknown> = () => {
  const { sellerId, ownerId, login, enableServicePortal } = useLayout();
  const isBtoBPortalEnabled = useIsBTobEnabled();
  const { orders, openedFiles, closedFiles, isLoading } = useLoadOrder({
    ownerId,
  });

  if (!sellerId || isLoading) {
    return <BaseLoader />;
  }

  if (isBtoBPortalEnabled) {
    return (
      <InitProvider
        ownerId={ownerId}
        login={login}
        orders={orders}
        openedFiles={openedFiles}
        closedFiles={closedFiles}
        enableServicePortal={enableServicePortal}
      >
        <ProfessionalLayout sellerId={sellerId} />
      </InitProvider>
    );
  }

  return (
    <InitProvider
      ownerId={ownerId}
      login={login}
      orders={orders}
      openedFiles={openedFiles}
      closedFiles={closedFiles}
      enableServicePortal={enableServicePortal}
    >
      <ClassiqueLayout sellerId={sellerId} />
    </InitProvider>
  );
};

export default NewLayout;
