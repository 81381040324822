import {
  ADDITIONAL_INFORMATION_TYPES,
  ClaimContext,
} from '@savgroup-front-common/types';

import { Reason, RELATED_TO } from '../../../../types';

export const computeReasonSteps = ({
  reason,
  claims,
}: {
  claims?: ClaimContext[];
  reason?: Reason;
}) => {
  const isMultiProduct = claims ? claims.length > 1 : undefined;

  const hasAdditionnalInformationFileRelatedToProduct =
    reason?.neededInformation.some(
      (item) =>
        (item.type === ADDITIONAL_INFORMATION_TYPES.FILE ||
          item.type === ADDITIONAL_INFORMATION_TYPES.MULTI_FILES) &&
        item.relatedTo === RELATED_TO.PRODUCT &&
        isMultiProduct === true,
    );

  const hasAdditionnalInformationProductRelatedToProduct =
    reason?.neededInformation.some(
      (item) =>
        item.type !== ADDITIONAL_INFORMATION_TYPES.FILE &&
        item.type !== ADDITIONAL_INFORMATION_TYPES.MULTI_FILES &&
        item.relatedTo === RELATED_TO.PRODUCT &&
        isMultiProduct === true,
    );

  const hasAdditionnalInformationFileRelatedToClaim =
    reason?.neededInformation.some(
      (item) =>
        (item.type === ADDITIONAL_INFORMATION_TYPES.FILE ||
          item.type === ADDITIONAL_INFORMATION_TYPES.MULTI_FILES) &&
        item.relatedTo === RELATED_TO.CLAIM &&
        isMultiProduct === true,
    );

  const hasAdditionalInformationFile = reason?.neededInformation.some(
    (item) =>
      (item.type === ADDITIONAL_INFORMATION_TYPES.FILE ||
        item.type === ADDITIONAL_INFORMATION_TYPES.MULTI_FILES) &&
      isMultiProduct === false,
  );

  return {
    hasAdditionnalInformationFileRelatedToProduct,
    hasAdditionnalInformationProductRelatedToProduct,
    hasAdditionnalInformationFileRelatedToClaim,
    hasAdditionalInformationFile,
  };
};
