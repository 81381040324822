import { useQuery } from 'react-query';

import { STALE_TIME } from '@savgroup-front-common/constants';
import { sortBy } from '@savgroup-front-common/core/src/helpers';
import { useToasts } from '@savgroup-front-common/core/src/molecules/NotificationsProvider';
import { ClaimService } from 'myaccount/api';
import { IssueSummaryDto } from 'myaccount/types/IssueSummaryDto';

const GetIssues = 'getIssues';

interface UseGetIssuesByOrderArgs {
  orderId?: string;
  onSuccess?: (values: IssueSummaryDto[]) => void;
  suspense?: boolean;
}

const useGetIssuesByOrder = ({
  orderId,
  onSuccess = undefined,
  suspense = false,
}: UseGetIssuesByOrderArgs) => {
  const { pushErrors, removeAllNotifications } = useToasts();

  const { data: issues, isLoading } = useQuery(
    [GetIssues, { orderId }],
    async () => {
      if (!orderId) {
        return undefined;
      }

      removeAllNotifications();

      const response = await ClaimService.getIssuesByOrder({
        orderId,
      });

      if (response.failure) {
        pushErrors(response.errors);

        return undefined;
      }

      return response.value.sort(
        sortBy({ fieldName: 'displayOrder', ascending: true }),
      );
    },
    {
      onSuccess,
      staleTime: STALE_TIME.MINUTES_10,
      suspense,
    },
  );

  return {
    issues,
    isIssuesLoading: isLoading,
  };
};

export default useGetIssuesByOrder;
