import React, { FC } from 'react';

import { CURRENCIES } from '@savgroup-front-common/constants';

import { CashRegisterPayment } from '../../../../../components/Payments/CashRegisterPayment';
import useGetPaymentStatus from '../hooks/useGetPaymentStatus';

interface ClaimGroupConfirmationCashRegisterPaymentProps {
  paymentUrl: string;
  claimGroupId: string;
  invoice: { id: string; priceWithTax: number; currencyTrigram: CURRENCIES };
  isFormCompleted: boolean;
}

export const ClaimGroupConfirmationCashRegisterPayment: FC<
  ClaimGroupConfirmationCashRegisterPaymentProps
> = ({ paymentUrl, claimGroupId, invoice, isFormCompleted }) => {
  const paymentStatus = useGetPaymentStatus({
    paymentUrl,
    claimGroupId,
  });

  return (
    <CashRegisterPayment
      paymentStatus={paymentStatus}
      amount={invoice.priceWithTax}
      currency={invoice.currencyTrigram}
      isFormCompleted={isFormCompleted}
    />
  );
};
