import React, { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { safeFormattedIntlString } from '@savgroup-front-common/core/src/formatters';
import BanneredLayout from 'myaccount/view/app/NewLayout/BanneredLayout/BanneredLayout';

import { forkLoadFileTrackingPage } from '../../../domains/FileTrackingPage/actionCreators';
import { useRedirectToNonNewDesignRoute } from '../irshPages/hooks/useRedirectToNonNewDesignRoute';

import FileFollowups from './FileFollowups/FileFollowups';
import {
  useBreadCrumbs,
  useGetProductsDetails,
} from './FileFollowupsPage.hooks';
import messages from './messages';

const FileFollowupsPage: FC = () => {
  const { fileId } = useParams<{ fileId: string }>();

  const dispatch = useDispatch();
  const {
    productsDetails,
    isLoadingSolution,
    isLoadingModel,
    isLoadingFileSummary,
  } = useGetProductsDetails();

  useRedirectToNonNewDesignRoute();

  useEffect(() => {
    dispatch(
      forkLoadFileTrackingPage({
        fileId: fileId,
      }),
    );
  }, [dispatch, fileId]);

  const isLoading = isLoadingSolution || isLoadingModel || isLoadingFileSummary;

  const breadCrumbs = useBreadCrumbs({ isLoading, productsDetails, fileId });

  return (
    <BanneredLayout>
      <BanneredLayout.BannerTitle
        breadcrumbs={breadCrumbs}
        title={
          productsDetails
            ? safeFormattedIntlString(messages.bannerTitle, {
                reference: isLoading
                  ? '...'
                  : productsDetails[0]?.fileReference,
              })
            : messages.bannerTitleUnknown
        }
        description={messages.bannerDescription}
      />
      <BanneredLayout.Content>
        <FileFollowups />
      </BanneredLayout.Content>
    </BanneredLayout>
  );
};

export default FileFollowupsPage;
