import React, { useContext } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { useTitle } from 'react-use';

import { NeedHelp } from '@savgroup-front-common/core/src/components/NeedHelp';
import { safeFormattedIntlString } from '@savgroup-front-common/core/src/formatters';
import { retryImportChunk } from '@savgroup-front-common/core/src/helpers/retryImportChunk';
import { AppErrorBoundary } from '@savgroup-front-common/core/src/molecules/ErrorBoundary';
import { AuthConfiguration } from 'myaccount/configuration';

import { IrshPages } from '../../newDesignPages/irshPages/IrshPages';
import {
  BASE_ROUTES,
  HOME_ROUTES,
  ROUTES,
  ROUTES_NEW_DESIGN,
} from '../routes/Routes';

import { otherPageConfig, stepsConfig } from './ClassiqueRoutes.config';
import Header from './header/Header';
import { InitContext } from './InitProvider/InitProvider.context';
import messages from './messages';
import { $PageWrapper } from './NewLayout.styles';
import StepProvider from './StepsProvider/StepsProvider.context';
import StepWrapper from './StepWrapper/StepWrapper';

const ClientVisioPage = React.lazy(() =>
  retryImportChunk(() => import('../../pages/ClientVisioPage/ClientVisioPage')),
);

interface ClassiqueLayoutProps {
  sellerId: string;
}

const ClassiqueLayout: React.FC<ClassiqueLayoutProps> = ({ sellerId }) => {
  const { enableServicePortal } = useContext(InitContext);

  useTitle(safeFormattedIntlString(messages.selfcareTitle));

  return (
    <AppErrorBoundary>
      <$PageWrapper>
        <NeedHelp clientId={AuthConfiguration.clientId} />
        <Header sellerId={sellerId} enableServicePortal={enableServicePortal} />

        <IrshPages />

        {otherPageConfig.map((page) => (
          <Route
            key={page.name}
            exact
            path={page.path}
            component={page.component}
          />
        ))}
        <Route
          exact
          path={`${BASE_ROUTES.NEW_DESIGN}${ROUTES_NEW_DESIGN.VIDEO_CALL}`}
        >
          <ClientVisioPage />
        </Route>

        <StepProvider>
          {stepsConfig.map((page) => {
            return (
              <Route
                key={page.name}
                path={page.path}
                render={() => (
                  <StepWrapper enableServicePortal={enableServicePortal}>
                    <page.component />
                  </StepWrapper>
                )}
              />
            );
          })}
        </StepProvider>

        {enableServicePortal && (
          <Redirect
            from={`${BASE_ROUTES.NEW_DESIGN}${ROUTES.PRODUCTS}`}
            to={`${BASE_ROUTES.NEW_DESIGN}${BASE_ROUTES.HOME}`}
            push
          />
        )}

        <Route exact path={ROUTES.HOME}>
          <Redirect
            to={
              enableServicePortal
                ? HOME_ROUTES.PRODUCTS
                : `${BASE_ROUTES.NEW_DESIGN}${ROUTES.PRODUCTS}`
            }
          />
        </Route>
      </$PageWrapper>
    </AppErrorBoundary>
  );
};

export default ClassiqueLayout;
