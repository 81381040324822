import { defineMessages } from 'react-intl';

export default defineMessages({
  noAvailableTechnicalInterventionSlots: {
    id: 'components.myaccount.interventionAppointment.noAvailableTechnicalInterventionSlots',
    defaultMessage: 'There is no available technical intervention slot.',
  },
  noAvailableTechnicalInterventionSlotsForThisPage: {
    id: 'components.myaccount.interventionAppointment.noAvailableTechnicalInterventionSlotsForThisPage',
    defaultMessage:
      'There is no available technical intervention slot for this page.',
  },
  appointmentCalendarEmpty: {
    id: 'components.appointmentCalendar.appointmentCalendarEmpty',
    defaultMessage: 'No available slot',
  },
  validate: {
    id: 'components.myaccount.interventionAppointment.validate',
    defaultMessage: 'Validate',
  },
  nexAvailableSchedule: {
    id: 'interventionAppointment.nexAvailableSchedule',
    defaultMessage: 'Next available schedule on {date}',
  },
  tryAgain: {
    id: 'interventionAppointment.tryAgain',
    defaultMessage: 'Try again',
  },
  back: {
    id: 'global.back',
    defaultMessage: 'Back',
  },
});
