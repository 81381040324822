import React, { FC, Suspense } from 'react';
import { useParams } from 'react-router-dom';

import { BaseLoader } from '@savgroup-front-common/core/src/molecules/BaseLoader';

import { customerState } from '../../../../domains/Customer/selectors';
import { useMyAccountTypedSelector } from '../../../../hooks';
import FileDocuments from '../../../components/FileDocuments/FileDocuments';
import { FileHistory } from '../../../components/FileHistory';
import { ProductsInfo } from '../../../components/ProductsInfo';
import ProductsInfoUnknown from '../../../components/ProductsInfo/ProductsInfoUnknown';
import {
  useFileFollowupsPage,
  useGetProductsDetails,
} from '../FileFollowupsPage.hooks';
import { $LayoutGrid } from '../FileFollowupsPage.styles';
import { useGetCustomerFileFullSummaryQuery } from '../hooks/useGetCustomerFileFullSummaryQuery';

import ClaimsSummary from './ClaimsSummary/ClaimsSummary';
import ExpectsContainer from './ClientAction/ExpectsContainer/ExpectsContainer';
import FileFollowupsContext from './FileFollowups.context';

const FileFollowups: FC = () => {
  const { fileId } = useParams<{ fileId: string }>();
  const { histories, fullClaim, isLoadingHistories, isLoadingFullClaim } =
    useFileFollowupsPage();
  const { fileInfo, isLoading: isLoadingFileSummary } =
    useGetCustomerFileFullSummaryQuery({ fileId });
  const { productsDetails, isLoadingSolution, isLoadingModel } =
    useGetProductsDetails();

  const isLoading =
    fileId !== fileInfo?.fileId ||
    isLoadingFileSummary ||
    isLoadingModel ||
    isLoadingSolution ||
    isLoadingHistories ||
    isLoadingFullClaim;

  const customer = useMyAccountTypedSelector(customerState);

  if (isLoading || !customer || !customer?.customerFileSummary) {
    return <BaseLoader />;
  }

  return (
    <FileFollowupsContext.Provider
      value={{ fileSummary: customer?.customerFileSummary }}
    >
      <$LayoutGrid>
        <ProductsInfo
          fileInfo={fileInfo}
          productsInfo={productsDetails}
          histories={histories}
        />

        {!productsDetails && <ProductsInfoUnknown />}

        {fileInfo && (
          <Suspense fallback={<BaseLoader />}>
            <ClaimsSummary fileInfo={fileInfo} fileStatus={histories?.at(0)} />
            <ExpectsContainer />
          </Suspense>
        )}

        {fullClaim && <FileDocuments fullClaim={fullClaim} />}

        <FileHistory histories={histories} />
      </$LayoutGrid>
    </FileFollowupsContext.Provider>
  );
};

export default FileFollowups;
