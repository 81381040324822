import get from 'lodash/get';
import { all, call, put, race, take, takeEvery } from 'redux-saga/effects';

import { APIConfiguration } from '@savgroup-front-common/configuration/src';
import { logCritical } from '@savgroup-front-common/configuration/src/appInsights/AppInsights';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants/src/shared';
import {
  ActionCreators as WorkflowActionCreators,
  ActionTypes as WorkflowActionTypes,
} from '@savgroup-front-common/core/src/domains/workflow';
import { callAndGetResponse } from '@savgroup-front-common/core/src/services';

import * as ActionTypes from './actionTypes';

function* loadClaimRecievedScreenDataWorker({ payload: claimId }) {
  yield call(
    callAndGetResponse,
    ActionTypes.LOAD_CLAIM_CONFIRMATION_SCREEN_DATA,
    `${APIConfiguration.claim}claims/${claimId}/summary`,
    {
      method: SUPPORTED_METHODS.GET,
    },
  );

  yield put(ActionTypes.LOAD_CLAIM_CONFIRMATION_SCREEN_DATA.end());
}
export function* loadClaimRecievedScreenDataWacther() {
  yield takeEvery(
    ActionTypes.LOAD_CLAIM_CONFIRMATION_SCREEN_DATA.BASE,
    loadClaimRecievedScreenDataWorker,
  );
}

function* loadQuotesInfosWorker({ payload: fileId }) {
  yield call(
    callAndGetResponse,
    ActionTypes.LOAD_QUOTES_DATA,
    `${APIConfiguration.repairer}files/${fileId}/quotes/withmargin`,
    {
      method: SUPPORTED_METHODS.GET,
    },
  );

  yield take(ActionTypes.LOAD_QUOTES_DATA.SUCCEEDED);

  yield put(ActionTypes.LOAD_QUOTES_DATA.end());
}
export function* loadQuotesInfosWacther() {
  yield takeEvery(ActionTypes.LOAD_QUOTES_DATA.BASE, loadQuotesInfosWorker);
}

function* loadInvoiceWorker({ payload }) {
  const fileId = get(payload, 'fileId');

  yield call(
    callAndGetResponse,
    ActionTypes.LOAD_INVOICE_DATA,
    `${APIConfiguration.payment}invoices/File/${fileId}/opened`,
    {
      fileId,
    },
  );

  yield put(ActionTypes.LOAD_INVOICE_DATA.end(fileId));
}
export function* loadInvoiceWatcher() {
  yield takeEvery(ActionTypes.LOAD_INVOICE_DATA.BASE, loadInvoiceWorker);
}

function* loadWorkflowActionWorker({ payload: fileId }) {
  if (!fileId) {
    yield put(ActionTypes.LOAD_WORKFLOW_ACTIONS.end());

    return;
  }

  yield call(
    callAndGetResponse,
    ActionTypes.LOAD_WORKFLOW_ACTIONS,
    `${APIConfiguration.workflow}files/${fileId}/actions`,
    {
      method: SUPPORTED_METHODS.GET,
    },
  );

  yield put(ActionTypes.LOAD_WORKFLOW_ACTIONS.end());
}
export function* loadWorkflowActionWatcher() {
  yield takeEvery(
    ActionTypes.LOAD_WORKFLOW_ACTIONS.BASE,
    loadWorkflowActionWorker,
  );
}

function* loadCustomerFileWorker({ payload }) {
  const { fileId, ownerId } = payload;

  if (!fileId || !ownerId) {
    yield put(ActionTypes.LOAD_CUSTOMER_FILE.end());

    return;
  }

  yield call(
    callAndGetResponse,
    ActionTypes.LOAD_CUSTOMER_FILE,
    `${APIConfiguration.workflow}owners/${ownerId}/files/${fileId}`,
    {
      method: SUPPORTED_METHODS.GET,
    },
  );

  yield put(ActionTypes.LOAD_CUSTOMER_FILE.end());
}
export function* loadFileSummaryWatcher() {
  yield takeEvery(ActionTypes.LOAD_CUSTOMER_FILE.BASE, loadCustomerFileWorker);
}

function* loadCustomerFileSummaryWorker({ payload }) {
  const { fileId } = payload;

  if (!fileId) {
    yield put(ActionTypes.LOAD_CUSTOMER_FILE_SUMMARY.end());

    return;
  }

  yield call(
    callAndGetResponse,
    ActionTypes.LOAD_CUSTOMER_FILE_SUMMARY,
    `${APIConfiguration.workflow}files/customerfile/${fileId}/summary`,
    {
      method: SUPPORTED_METHODS.GET,
    },
  );
  yield put(ActionTypes.LOAD_CUSTOMER_FILE_SUMMARY.end());
}
export function* loadCustomerFileSummaryWatcher() {
  yield takeEvery(
    ActionTypes.LOAD_CUSTOMER_FILE_SUMMARY.BASE,
    loadCustomerFileSummaryWorker,
  );
}

function* loadModelInformationsWorker({ payload: modelId }) {
  if (!modelId) {
    yield put(ActionTypes.LOAD_MODEL_INFORMATIONS.end());

    return;
  }

  yield call(
    callAndGetResponse,
    ActionTypes.LOAD_MODEL_INFORMATIONS,
    `${APIConfiguration.catalog}models/${modelId}`,
    {
      method: SUPPORTED_METHODS.GET,
    },
  );

  yield put(ActionTypes.LOAD_MODEL_INFORMATIONS.end());
}
export function* loadModelInformationsWatcher() {
  yield takeEvery(
    ActionTypes.LOAD_MODEL_INFORMATIONS.BASE,
    loadModelInformationsWorker,
  );
}

function* loadIRSHTranslationsWorker({
  payload: { issueId, reasonId, solutionId, warrantyTypeId },
}) {
  const meta = {
    issueId,
    reasonId,
    solutionId,
    warrantyTypeId,
  };

  yield call(
    callAndGetResponse,
    ActionTypes.LOAD_IRSH_TRANSLATIONS,
    `${APIConfiguration.claim}irsh/translate`,
    {
      method: SUPPORTED_METHODS.POST,
      json: meta,
    },
    meta,
  );

  yield put(ActionTypes.LOAD_IRSH_TRANSLATIONS.end());
}
export function* loadIRSHTranslationWatcher() {
  yield takeEvery(
    ActionTypes.LOAD_IRSH_TRANSLATIONS.BASE,
    loadIRSHTranslationsWorker,
  );
}

function* loadAvailableFileActionsWorker({ payload: fileId }) {
  if (!fileId) {
    yield put(ActionTypes.LOAD_FILE_STATE_HISTORY_LINES.end());

    return;
  }

  yield call(
    callAndGetResponse,
    ActionTypes.LOAD_FILE_STATE_HISTORY_LINES,
    `${APIConfiguration.workflow}files/${fileId}/states`,
    {
      method: SUPPORTED_METHODS.GET,
    },
  );

  yield put(ActionTypes.LOAD_FILE_STATE_HISTORY_LINES.end());
}
export function* loadAvailableFileActionsWatcher() {
  yield takeEvery(
    ActionTypes.LOAD_FILE_STATE_HISTORY_LINES.BASE,
    loadAvailableFileActionsWorker,
  );
}

function* loadFileStatesWorker({ payload: fileId }) {
  if (!fileId) {
    yield put(ActionTypes.LOAD_FILE_STATES.end(fileId));

    return;
  }

  yield call(
    callAndGetResponse,
    ActionTypes.LOAD_FILE_STATES,
    `${APIConfiguration.workflow}files/${fileId}/states`,
    {
      method: SUPPORTED_METHODS.GET,
    },
    {
      fileId,
    },
  );

  yield put(ActionTypes.LOAD_FILE_STATES.end(fileId));
}
export function* loadFileStatesWatcher() {
  yield takeEvery(ActionTypes.LOAD_FILE_STATES.BASE, loadFileStatesWorker);
}

function* submitWorkflowActionWorker({ payload = {} }) {
  const {
    module,
    fromState,
    toState,
    comment,
    expects,
    toCustomerComment,
    fileId,
  } = payload;

  const [, [saveExpectsSucceeded]] = yield all([
    put(WorkflowActionCreators.saveExpects({ fileId, expects })),
    race([
      take(WorkflowActionTypes.SAVE_EXPECTS.SUCCEEDED),
      take(WorkflowActionTypes.SAVE_EXPECTS.ERRORED),
    ]),
  ]);

  const meta = {
    indexer: fileId,
  };

  if (saveExpectsSucceeded) {
    yield all([
      call(
        callAndGetResponse,
        ActionTypes.SUBMIT_WORKFLOW_ACTION,
        `${APIConfiguration.workflow}files/${fileId}/actions`,
        {
          method: SUPPORTED_METHODS.PUT,
          json: {
            module,
            fromState,
            toState,
            comment,
            toCustomerComment,
          },
        },
      ),
      take(ActionTypes.SUBMIT_WORKFLOW_ACTION.SUCCEEDED),
    ]);
    yield put(ActionTypes.SUBMIT_WORKFLOW_ACTION.end(meta));
  }
}
export function* submitWorkflowActionWatcher() {
  yield takeEvery(
    ActionTypes.SUBMIT_WORKFLOW_ACTION.BASE,
    submitWorkflowActionWorker,
  );
}

function* getShippingLabelWorker({ payload }) {
  const { label, fileId, downloadWindow } = payload;
  const response = yield callAndGetResponse(
    ActionTypes.GET_SHIPPING_LABEL,
    `${APIConfiguration.carrier}carriers/files/${fileId}/urls/${label.moduleDefinitionId}/${label.moduleInstanceId}`,
    {
      method: SUPPORTED_METHODS.GET,
    },
  );

  yield put(ActionTypes.GET_SHIPPING_LABEL.end());

  const shippingLabelUrl = get(response, 'payload.value');

  if (shippingLabelUrl) {
    const shippingLabel =
      APIConfiguration.carrier +
      shippingLabelUrl.replace('v1/', '').replace('{fileId}', fileId);

    downloadWindow.location.href = shippingLabel;
  } else {
    downloadWindow.close();
  }
}
export function* getShippingLabelUrlWatcher() {
  yield takeEvery(ActionTypes.GET_SHIPPING_LABEL.BASE, getShippingLabelWorker);
}

function* actOnQuoteWorker({ payload }) {
  const { quoteId, answer: clientAnswer } = payload;

  yield call(
    callAndGetResponse,
    ActionTypes.ACT_ON_QUOTE,
    `${APIConfiguration.repairer}quotes/answered`,
    {
      method: SUPPORTED_METHODS.POST,
      json: { clientAnswer, quoteId },
    },
  );
  yield put(ActionTypes.ACT_ON_QUOTE.end());
}
export function* actOnQuoteWacther() {
  yield takeEvery(ActionTypes.ACT_ON_QUOTE.BASE, actOnQuoteWorker);
}

export function* customerSaga() {
  try {
    yield all([
      loadFileSummaryWatcher(),
      loadCustomerFileSummaryWatcher(),
      loadModelInformationsWatcher(),
      loadIRSHTranslationWatcher(),
      loadAvailableFileActionsWatcher(),
      getShippingLabelUrlWatcher(),
      actOnQuoteWacther(),
      loadWorkflowActionWatcher(),
      submitWorkflowActionWatcher(),
      loadClaimRecievedScreenDataWacther(),
      loadQuotesInfosWacther(),
      loadInvoiceWatcher(),
      loadFileStatesWatcher(),
    ]);
  } catch (error) {
    logCritical(error);
  }
}

export default customerSaga;
