import React, { FC, Suspense, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { Grid } from 'react-styled-flexboxgrid';

import { BaseLoader } from '@savgroup-front-common/core/src/molecules/BaseLoader';
import { RoutedStepsOrchestrator } from '@savgroup-front-common/core/src/molecules/RoutedStepsOrchestrator/RoutedStepsOrchestrator';
import { OnSubmit } from '@savgroup-front-common/core/src/molecules/RoutedStepsOrchestrator/RoutedStepsOrchestrator.types';

import { ROUTES_NEW_DESIGN } from '../../app';
import { buildFileFollowupUrl } from '../../app/NewLayout/ClassiqueLayout.helpers';

import { STEPS_CONFIG } from './IrshPages.config';
import { IrshStepValues } from './IrshPages.types';

export const IrshPages: FC = () => {
  const history = useHistory();

  const handleOnSubmit: OnSubmit<IrshStepValues> = useCallback(
    ({ values }) => {
      if (!values.fileId) {
        throw new Error('File id is required');
      }

      const fileFollowupUrl = buildFileFollowupUrl({
        fileId: values.fileId,
      });

      history.push(fileFollowupUrl);
    },
    [history],
  );

  return (
    <Grid>
      <Suspense fallback={<BaseLoader />}>
        <RoutedStepsOrchestrator
          config={STEPS_CONFIG}
          onSubmit={handleOnSubmit}
          onPrevious={() => {
            history.replace(ROUTES_NEW_DESIGN.HOME);
          }}
        />
      </Suspense>
    </Grid>
  );
};
