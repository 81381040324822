import { useDeepCompareEffect } from 'react-use';

import { useRoutedStepsOrchestratorContext } from '@savgroup-front-common/core/src/molecules/RoutedStepsOrchestrator/RoutedStepsOrchestrator.context';
import { ClaimContext } from '@savgroup-front-common/types';

import useGetClaimReasons from '../../../app/hooks/useGetClaimReasons';
import { IrshStepValues } from '../IrshPages.types';

export const useInitializeReason = ({
  claimGroupId,
  claim,
}: {
  claimGroupId?: string;
  claim?: ClaimContext;
}) => {
  const { values, onValuesUpdate } =
    useRoutedStepsOrchestratorContext<IrshStepValues>();

  const { reasons } = useGetClaimReasons({
    claimGroupId,
    suspense: true,
  });

  const reason = reasons.find(
    (reason) => claim && reason.id === claim.reasonId,
  );

  useDeepCompareEffect(() => {
    if (!values?.reason && reason) {
      onValuesUpdate({
        newValues: {
          reason,
        },
      });
    }
  }, [onValuesUpdate, values?.reason, reason]);

  return { reasons };
};
