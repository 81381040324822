import styled from 'styled-components';

import { media } from '@savgroup-front-common/constants';
import { Link } from '@savgroup-front-common/core/src/atoms/link';

export const $SimpleCardContainer = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.primary};
  border-radius: ${({ theme }) => theme.borders.radius};
  display: flex;
  flex-direction: column;
  @media ${media.minWidth.sm} {
    flex-direction: row;
    padding: 24px;
    align-items: center;
  }
  padding: 12px;
  margin-bottom: 1.75rem;
  align-items: flex-start;
`;

export const $SimpleCardIcon = styled.div`
  display: flex;
  align-items: center;
  margin-right: 10px;
  & svg {
    width: 24px;
    height: auto;
  }
  @media ${media.minWidth.sm} {
    margin-right: 1rem;
    & svg {
      width: 32px;
      height: 32px;
    }
  }
`;

interface SimpleCardInfoContainerProps {
  hasDidactics?: boolean;
}

export const $SimpleCardInfoContainer = styled.div<SimpleCardInfoContainerProps>`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${({ hasDidactics }) => (hasDidactics ? '8px' : 'unset')};
  @media ${media.maxWidth.sm} {
    margin-bottom: 1rem;
    justify-content: flex-start;
  }
`;

export const $SimpleCardPrefixTitle = styled.span`
  color: ${({ theme }) => theme.colors.primary};
  font-weight: ${({ theme }) => theme.fonts.weight.mediumFont};
  font-size: 16px;
  line-height: 19px;
`;

export const $SimpleCardTitle = styled.span`
  color: ${({ theme }) => theme.colors.mainTextColor};
  font-weight: ${({ theme }) => theme.fonts.weight.normal};
  font-size: 16px;
  line-height: 19px;
`;

export const $SimpleCardLink = styled(Link)`
  font-weight: ${({ theme }) => theme.fonts.weight.normal};
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-align: right;
  text-decoration-line: underline;
  color: ${({ theme }) => theme.colors.mainTextColor};
`;

export const $SimpleCardDidactic = styled.div`
  width: 80%;
  font-weight: ${({ theme }) => theme.fonts.weight.normal};
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  color: #8a94a6;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  @media ${media.maxWidth.sm} {
    width: 100%;
  }
`;

export const $SimpleCardDetail = styled.div`
  text-align: left;

  @media ${media.minWidth.sm} {
    font-size: 16px;
    line-height: 19px;
  }
  @media ${media.maxWidth.sm} {
    color: ${({ theme }) => theme.colors.primary};
    margin-bottom: 0.5rem;
    font-weight: ${({ theme }) => theme.fonts.weight.mediumFont};
  }
`;

export const $SimpleCardAddressName = styled.div`
  display: block;
  color: ${({ theme }) => theme.colors.mainTextColor};
  font-weight: ${({ theme }) => theme.fonts.weight.mediumFont};
  font-size: 14px;
  line-height: 19px;
`;

export const $SimpleCardAddressNameContainer = styled.div`
  border-top: 1px solid #e1e4e8;
  margin-top: 1rem;
  padding-top: 1rem;
`;

export const $SimpleCardTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
