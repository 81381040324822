import { fromJS } from 'immutable';
import find from 'lodash/find';
import get from 'lodash/get';
import { createSelector } from 'reselect';

export const handlingSummary = createSelector(
  [(state) => state, (_: any, fileId: string) => fileId],
  (state, fileId) => {
    const customer = state.customer;
    const handling = state.workflow.handling;

    const fileHandling = handling.getIn([fileId]) || fromJS({});

    const trackingNumbers = get(
      customer,
      'customerFileSummary.trackingNumbers',
      [],
    );
    const handlingData =
      fileHandling && fileHandling.get('isLoaded') && fileHandling.get('value');
    const depositAddress =
      get(handlingData, 'deposit.pickupPointAddress') ||
      get(handlingData, 'deposit.address');
    const deliveryAddress =
      get(handlingData, 'delivery.pickupPointAddress') ||
      get(handlingData, 'delivery.address');
    const depositCarrier = get(
      find(trackingNumbers, { handlingDirection: 'FromOwner' }),
      'carrier',
    );
    const deliveryCarrier = get(
      find(trackingNumbers, { handlingDirection: 'ToOwner' }),
      'carrier',
    );
    const trackingUrlDepositCarrier = get(
      find(trackingNumbers, { handlingDirection: 'FromOwner' }),
      'trackingUrl',
    );
    const trackingUrlDeliveryCarrier = get(
      find(trackingNumbers, { handlingDirection: 'ToOwner' }),
      'trackingUrl',
    );

    return {
      handlingStarted: Boolean(depositAddress),
      deposit: depositAddress && {
        ...depositAddress,
        address: `${
          depositAddress.address || depositAddress.additionalAddress
        }`,
        carrier: depositCarrier,
      },
      trackingUrlDepositCarrier,
      trackingUrlDeliveryCarrier,
      delivery: deliveryAddress && {
        ...deliveryAddress,
        address: `${
          deliveryAddress.address || deliveryAddress.additionalAddress
        }`,
        carrier: deliveryCarrier,
      },
    };
  },
);
