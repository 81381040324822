import { useDeepCompareEffect } from 'react-use';

import { useRoutedStepsOrchestratorContext } from '@savgroup-front-common/core/src/molecules/RoutedStepsOrchestrator/RoutedStepsOrchestrator.context';
import { ClaimContext } from '@savgroup-front-common/types';

import useGetClaimGroupSummary from './useGetClaimGroupSummary';

export const useInitializeClaims = ({
  claimGroupId,
}: {
  claimGroupId?: string;
}) => {
  const { values, onValuesUpdate } = useRoutedStepsOrchestratorContext<{
    claims?: ClaimContext[];
    claimGroupId?: string;
  }>();

  const { claims } = useGetClaimGroupSummary({
    claimGroupId,
    suspense: true,
  });

  useDeepCompareEffect(() => {
    if (!values?.claims && claimGroupId && claims?.length) {
      onValuesUpdate({
        newValues: {
          claimGroupId,
          claims,
        },
      });
    }
  }, [claimGroupId, claims, onValuesUpdate, values?.claims]);
};
