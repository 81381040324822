import { yupResolver } from '@hookform/resolvers/yup';
import { useCallback, useContext, useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';

import { REVALIDATE_MODES } from '@savgroup-front-common/constants';
import { OrderSummary } from '@savgroup-front-common/types';
import { IssueSummaryDto } from 'myaccount/types/IssueSummaryDto';
import { STEP_CONFIG } from 'myaccount/view/app/NewLayout/ClassiqueRoutes.config';
import { StepContext } from 'myaccount/view/app/NewLayout/StepsProvider/StepsProvider.context';

import useGetIssuesByOrder from '../../app/hooks/useGetIssuesByOrder';

import IssuesPageSchema from './IssuesPage.schema';
import { ChoiceIssueValues } from './IssuesPage.types';

interface UseIssuesPageArgs {
  orders: OrderSummary[] | undefined;
}

const useIssuesPage = ({ orders }: UseIssuesPageArgs) => {
  const {
    values,
    handleUpdateValue,
    handleNextStep,
    handleValidateStep,
    form,
  } = useContext(StepContext);
  const [isSkipLoading, setIsSkipLoading] = useState(false);

  const orderIds = orders?.map((order) => order.orderId);
  const orderId = orderIds?.at(0) || undefined;
  const claims = values.claimGroup?.claims || [];

  const handleSubmitToNextStep = useCallback(
    (issue: IssueSummaryDto, isSkipped?: boolean) => {
      if (issue) {
        handleUpdateValue({
          value: {
            issue,
          },
        });
      }

      return handleNextStep(STEP_CONFIG.CHOOSE_PRODUCTS, issue.id, {
        issuePageIsSkipped: isSkipped,
      });
    },
    [handleUpdateValue, handleNextStep],
  );

  const handleGetIssuesByOrderSuccess = async (issues: IssueSummaryDto[]) => {
    if (issues.length === 1 && !form?.issuePageIsSkipped) {
      const issue = issues[0];

      setIsSkipLoading(true);

      if (issue) {
        handleUpdateValue({
          value: {
            issue,
          },
        });
      }

      return handleNextStep(STEP_CONFIG.CHOOSE_PRODUCTS, issue.id, {
        issuePageIsSkipped: true,
      });
    }

    return undefined;
  };

  const { issues, isIssuesLoading } = useGetIssuesByOrder({
    orderId,
    onSuccess: handleGetIssuesByOrderSuccess,
  });

  const formContext = useForm<ChoiceIssueValues>({
    resolver: yupResolver(IssuesPageSchema),
    defaultValues: {
      choiceIssue: claims?.at(0)?.issueId || '',
    },
    mode: REVALIDATE_MODES.ON_CHANGE,
  });

  const {
    formState: { isValid },
  } = formContext;

  const onSubmit: SubmitHandler<ChoiceIssueValues> = useCallback(
    async ({ choiceIssue }: { choiceIssue: string }) => {
      const issueValue = issues?.find((issue) => issue.id === choiceIssue);

      if (issueValue) {
        return handleSubmitToNextStep(issueValue);
      }

      return undefined;
    },
    [handleSubmitToNextStep, issues],
  );

  useEffect(() => {
    handleValidateStep(!!isValid);
  }, [handleValidateStep, isValid]);

  return {
    issues: (issues || []) as any,
    issuePageIsSkipped: form?.issuePageIsSkipped && (issues?.length ?? 0) <= 1,
    isIssuesLoading,
    formContext,
    onSubmit,
    isSkipLoading,
  };
};

export default useIssuesPage;
