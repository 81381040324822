import React, { FC, PropsWithChildren } from 'react';

import { $PanelHeading } from './PanelHeading.styles';

export interface PanelHeadingProps {
  isActive?: boolean;
  gridTemplateColumns: string;
}

export const PanelHeading: FC<PropsWithChildren<PanelHeadingProps>> = ({
  children,
  isActive = false,
  gridTemplateColumns,
}) => {
  return (
    <$PanelHeading
      $isActive={isActive}
      $gridTemplateColumns={gridTemplateColumns}
    >
      {children}
    </$PanelHeading>
  );
};
