import { yupResolver } from '@hookform/resolvers/yup';
import { useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { useParams } from 'react-router-dom';

import { REVALIDATE_MODES } from '@savgroup-front-common/constants';
import { useToasts } from '@savgroup-front-common/core/src/molecules/NotificationsProvider';
import { useRoutedStepsOrchestratorContext } from '@savgroup-front-common/core/src/molecules/RoutedStepsOrchestrator/RoutedStepsOrchestrator.context';
import { RoutedStepProps } from '@savgroup-front-common/core/src/molecules/RoutedStepsOrchestrator/RoutedStepsOrchestrator.types';
import { ClaimService } from 'myaccount/api';

import { IssueSummaryDto } from '../../../../types/IssueSummaryDto';
import useGetIssuesByOrder from '../../../app/hooks/useGetIssuesByOrder';
import { InitContext } from '../../../app/NewLayout/InitProvider/InitProvider.context';
import { IrshStepValues } from '../IrshPages.types';

import chooseProductSchema from './NewChooseProductPage.schema';
import { ChoiceProductValues } from './NewChooseProductPage.types';

const SET_CREATE_CLAIM = 'setCreateClaim';

interface Args {
  onNextStep: RoutedStepProps<IrshStepValues>['onNextStep'];
}

const useChooseProduct = ({ onNextStep }: Args) => {
  const { issueId: issueIdFromUrl } = useParams<{ issueId: string }>();

  const { ownerId, orders } = useContext(InitContext);
  const { values, onValuesUpdate } = useRoutedStepsOrchestratorContext<{
    issue?: IssueSummaryDto;
  }>();

  const { removeAllNotifications, pushErrors } = useToasts();

  const orderIds = orders?.map((order) => order.orderId);
  const firstOrderId = orderIds?.at(0) || undefined;

  const { issues, isIssuesLoading } = useGetIssuesByOrder({
    orderId: firstOrderId,
  });

  const selectedIssueFromUrl = issues?.find(
    (issue) => issue.id === issueIdFromUrl,
  );

  const issue = values?.issue;

  useEffect(() => {
    if (!issue && selectedIssueFromUrl) {
      onValuesUpdate({
        newValues: {
          issue: selectedIssueFromUrl,
        },
      });
    }
  }, [onValuesUpdate, selectedIssueFromUrl, issue]);

  const formContext = useForm<ChoiceProductValues>({
    resolver: yupResolver(chooseProductSchema),
    defaultValues: {
      selectedOwnerProductIds: [],
    },
    mode: REVALIDATE_MODES.ON_CHANGE,
  });

  const { handleSubmit } = formContext;

  const { mutateAsync: handleCreateClaim, isLoading: isLoadingSubmit } =
    useMutation(
      [SET_CREATE_CLAIM],
      async ({
        issueId,
        ownerId,
        ownerProductIds,
      }: {
        issueId: string;
        ownerId: string;
        ownerProductIds?: string[];
      }) => {
        removeAllNotifications();
        if (!issueId || !ownerId || !ownerProductIds) {
          return undefined;
        }

        const response = await ClaimService.setCreateClaimGroup({
          issueId,
          ownerId,
          ownerProductIds,
        });

        if (response.failure) {
          pushErrors(response.errors);

          return undefined;
        }

        return response.value;
      },
    );

  const onSubmit = handleSubmit(async ({ selectedOwnerProductIds }) => {
    if (issue && ownerId) {
      const claimGroupId = await handleCreateClaim({
        issueId: issue.id,
        ownerId,
        ownerProductIds: selectedOwnerProductIds,
      });

      if (!claimGroupId) {
        return undefined;
      }

      onNextStep({
        newValues: {
          issue,
          claimGroupId,
          diagnosticTree: {
            diagnosticTreeUniqueName: undefined,
            isDiagnosticIsAlreadyMade: false,
            hasRefusedDiagnosticTree: false,
          },
        },
      });
    }

    return undefined;
  });

  return {
    isIssuesLoading,
    formContext,
    onSubmit,
    isLoadingSubmit,
  };
};

export default useChooseProduct;
