import { APIConfiguration } from '@savgroup-front-common/configuration';
import { computeInternalId } from '@savgroup-front-common/core/src/helpers/AdditionalInformations/computeInternalId';
import { ADDITIONAL_INFORMATION_TYPES } from '@savgroup-front-common/types';
import {
  AdditionalInformation,
  AdditionalInformationExtended,
  Reason,
  RELATED_TO,
} from 'myaccount/types';

interface ReasonAdapterArgs {
  reasonSelected?: Reason;
  claimIds: string[];
}

export const buildFileUploadEndpoints = ({
  claimIds,
  id,
}: {
  claimIds: string[];
  id: string;
}): Record<string, { uploadEndpoint: string; deleteEndpoint: string }> => {
  return claimIds.reduce((acc, claimId) => {
    return {
      ...acc,
      [claimId]: {
        uploadEndpoint: `${APIConfiguration.claim}claims/${claimId}/additionalInformation/${id}/file`,
        deleteEndpoint: `${APIConfiguration.claim}claims/${claimId}/additionalInformation/${id}/file`,
      },
    };
  }, {});
};

export const adaptReason = ({ reasonSelected }: ReasonAdapterArgs) => {
  const newNeededInformation = reasonSelected?.neededInformation.reduce<
    AdditionalInformationExtended[]
  >((acc, curr) => {
    const { uniquenessByProduct, id, type, claimIds } = curr;

    const isFileType = type === ADDITIONAL_INFORMATION_TYPES.FILE;

    if (uniquenessByProduct) {
      return [
        ...acc,
        ...claimIds.map((claimId) => {
          const internalId = computeInternalId({
            id,
            claimId,
            uniquenessByProduct: true,
          });

          return {
            ...curr,
            internalId,
            claimIds: [claimId],
            fileUploadEndpoints: isFileType
              ? buildFileUploadEndpoints({
                  claimIds,
                  id,
                })
              : undefined,
          };
        }),
      ];
    }

    const internalId = computeInternalId({
      id,
      uniquenessByProduct: false,
    });

    return [
      ...acc,
      {
        ...curr,
        claimIds,
        internalId,
        fileUploadEndpoints: isFileType
          ? buildFileUploadEndpoints({
              claimIds,
              id,
            })
          : undefined,
      },
    ];
  }, []);

  return {
    ...reasonSelected,
    neededInformation: newNeededInformation,
  };
};

export const prepareReasonAdditionalInformation = (
  claimId: string,
  reasonAdditionalInformation: Record<string, { value: string }>,
  neededInformations: { id: string }[],
): unknown => {
  const neededInformationIds =
    neededInformations?.map((neededInformation) => neededInformation.id) ?? [];

  return Object.keys(reasonAdditionalInformation || {})
    .filter((key) => {
      const needInformationKey = key.endsWith(claimId)
        ? key.replace(`_${claimId}`, '')
        : key;

      return neededInformationIds.includes(needInformationKey);
    })
    .map((key: any) => {
      const needInformationKey = key.endsWith(claimId)
        ? key.replace(`_${claimId}`, '')
        : key;

      if (
        reasonAdditionalInformation &&
        reasonAdditionalInformation[key] &&
        reasonAdditionalInformation[key]?.value
      ) {
        return {
          id: needInformationKey,
          value: reasonAdditionalInformation[key].value,
        };
      }

      return {
        id: needInformationKey,
        value: reasonAdditionalInformation[key],
      };
    });
};

export const neededInformationFiltered = (
  neededInformations?: AdditionalInformation[],
  isMultiProduct?: boolean,
): AdditionalInformation[] | undefined => {
  if (!neededInformations || !neededInformations?.length) {
    return undefined;
  }

  if (isMultiProduct === true) {
    return neededInformations
      .filter(
        (item) =>
          item.type !== ADDITIONAL_INFORMATION_TYPES.FILE &&
          item.type !== ADDITIONAL_INFORMATION_TYPES.MULTI_FILES,
      )
      .filter((item) => item.relatedTo === RELATED_TO.CLAIM);
  }

  return neededInformations.filter(
    (item) =>
      item.type !== ADDITIONAL_INFORMATION_TYPES.FILE &&
      item.type !== ADDITIONAL_INFORMATION_TYPES.MULTI_FILES,
  );
};
