import { defineMessages } from 'react-intl';

export default defineMessages({
  products: {
    id: 'components.myaccount.multiProduct.products',
    defaultMessage: 'Product(s)',
  },
  claimGroupContinue: {
    id: 'components.myaccount.multiProduct.claimGroupContinue',
    defaultMessage: 'Continue',
  },
  claimGroupNextProduct: {
    id: 'components.myaccount.multiProduct.claimGroupNextProduct',
    defaultMessage: 'Next product',
  },
  reasonLabel: {
    id: 'components.myaccount.selectReasonStep.reasonLabel',
    defaultMessage: 'Reason',
  },
  reasonCommentLabel: {
    id: 'components.myaccount.selectReasonStep.reasonCommentLabel',
    defaultMessage: 'Comment',
  },
  reasonIsRequired: {
    id: 'components.myaccount.selectReasonStep.reasonRequired',
    defaultMessage: 'Reason is required',
  },
  reasonCommentIsRequired: {
    id: 'components.myaccount.selectReasonStep.reasonCommentIsRequired',
    defaultMessage: 'Comment is required',
  },
  thisFieldIsRequired: {
    id: 'components.myaccount.myReturn.quoteInformation.commentMandatory',
    defaultMessage: 'This field is required',
  },
  documentRequired: {
    id: 'components.myaccount.claims.confirmation.documentRequired',
    defaultMessage: 'This document is mandatory',
  },
  uploadDocumentError: {
    id: 'components.myaccount.claims..uploadDocumentError.uploadError',
    defaultMessage: 'An error is occured',
  },

  previousButton: {
    id: 'global.stepsOrchestrator.previousButton',
    defaultMessage: 'Previous',
  },
  nextButton: {
    id: 'view.myaccount.component.nextButton',
    defaultMessage: 'Next',
  },
});
