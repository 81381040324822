import React from 'react';

import { ProductImage } from '@savgroup-front-common/core/src/atoms/ProductImage';
import { SafeFormattedMessageWithoutSpread } from '@savgroup-front-common/core/src/formatters';
import { OrderProductSummary } from '@savgroup-front-common/types';

import messages from './messages';
import useProductHeader from './ProductHeader.hooks';
import {
  $ProductCardImageContainer,
  $ProductContainer,
  $ProductInfoContainer,
} from './ProductHeader.styles';

interface ProductHeader {
  product: OrderProductSummary;
}

const ProductHeader: React.FC<ProductHeader> = ({ product }) => {
  const { model } = useProductHeader({ product });

  return (
    <$ProductContainer>
      <$ProductCardImageContainer>
        <ProductImage pictureUrl={model?.pictureUrl} />
      </$ProductCardImageContainer>
      <$ProductInfoContainer>
        <span>{product.modelName}</span>
        {model?.sku && (
          <SafeFormattedMessageWithoutSpread
            message={messages.reference}
            values={{
              value: model.sku,
            }}
          />
        )}
      </$ProductInfoContainer>
    </$ProductContainer>
  );
};

export default ProductHeader;
