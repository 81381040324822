import { generatePath } from 'react-router-dom';

import { ClaimContextState } from '@savgroup-front-common/types';

import { BASE_ROUTES, ROUTES_NEW_DESIGN } from '../routes/Routes';

export const buildFileFollowupUrl = ({
  fileId,
}: {
  fileId?: string;
}): string => {
  if (!fileId) {
    return '/';
  }

  return generatePath(ROUTES_NEW_DESIGN.FILE_FOLLOWUPS, {
    fileId,
  });
};

export const buildClaimConfirmationUrl = ({
  claimGroupId,
}: {
  claimGroupId: string;
}): string => {
  return generatePath(
    `${BASE_ROUTES.NEW_DESIGN}${ROUTES_NEW_DESIGN.CLAIM_GROUP_CONFIRMATION}`,
    {
      claimGroupId,
    },
  );
};

export const buildClaimHandlingDeliveryUrl = ({
  claimGroupId,
}: {
  claimGroupId: string;
}): string => {
  return generatePath(
    `${BASE_ROUTES.NEW_DESIGN}${ROUTES_NEW_DESIGN.CLAIM_GROUP_HANDLING_DELIVERY}`,
    {
      claimGroupId,
    },
  );
};

export const buildClaimHandlingDepositUrl = ({
  claimGroupId,
}: {
  claimGroupId: string;
}): string => {
  return generatePath(
    `${BASE_ROUTES.NEW_DESIGN}${ROUTES_NEW_DESIGN.CLAIM_GROUP_HANDLING_DEPOSIT}`,
    {
      claimGroupId,
    },
  );
};

export const buildClaimSolutionUrl = ({
  claimGroupId,
}: {
  claimGroupId: string;
}): string => {
  return generatePath(
    `${BASE_ROUTES.NEW_DESIGN}${ROUTES_NEW_DESIGN.CLAIM_GROUP_SOLUTION}`,
    {
      claimGroupId,
    },
  );
};

export const buildClaimReasonUrl = ({
  claimGroupId,
}: {
  claimGroupId: string;
}): string => {
  return generatePath(
    `${BASE_ROUTES.NEW_DESIGN}${ROUTES_NEW_DESIGN.CLAIM_GROUP_REASON}`,
    {
      claimGroupId,
    },
  );
};

export const buildClaimIssuesUrl = (): string => {
  return generatePath(`${BASE_ROUTES.NEW_DESIGN}${ROUTES_NEW_DESIGN.ISSUES}`);
};

export const buildClaimDiagnosticTreeUrl = ({
  claimGroupId,
  diagnosticTreeUniqueName,
  stepName,
}: {
  claimGroupId: string;
  diagnosticTreeUniqueName: string;
  stepName: string;
}): string => {
  return generatePath(
    `${BASE_ROUTES.NEW_DESIGN}${ROUTES_NEW_DESIGN.CLAIM_GROUP_DIAGNOSTIC_TREE}`,
    {
      claimGroupId,
      questionId: diagnosticTreeUniqueName,
      step: stepName,
    },
  );
};

export const buildClaimStepUrl = ({
  claimContextsState,
  claimGroupId,
  fileId,
}: {
  claimContextsState: ClaimContextState;
  claimGroupId: string;
  fileId?: string;
}): string => {
  switch (claimContextsState) {
    case ClaimContextState.WAITING_FOR_HANDLING_MODE:
    case ClaimContextState.WAITING_FOR_ONSITE_INTERVENTION_INFO:
    case ClaimContextState.WAITING_FOR_SOLUTION:
      return buildClaimSolutionUrl({ claimGroupId });
    case ClaimContextState.WAITING_FOR_HANDLING_DEPOSIT_INFO:
      return buildClaimHandlingDepositUrl({ claimGroupId });
    case ClaimContextState.WAITING_FOR_HANDLING_DELIVERY_INFO:
      return buildClaimHandlingDeliveryUrl({ claimGroupId });
    case ClaimContextState.WAITING_FOR_DIAGNOSTIC_CONFIRMATION: {
      return buildFileFollowupUrl({ fileId });
    }
    case ClaimContextState.CONFIRMATION:
      return buildClaimConfirmationUrl({ claimGroupId });
    case ClaimContextState.WAITING_FOR_REASON:
    default:
      return buildClaimReasonUrl({ claimGroupId });
  }
};
