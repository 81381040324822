import React from 'react';
import { FormProvider } from 'react-hook-form';

import StepLayout from 'myaccount/view/app/NewLayout/StepLayout/StepLayout';

import { useRedirectToNonNewDesignRoute } from '../irshPages/hooks/useRedirectToNonNewDesignRoute';

import useChooseProduct from './ChooseProductPage.hooks';
import ChooseProductPageContent from './ChooseProductPageContent';

const ChooseProductPage: React.FC = () => {
  const { orders, openedFiles, issue, formContext, onSubmit, isLoadingSubmit } =
    useChooseProduct();

  useRedirectToNonNewDesignRoute();

  return (
    <FormProvider {...formContext}>
      <form onSubmit={onSubmit}>
        <StepLayout isLoading={isLoadingSubmit}>
          {orders.map((order) => {
            return (
              <ChooseProductPageContent
                key={order.orderId}
                orderId={order.orderId}
                openedFiles={openedFiles}
                issue={issue}
                formContext={formContext}
              />
            );
          })}
        </StepLayout>
      </form>
    </FormProvider>
  );
};

ChooseProductPage.displayName = 'ChooseProductPage';

export default ChooseProductPage;
