import { defineMessages } from 'react-intl';

export default defineMessages({
  products: {
    id: 'components.myaccount.multiProduct.products',
    defaultMessage: 'Product(s)',
  },
  claimGroupContinue: {
    id: 'components.myaccount.multiProduct.claimGroupContinue',
    defaultMessage: 'Continue',
  },
  claimGroupNextProduct: {
    id: 'components.myaccount.multiProduct.claimGroupNextProduct',
    defaultMessage: 'Next product',
  },
  recommendMessage: {
    id: 'components.myaccount.selectReasonStep.recommendMessage',
    defaultMessage: 'optional',
  },
  selectReason: {
    id: 'components.myaccount.selectReasonStep.selectReason',
    defaultMessage: 'Reason',
  },
  reasonLabel: {
    id: 'components.myaccount.selectReasonStep.reasonLabel',
    defaultMessage: 'Reason',
  },
  reasonCommentLabel: {
    id: 'components.myaccount.selectReasonStep.reasonCommentLabel',
    defaultMessage: 'Comment',
  },
  reasonIsRequired: {
    id: 'components.myaccount.selectReasonStep.reasonRequired',
    defaultMessage: 'Reason is required',
  },
  reasonCommentIsRequired: {
    id: 'components.myaccount.selectReasonStep.reasonCommentIsRequired',
    defaultMessage: 'Comment is required',
  },
  thisFieldIsRequired: {
    id: 'components.myaccount.myReturn.quoteInformation.commentMandatory',
    defaultMessage: 'This field is required',
  },
  documentRequired: {
    id: 'components.myaccount.claims.confirmation.documentRequired',
    defaultMessage: 'This document is mandatory',
  },
  diagnosticTreeTitle: {
    id: 'components.myaccount.selectReasonStep.diagnosticTreeTitle',
    defaultMessage:
      'Avant d’envoyer votre produit en réparation,\nsi on tentait d’identifier votre panne en quelques questions ?',
  },
  diagnosticTreeSubtitle: {
    id: 'components.myaccount.selectReasonStep.diagnosticTreeSubtitle',
    defaultMessage:
      'Réparer soi-même son appareil ménager réduit son empreinte carbone de 20% !',
  },
  diagnosticTreeButtonBack: {
    id: 'components.myaccount.selectReasonStep.diagnosticTreeButtonBack',
    defaultMessage: 'Non merci, passer cette étape',
  },
  diagnosticTreeButtonGo: {
    id: 'components.myaccount.selectReasonStep.diagnosticTreeButtonGo',
    defaultMessage: 'Commencer le diagnostic',
  },

  previousButton: {
    id: 'global.stepsOrchestrator.previousButton',
    defaultMessage: 'Previous',
  },
  nextButton: {
    id: 'view.myaccount.component.nextButton',
    defaultMessage: 'Next',
  },
});
