import { APIConfiguration } from '@savgroup-front-common/configuration';
import { ADDITIONAL_INFORMATION_TYPES } from '@savgroup-front-common/types';
import { AdditionalInformationExtended, Reason } from 'myaccount/types';

interface ReasonAdapterArgs {
  reasonSelected?: Reason;
  currentClaimId: string;
}

export const buildFileUploadEndpoints = ({
  claimId,
  id,
}: {
  claimId: string;
  id: string;
}) => {
  return {
    [claimId]: {
      uploadEndpoint: `${APIConfiguration.claim}claims/${claimId}/additionalInformation/${id}/file`,
      deleteEndpoint: `${APIConfiguration.claim}claims/${claimId}/additionalInformation/${id}/file`,
    },
  };
};

export const reasonAdapter = ({
  reasonSelected,
  currentClaimId,
}: ReasonAdapterArgs) => {
  const newNeededInformation = reasonSelected?.neededInformation.reduce<
    AdditionalInformationExtended[]
  >((acc, curr) => {
    const { id, type } = curr;

    const isFileType =
      type === ADDITIONAL_INFORMATION_TYPES.FILE ||
      type === ADDITIONAL_INFORMATION_TYPES.MULTI_FILES;

    return [
      ...acc,
      {
        ...curr,
        internalId: `${id}_${currentClaimId}`,
        currentClaimId,
        fileUploadEndpoints: isFileType
          ? buildFileUploadEndpoints({
              claimId: currentClaimId,
              id,
            })
          : undefined,
      },
    ];
  }, []);

  return {
    ...reasonSelected,
    neededInformation: newNeededInformation,
  };
};
