import { Reducer } from 'react';

import {
  RoutesConfig,
  STEP_CONFIG,
  stepsConfig,
} from '../ClassiqueRoutes.config';

import { FORM_ACTION_TYPES, FormState } from './StepsProvider.types';

export type FormAction =
  | {
      type: FORM_ACTION_TYPES.NEXT_STEP;
      payload: {
        nextStep: STEP_CONFIG;
        issuePageIsSkipped?: boolean;
        solutionPageIsSkipped?: boolean;
      };
    }
  | {
      type: FORM_ACTION_TYPES.UPDATE_STEP;
      payload: {
        stepName: string;
      };
    }
  | {
      type: FORM_ACTION_TYPES.JUMP_TO_STEP;
      payload: {
        jumpStep: STEP_CONFIG;
      };
    };

export const initFormState = (matchStep: RoutesConfig | void): FormState => {
  if ((matchStep?.name as STEP_CONFIG) && matchStep?.path) {
    return {
      currentStep: {
        stepName: matchStep.name as STEP_CONFIG,
        stepUrl: matchStep.path,
      },
      history: [],
      issuePageIsSkipped: false,
      solutionPageIsSkipped: false,
    };
  }

  return {} as FormState;
};

const formReducer: Reducer<FormState, FormAction> = (state, action) => {
  switch (action.type) {
    case FORM_ACTION_TYPES.NEXT_STEP: {
      const nextStep = stepsConfig.find(
        (step) => step.name === action.payload.nextStep,
      );

      const updateState = {
        ...state,
        currentStep: {
          stepName: nextStep?.name as STEP_CONFIG,
          stepUrl: nextStep?.path,
        },
        history: state.history?.concat(state.currentStep),
      };

      if (action.payload.issuePageIsSkipped) {
        return {
          ...updateState,
          issuePageIsSkipped: action.payload.issuePageIsSkipped,
        };
      }

      if (action.payload.solutionPageIsSkipped) {
        return {
          ...updateState,
          solutionPageIsSkipped: action.payload.solutionPageIsSkipped,
        };
      }

      return updateState;
    }
    case FORM_ACTION_TYPES.UPDATE_STEP: {
      const stepUpdated = stepsConfig.find(
        (step) => step.name === action.payload.stepName,
      );

      return {
        ...state,
        currentStep: {
          stepName: stepUpdated?.name as STEP_CONFIG,
          stepUrl: stepUpdated?.path,
        },
        history: state.history.concat(state.currentStep),
      };
    }
    case FORM_ACTION_TYPES.JUMP_TO_STEP: {
      const jumpStep = stepsConfig.find(
        (step) => step.name === action.payload.jumpStep,
      );

      return {
        ...state,
        currentStep: {
          stepName: jumpStep?.name as STEP_CONFIG,
          stepUrl: jumpStep?.path,
        },
        history: state.history?.concat(state.currentStep),
      };
    }
    default: {
      return { ...state };
    }
  }
};

export default formReducer;
