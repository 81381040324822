import { createAction } from '@reduxjs/toolkit';

import { ModuleSummary } from '@savgroup-front-common/types';

import * as ActionTypes from './actionTypes';

export const loadCustomerFile = createAction<{
  fileId: string;
  ownerId: string;
}>(ActionTypes.LOAD_CUSTOMER_FILE.BASE);

export const loadCustomerFileSummary = createAction<{ fileId: string }>(
  ActionTypes.LOAD_CUSTOMER_FILE_SUMMARY.BASE,
);

export const loadModelInformations = createAction(
  ActionTypes.LOAD_MODEL_INFORMATIONS.BASE,
);

export const getIssueReasonSolutionNames = createAction(
  ActionTypes.LOAD_IRSH_TRANSLATIONS.BASE,
);

export const getAvailableFileActions = createAction(
  ActionTypes.LOAD_FILE_STATE_HISTORY_LINES.BASE,
);

export const loadClaimConfirmationScreenData = createAction(
  ActionTypes.LOAD_CLAIM_CONFIRMATION_SCREEN_DATA.BASE,
);

export const getInvoice = createAction<{ fileId: string }>(
  ActionTypes.LOAD_INVOICE_DATA.BASE,
);

export const loadQuotesInfos = createAction(ActionTypes.LOAD_QUOTES_DATA.BASE);

export const actOnQuote = createAction(ActionTypes.ACT_ON_QUOTE.BASE);

export const loadAvailableWorkflowActions = createAction(
  ActionTypes.LOAD_WORKFLOW_ACTIONS.BASE,
);

export const submitWorkflowAction = createAction<{
  module?: ModuleSummary;
  fromState?: string;
  toState?: string;
  toCustomerComment?: string;
  comment?: string;
  expects?: Record<string, any>;
  fileId: string;
}>(ActionTypes.SUBMIT_WORKFLOW_ACTION.BASE);

export const isLoading = createAction(ActionTypes.IS_LOADING.BASE);

export const loadFileStates = createAction(ActionTypes.LOAD_FILE_STATES.BASE);

export const stateUpdateSucceeded = createAction(
  ActionTypes.STATE_UPDATE_SUCCEEDED,
);
