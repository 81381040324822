import styled from 'styled-components';

import { media } from '@savgroup-front-common/constants';

export const $ChooseProductTagContainer = styled('div')`
  margin-left: unset;
  flex-wrap: nowrap;
  display: flex;
  align-items: flex-start;
  gap: 0.5rem;
  em:not(:last-child) {
    margin-right: 10px;
  }
  @media ${media.minWidth.sm} {
    margin-left: auto;
    justify-content: end;
  }
`;

export const $ChooseProductTagOpenFileContainer = styled('div')`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: end;
  em {
    margin-right: unset !important;
  }
  a {
    color: ${({ theme }) => theme.colors.paragraphTextColor};
    text-decoration-color: ${({ theme }) => theme.colors.paragraphTextColor};
  }
`;
