import { useHistory, useLocation } from 'react-router-dom';

export const useRedirectToNonNewDesignRoute = () => {
  const history = useHistory();
  const location = useLocation();

  if (location.pathname.includes('/new_design')) {
    history.push(location.pathname.replace('/new_design', ''));
  }
};
