import React, { FC, PropsWithChildren } from 'react';

import { BaseLoader } from '@savgroup-front-common/core/src/molecules/BaseLoader';
import { useRoutedStepsOrchestratorContext } from '@savgroup-front-common/core/src/molecules/RoutedStepsOrchestrator/RoutedStepsOrchestrator.context';

import useGetClaimGroupSummary from '../hooks/useGetClaimGroupSummary';
import { useInitializeClaimGroupId } from '../hooks/useInitializeClaimGroupId';
import { useInitializeIssue } from '../hooks/useInitializeIssue';
import { useInitializeReason } from '../hooks/useInitializeReason';
import { useInitializeSolution } from '../hooks/useInitializeSolution';
import { IrshStepValues } from '../IrshPages.types';

export const NewClaimGroupSolutionPageWrapper: FC<PropsWithChildren> = ({
  children,
}) => {
  const { values } = useRoutedStepsOrchestratorContext<IrshStepValues>();
  const claimGroupId = values?.claimGroupId;

  const { claims } = useGetClaimGroupSummary({
    claimGroupId,
    suspense: true,
  });

  const firstClaim = claims?.at(0);
  const reason = values?.reason;
  const solution = values?.solution;

  useInitializeIssue({
    claim: firstClaim,
  });
  useInitializeClaimGroupId();
  useInitializeReason({
    claimGroupId,
    claim: firstClaim,
  });
  const { solutions } = useInitializeSolution({
    claimGroupId,
    claim: firstClaim,
  });

  const isClaimsLoaded = claimGroupId && !!claims?.length;

  const hasReasonToLoad = isClaimsLoaded && firstClaim?.reasonId;
  const isReasonOk = hasReasonToLoad ? !!reason : true;

  const hasSolutionToLoad = isClaimsLoaded && firstClaim?.solutionTypeId;
  const isSolutionsOk = hasSolutionToLoad ? !!solution : true;

  if (!isClaimsLoaded || !isReasonOk || !isSolutionsOk || !solutions) {
    return <BaseLoader />;
  }

  return <>{children}</>;
};
