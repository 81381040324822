import { yupResolver } from '@hookform/resolvers/yup';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation, useQueryClient } from 'react-query';

import {
  HANDLING_GROUPS,
  HANDLING_MODES,
  REVALIDATE_MODES,
} from '@savgroup-front-common/constants';
import { useToasts } from '@savgroup-front-common/core/src/molecules/NotificationsProvider';
import { useRoutedStepsOrchestratorContext } from '@savgroup-front-common/core/src/molecules/RoutedStepsOrchestrator/RoutedStepsOrchestrator.context';
import { RoutedStepProps } from '@savgroup-front-common/core/src/molecules/RoutedStepsOrchestrator/RoutedStepsOrchestrator.types';
import {
  AddressInfoDto,
  PickupPointAddress,
} from '@savgroup-front-common/types';
import { ClaimService } from 'myaccount/api';
import { useGetClaimGroupCarrierQuery } from 'myaccount/view/app/hooks/useGetClaimGroupCarrierQuery';
import { useGetClaimGroupConfirmation } from 'myaccount/view/app/hooks/useGetClaimGroupConfirmation';
import useGetHomePickup from 'myaccount/view/app/hooks/useGetHomePickup';
import useGetMultiCarrierProductByIdQuery from 'myaccount/view/app/hooks/useGetMultiCarrierProductByIdQuery';

import { getGroupedCarriers } from '../../../../helpers';
import useGetClaimGroupSummary, {
  USE_GET_CLAIMS_GROUP_SUMMARY,
} from '../hooks/useGetClaimGroupSummary';
import { IrshStepValues } from '../IrshPages.types';

import useSuggestedAddress from './hooks/useSuggestedAddress';
import NewClaimGroupDeliveryPageSchema from './NewClaimGroupDeliveryPage.schema';
import { ClaimGroupDeliveryValues } from './NewClaimGroupDeliveryPage.types';

interface Args {
  onNextStep: RoutedStepProps<IrshStepValues>['onNextStep'];
}

const useClaimGroupDeliveryPage = ({ onNextStep }: Args) => {
  const { removeAllNotifications, pushErrors } = useToasts();
  const [openModalRelayPointDelivery, setOpenModalRelayPointDelivery] =
    useState(false);
  const queryClient = useQueryClient();

  const { values } = useRoutedStepsOrchestratorContext<IrshStepValues>();
  const handling = values?.handling;

  const { homePickupAddress, mail } = useGetHomePickup();
  const claimGroupId = values?.claimGroupId;

  const { claims } = useGetClaimGroupSummary({
    claimGroupId,
    suspense: true,
  });

  const depositAddress =
    claims && claims?.at(0) && claims?.at(0)?.depositAddress
      ? claims?.at(0)?.depositAddress
      : undefined;

  const ownerAddress = depositAddress || homePickupAddress;

  const { claimGroupConfirmation } = useGetClaimGroupConfirmation({
    claimGroupId,
    suspense: true,
  });

  const { carriers, isLoading: isGetCarrierLoading } =
    useGetClaimGroupCarrierQuery({
      claimGroupId,
      handlingMode: HANDLING_MODES.DELIVERY,
    });

  const { multiCarrierProductSummary } = useGetMultiCarrierProductByIdQuery({
    carrierProductIds: carriers
      ?.map((carrier) => carrier.carrierProductId)
      .filter((carrier) => carrier) as string[],
  });

  const { groupedCarriers } = getGroupedCarriers({
    carriers,
    multiCarrierProductSummary,
    handlingInfo: handling,
  });

  const shouldShowTransportDeduction =
    handling?.canDeduceTransportFromRefund &&
    carriers?.some((carrier) => carrier.priceWithTax);

  const formContext = useForm<ClaimGroupDeliveryValues>({
    defaultValues: {
      addressSelected:
        values?.handlingDelivery?.depositAddress ?? ownerAddress
          ? {
              ...ownerAddress,
              firstName: (ownerAddress as any).firstname,
              lastName: (ownerAddress as any).lastname,
              mail,
            }
          : undefined,
      chooseHandling: values?.handlingDelivery?.chooseHandling,
      pickupPointSelected: values?.handlingDelivery?.pickupPointSelected,
    },
    resolver: yupResolver(NewClaimGroupDeliveryPageSchema),
    mode: REVALIDATE_MODES.ON_CHANGE,
  });

  const { handleSubmit, setValue, watch } = formContext;

  const [hasChangeValue, setChangedValue] = useState(false);

  const changeSelection = () => {
    setValue('chooseHandling', undefined);
    setValue('pickupPointSelected', undefined);
    setChangedValue(false);
    setOpenModalRelayPointDelivery(false);
  };

  const {
    mutateAsync: handleDeliveryHandling,
    isLoading: isLoadingDeliveryHandlingSubmit,
  } = useMutation(
    ['setDeliveryHandling'],
    async ({
      claimGroupId,
      payload,
    }: {
      claimGroupId?: string;
      payload: {
        handlingMode: HANDLING_MODES;
        pickupPointId?: string;
        pickupPointAddress?: PickupPointAddress | null;
        carrierCustomerPriceId?: string;
        carrierSellerPriceId?: string;
        address?: AddressInfoDto | null;
      };
    }) => {
      removeAllNotifications();
      const responseHandling = await ClaimService.setClaimGroupHandling({
        claimGroupId,
        payload,
      });

      if (responseHandling.failure) {
        pushErrors(responseHandling.errors);

        return undefined;
      }

      return responseHandling;
    },
  );

  const { handleAddressSubmit } = useSuggestedAddress({
    formContext,
  });

  const chooseHandlingSelected = watch('chooseHandling');
  const pickupPointSelected = watch('pickupPointSelected');
  const handlingSelected = Boolean(
    chooseHandlingSelected === HANDLING_GROUPS.PICKUP_POINT ||
      chooseHandlingSelected === HANDLING_GROUPS.PICKUP_STORE ||
      chooseHandlingSelected === HANDLING_GROUPS.PICKUP_STORE_DELIVERY,
  );

  const homeSelectedAddressChanged = Boolean(
    hasChangeValue &&
      (chooseHandlingSelected === HANDLING_GROUPS.HOME ||
        chooseHandlingSelected === HANDLING_GROUPS.HOME_INTERVENTION ||
        chooseHandlingSelected === HANDLING_GROUPS.EXTERNAL),
  );

  const handleOpenDeliveryModal = () => {
    if (handlingSelected) {
      setOpenModalRelayPointDelivery((prev) => !prev);

      return;
    }

    return;
  };

  const onSubmit = handleSubmit(
    async ({
      chooseHandling,
      pickupPointSelected,
      addressSelected,
      addressSuggested,
      keepSuggestedAddress,
      keepAddressEntered,
    }) => {
      const shouldBeChoiceAnAddress = await handleAddressSubmit({
        addressSelected,
      });

      const minimumPrice = Math.min(
        ...groupedCarriers[chooseHandling || HANDLING_GROUPS.HOME].map(
          (carrier) => carrier.priceWithTax || 0,
        ),
      );

      const carrier = groupedCarriers[
        chooseHandling || HANDLING_GROUPS.HOME
      ].find((carrier) => (carrier.priceWithTax || 0) === minimumPrice);

      if (!carrier) {
        throw new Error('carrier not found');
      }

      const payload = pickupPointSelected
        ? {
            handlingMode: HANDLING_MODES.DELIVERY,
            pickupPointId: pickupPointSelected.id,
            pickupPointAddress: pickupPointSelected && {
              id: pickupPointSelected.id,
              name: pickupPointSelected.name,
              firstName: pickupPointSelected.name,
              address: pickupPointSelected.adress,
              additionalAddress: pickupPointSelected.adress3,
              postalCode: pickupPointSelected.postalCode,
              city: pickupPointSelected.city,
              countryCode: pickupPointSelected.countryCode,
            },
            carrierCustomerPriceId: pickupPointSelected.carrierCustomerPriceId,
            carrierSellerPriceId: pickupPointSelected.carrierSellerPriceId,
            address: undefined,
          }
        : {
            handlingMode: HANDLING_MODES.DELIVERY,
            pickupPointId: undefined,
            pickupPointAddress: null,
            carrierCustomerPriceId: carrier.carrierCustomerPriceId,
            carrierSellerPriceId: carrier.carrierSellerPriceId,
            address: keepSuggestedAddress ? addressSuggested : addressSelected,
          };
      const choiceAnAddress =
        keepSuggestedAddress !== undefined ||
        keepAddressEntered !== undefined ||
        shouldBeChoiceAnAddress;

      const responseHandling = await handleDeliveryHandling({
        claimGroupId,
        payload,
      });

      if (!responseHandling) {
        return undefined;
      }

      if (payload && (choiceAnAddress || !homeSelectedAddressChanged)) {
        changeSelection();

        await queryClient.invalidateQueries([
          USE_GET_CLAIMS_GROUP_SUMMARY,
          { claimGroupId },
        ]);

        onNextStep({
          newValues: {
            handlingDelivery: {
              chooseHandling,
              pickupPointSelected,
              depositAddress: keepSuggestedAddress
                ? addressSuggested
                : addressSelected,
            },
          },
        });

        return undefined;
      }

      return undefined;
    },
  );

  const isDeliveryHandlingLoading = isGetCarrierLoading;

  return {
    claimGroupConfirmation,
    claimGroupId,
    groupedCarriers,
    shouldShowTransportDeduction,
    formContext,
    setChangedValue,
    changeSelection,
    onSubmit,
    isDeliveryHandlingLoading,
    isLoadingSubmit: isLoadingDeliveryHandlingSubmit,
    chooseHandlingSelected,
    handlingSelected: handlingSelected && !pickupPointSelected,
    homeSelectedAddressChanged,
    openModalRelayPointDelivery,
    handleOpenDeliveryModal,
  };
};

export default useClaimGroupDeliveryPage;
