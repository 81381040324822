import React, { useState } from 'react';
import { Controller, FormProvider } from 'react-hook-form';
import { Col, Grid, Row } from 'react-styled-flexboxgrid';
import { useMedia } from 'react-use';

import { media } from '@savgroup-front-common/constants';
import { Input } from '@savgroup-front-common/core/src/atoms/Form';
import { Modal } from '@savgroup-front-common/core/src/atoms/modal';
import { SafeFormattedMessageWithoutSpread } from '@savgroup-front-common/core/src/formatters';
import { BaseLoader } from '@savgroup-front-common/core/src/molecules/BaseLoader';
import { MyAccountIcon } from '@savgroup-front-common/core/src/protons/IconsNewDesign/MyAccount.icon';
import { ICONS_TYPE } from '@savgroup-front-common/types/src/Icon';
import StepLayout from 'myaccount/view/app/NewLayout/StepLayout/StepLayout';
import { ResumeCard } from 'myaccount/view/components/ResumeCard';
import { ResumeCardButton } from 'myaccount/view/components/ResumeCardButton';

import { ResumeDelivery } from '../../components/ResumeDelivery';
import { ResumeDeposit } from '../../components/ResumeDeposit';
import { ResumeSolution } from '../../components/ResumeSolution';
import { useRedirectToNonNewDesignRoute } from '../irshPages/hooks/useRedirectToNonNewDesignRoute';

import AgreementCheckbox from './AgreementCheckbox';
import useClaimGroupConfirmationPage from './ClaimGroupConfirmationPage.hooks';
import {
  $AgreementRow,
  $ConfirmationItemWrapper,
  $ConfirmationResultWrapper,
  $ConfirmationWrapper,
  $Heading,
  $HeadingIcon,
  $LeftColumn,
} from './ClaimGroupConfirmationPage.styles';
import messages from './messages';
import PaymentCard from './PaymentCard/PaymentCard';

const ClaimGroupConfirmationPage: React.FC = () => {
  const isMobileView = useMedia(media.maxWidth.xs);
  const [openResumeModal, setOpenResumeModal] = useState(false);
  const {
    claimGroupConfirmation,
    claimGroupId,
    formContext,
    onSubmit,
    agreementUrl,
    hasDeposit,
    hasDelivery,
    isLoading,
    paymentRequired,
    sellerId,
    isLoadingSubmit,
    isCheckInvoiceLoading,
    hasDepositStatus,
  } = useClaimGroupConfirmationPage();

  const {
    control,
    formState: { isValid, errors },
    watch,
  } = formContext;

  const handleOpenModal = () => setOpenResumeModal((prev) => !prev);

  const cgv = watch('cgv');
  const shouldDisplayPaymentCard =
    cgv && paymentRequired && sellerId && !isCheckInvoiceLoading;

  useRedirectToNonNewDesignRoute();

  if (isLoading || !claimGroupId) {
    return <BaseLoader />;
  }

  return (
    <>
      <Grid fluid>
        <FormProvider {...formContext}>
          <StepLayout
            onSubmit={onSubmit}
            noConfirmButton={paymentRequired}
            isLoading={isLoadingSubmit}
          >
            <$ConfirmationWrapper>
              <$LeftColumn>
                <ResumeSolution
                  claimGroupId={claimGroupId}
                  claimGroupConfirmation={claimGroupConfirmation}
                />
                {hasDeposit && (
                  <ResumeDeposit
                    claimGroupConfirmation={claimGroupConfirmation}
                    handlingStatus={hasDepositStatus}
                  />
                )}
                {hasDelivery && (
                  <ResumeDelivery
                    claimGroupConfirmation={claimGroupConfirmation}
                  />
                )}

                <$ConfirmationItemWrapper>
                  <$Heading>
                    <$HeadingIcon>
                      <MyAccountIcon icon={ICONS_TYPE.SEND_MAIL_ICON} />
                    </$HeadingIcon>
                    <SafeFormattedMessageWithoutSpread
                      message={messages.notificationsTitle}
                    />
                  </$Heading>
                  <Row>
                    <Col sm={6} xs={12}>
                      <Controller
                        control={control}
                        name="email"
                        render={({ field }) => {
                          return (
                            <Input
                              name={field.name}
                              value={field.value}
                              label={messages.mail}
                              disableBrowserAutocomplete
                              errors={errors}
                              onChange={field.onChange}
                            />
                          );
                        }}
                      />
                    </Col>
                    <Col sm={6} xs={12}>
                      <Controller
                        control={control}
                        name="phone"
                        render={({ field }) => {
                          return (
                            <Input
                              name={field.name}
                              value={field.value}
                              label={messages.phone}
                              disableBrowserAutocomplete
                              errors={errors}
                              onChange={field.onChange}
                            />
                          );
                        }}
                      />
                    </Col>
                  </Row>
                  <$AgreementRow>
                    <Col sm={12} xs={12}>
                      <Controller
                        control={control}
                        name="cgv"
                        render={({ field }) => {
                          return (
                            <AgreementCheckbox
                              agreementHref={agreementUrl}
                              isChecked={field.value}
                              onChange={field.onChange}
                            />
                          );
                        }}
                      />
                    </Col>
                  </$AgreementRow>

                  {shouldDisplayPaymentCard && (
                    <Row>
                      <Col sm={12} xs={12}>
                        <PaymentCard
                          sellerId={sellerId}
                          onSubmitSuccess={onSubmit}
                          isFormCompleted={isValid}
                        />
                      </Col>
                    </Row>
                  )}
                </$ConfirmationItemWrapper>
              </$LeftColumn>
              <$ConfirmationResultWrapper>
                {isMobileView ? (
                  <ResumeCardButton
                    claimGroupConfirmation={claimGroupConfirmation}
                    handleOpenResumeModal={handleOpenModal}
                  />
                ) : (
                  <ResumeCard
                    claimGroupConfirmation={claimGroupConfirmation}
                    hasPrice
                    hasDepositPrice
                    hasDeliveryPrice
                  />
                )}
              </$ConfirmationResultWrapper>
            </$ConfirmationWrapper>
          </StepLayout>
        </FormProvider>
      </Grid>
      <Modal
        isOpen={openResumeModal}
        onClose={handleOpenModal}
        shouldShowPadding={false}
      >
        <ResumeCard
          claimGroupConfirmation={claimGroupConfirmation}
          hasPrice
          hasDepositPrice
          hasDeliveryPrice
        />
      </Modal>
    </>
  );
};

ClaimGroupConfirmationPage.displayName = 'ClaimGroupConfirmationPage';

export default ClaimGroupConfirmationPage;
