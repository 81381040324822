import React, { FC, ReactElement, useContext, useState } from 'react';

import { BUTTON_TYPES } from '@savgroup-front-common/constants';
import { MessageType, Timeslot } from '@savgroup-front-common/types';

import Button from '../../atoms/button/Button';
import { SafeFormattedMessageWithoutSpread } from '../../formatters/SafeFormattedMessageWithoutSpread';
import { BannersProviderContext } from '../../molecules/NotificationsProvider';
import { ChevronLeftIcon } from '../../protons/icons';
import { $ActionRow } from '../InterventionAppointment/components/TakeInterventionAppointment/AppointmentCalendarContainer/AppointmentCalendarContainer.styles';

import {
  $AppointmentCalendar,
  AppointmentCalendarContainer,
  LeftArrowButton,
  RightArrowButton,
} from './AppointmentCalendar.styles';
import { AppointmentCalendarLoader } from './AppointmentCalendarLoader';
import { AppointmentCalendarScreen } from './AppointmentCalendarScreen';
import messages from './messages';
import { DetailedSchedule } from './types/DetailedSchedule';

interface AppointmentCalendarProps {
  isActive?: boolean;
  isGetTimeslotsLoading?: boolean;
  isSubmitLoading: boolean;
  isLoading?: boolean;
  startTime?: Date;
  onValidateSchedule: ({
    selectedTimeslot,
  }: {
    selectedTimeslot?: Timeslot;
  }) => Promise<void>;

  customLoader?: ReactElement;
  messageList?: MessageType[];
  schedules: DetailedSchedule[];
  onPageNextClick?: () => void;
  onPagePreviousClick?: () => void;
  hasErrors?: boolean;
  isFirstPage?: boolean;
  onPreviousStepClick?: () => void;
}

const AppointmentCalendar: FC<
  React.PropsWithChildren<AppointmentCalendarProps>
> = ({
  children,
  isActive = true,
  isGetTimeslotsLoading = false,
  startTime,
  isSubmitLoading,
  isLoading = false,
  onValidateSchedule,

  customLoader,
  messageList,
  schedules,
  onPageNextClick,
  onPagePreviousClick,
  hasErrors,
  isFirstPage = false,
  onPreviousStepClick,
}) => {
  const [selectedTimeslot, setSelectedTimeslot] = useState<
    Timeslot | undefined
  >();

  const { notifications } = useContext(BannersProviderContext);

  const isCurrentWeekEmpty = (schedules || []).every(
    ({ timeslots = [] }) =>
      timeslots.filter((timeslot) => !!timeslot).length === 0,
  );
  const isValidateScheduleDisabled = !schedules || isGetTimeslotsLoading;

  if (isGetTimeslotsLoading) {
    return (
      <AppointmentCalendarLoader
        customLoader={customLoader}
        messageList={messageList}
      />
    );
  }

  return (
    <$AppointmentCalendar>
      <AppointmentCalendarContainer>
        <Button
          type={BUTTON_TYPES.BUTTON}
          onClick={onPagePreviousClick}
          icon={<LeftArrowButton />}
          disabled={isFirstPage}
          dataTestId="previousScreenButton"
          primary
          naked
          small
        />

        <AppointmentCalendarScreen
          isActive={isActive}
          selectedTimeslot={selectedTimeslot}
          onTimeslotSelect={({ selectedTimeslot }) => {
            setSelectedTimeslot(selectedTimeslot);
          }}
          schedules={schedules}
          startTime={startTime}
        />

        <Button
          type={BUTTON_TYPES.BUTTON}
          onClick={onPageNextClick}
          icon={<RightArrowButton />}
          dataTestId="nextScreenButton"
          primary
          naked
          small
        />
      </AppointmentCalendarContainer>

      <$ActionRow>
        {children}
        {onPreviousStepClick && (
          <Button
            onClick={onPreviousStepClick}
            secondary
            naked
            type={BUTTON_TYPES.BUTTON}
            icon={<ChevronLeftIcon />}
          >
            <SafeFormattedMessageWithoutSpread message={messages.back} />
          </Button>
        )}

        {!isCurrentWeekEmpty && notifications.length === 0 && (
          <Button
            disabled={isValidateScheduleDisabled || !selectedTimeslot}
            isLoading={isLoading || isSubmitLoading}
            primary
            type={BUTTON_TYPES.BUTTON}
            isError={hasErrors}
            onClick={() => onValidateSchedule({ selectedTimeslot })}
            dataTestId="validateSchedule"
          >
            <SafeFormattedMessageWithoutSpread message={messages.validate} />
          </Button>
        )}
      </$ActionRow>
    </$AppointmentCalendar>
  );
};

AppointmentCalendar.displayName = 'AppointmentCalendar';

export default AppointmentCalendar;
