enum PERMISSIONS {
  ACCESS_SPARE_PART_REQUEST = 'AccessSparePartRequest',
  ACCESS_SPARE_PART_STOCK_ITEMS = 'AccessSparePartStockItems',
  DISPLAY_SPARE_PART_PURCHASE_PRICE = 'DisplaySparePartPurchasePrice',
  CREATE_SPARE_PART_REQUEST = 'CreateSparePartRequest',
  UPDATE_REQUEST_LINE_VALIDITY = 'UpdateRequestLineValidity',
  MANAGE_INTERVENTION_REPORT_SPARE_PART_LINES = 'ManageInterventionReportSparePartLines',
  MANAGE_QUOTATION_UNKNOWN_SPARE_PART_LINES = 'ManageQuotationUnknownSparePartLines',
  READ_FILE_SPARE_PART_REQUEST_DETAILS = 'ReadFileSparePartRequestDetails',
  MANAGE_SPARE_PART_CATALOG = 'ManageSparePartCatalog',
  UPDATE_SPARE_PART_REQUEST = 'UpdateSparePartRequest',
  UPDATE_SPARE_PART_STOCK = 'UpdateSparePartStock',
  STOCK_INVENTORY_OPERATOR = 'StockInventoryOperator',
  STOCK_INVENTORY_SUPERVISOR = 'StockInventorySupervisor',

  ACCOUNT_MANAGER = 'AccountManager',
  ADD_PRODUCT_TO_ORDER = 'AddProductToOrder',
  API_USERS_MANAGEMENT = 'ApiUsersManagement',
  ARCHIVE_AN_ATTACHMENT = 'ArchiveAnAttachment',
  ASSIGN_FILE = 'AssignFile',
  ASSIGN_USER_PROFILES = 'AssignUserProfiles',
  CARRIER_ACCOUNT_MANAGEMENT = 'CarrierAccountManagement',
  CLAIM_READ = 'ClaimRead',
  CLIENT_QUOTE_ANSWER = 'ClientQuoteAnswer',
  COMMENTS_TO_CUSTOMERS = 'CommentsToCustomers',
  CONTROL_FILE_ATTACHMENT_MANAGEMENT = 'ControlFileAttachmentManagement',
  CREATE_CLAIM = 'CreateClaim',
  CREATE_CLAIM_FROM_ORDER = 'CreateClaimFromOrder',
  CREATE_FILE_BY_CUSTOMER_INFORMATION = 'CreateFileByCustomerInfo',
  CREATE_FILE_BY_ORDER_REFERENCE = 'CreateFileByOrderReference',
  CREATE_FILE_BY_PRODUCT_REFERENCE = 'CreateFileByProductReference',
  CREATE_FILE_BY_OWNER_STOCK = 'CreateFileByOwnerStock',
  CREATE_FILE_BY_RECEIPT_INFORMATION = 'CreateFileBySalesReceiptInfo',
  CREATE_FILTER_PRESET = 'CreateFilterPreset',
  CREATE_INTERVENTION_REPORT = 'CreateInterventionReport',
  CREATE_ORDER_FROM_SCRATCH = 'CreateOrderFromScratch',
  CREATE_QUOTE = 'CreateQuote',
  CREDENTIALS_MANAGEMENT = 'CredentialsManagement',
  CUSTOMER_FILE_MANAGEMENT = 'CustomerFileManagement',
  DELETE_QUOTE = 'DeleteQuote',
  DISPLAY_FILE_INVOICE_LIST = 'DisplayFileInvoiceList',
  DISPLAY_INTERVENTION_REPORT = 'DisplayInterventionReport',
  DISPLAY_INVOICE = 'DisplayInvoice',
  DISPLAY_QUOTE = 'DisplayQuote',
  DISPLAY_QUOTE_WITH_MARGE = 'DisplayQuoteWithMarge',
  EDIT_ANY_ACTOR = 'EditAnyActor',
  EDIT_QUOTE = 'EditQuote',
  FORCE_CLOSE_FILE = 'ForceCloseFile',
  GENERATE_SHIPPING_LABEL = 'GenerateShippingLabel',
  GET_ACTOR = 'GetActor',
  GET_CARRIER = 'GetCarrier',
  GET_CARRIER_SHIPPING_PICKUP_POINT = 'GetCarrierShippingPickupPoint',
  GET_CATALOG = 'GetCatalog',
  GET_COUNTRY = 'GetCountry',
  GET_GEOGRAPHIC_COVERAGE = 'GetGeographicCoverage',
  GET_HUB = 'GetHub',
  GET_ORDER = 'GetOrder',
  GET_PARTNER_CLAIM = 'GetPartnerClaim',
  GET_PARTNER_CUSTOMER_FILE = 'GetPartnerCustomerFile',
  GET_RETURN_VOUCHER = 'GetReturnVoucher',
  GET_ROUTING = 'GetRouting',
  GET_SALES_CHANNEL = 'GetSalesChannel',
  GET_SELLER = 'GetSeller',
  GET_TECHNICAL_INTERVENTION = 'GetTechnicalIntervention',
  GET_WARRANTY = 'GetWarranty',
  GET_WORKFLOW = 'GetWorkflow',
  IGNORE_DATE_RULES_CHECKING = 'IgnoreDateRulesChecking',
  IMPORT_MANAGEMENT = 'ImportManagement',
  INTERACTIVE_MESSAGE_CREATION = 'InteractiveMessageCreation',
  INTERACT_WITH_QUOTATION_TAB = 'InteractWithQuotationTab',
  MANAGE_LOANS = 'ManageLoans',
  MANAGE_INTERVENTION_REPORT_SERVICES = 'ManageInterventionReportServices',

  MESSAGE_ATTACHMENT_DOWNLOAD = 'MessageAttachmentDownload',
  MESSAGE_ATTACHMENT_UPLOAD = 'MessageAttachmentUpload',
  MESSAGE_MANAGEMENT = 'MessageManagement',
  MODIFY_DEPOSIT_STORE = 'ModifyDepositStore',
  MODIFY_STORE_ACTOR = 'ModifyStoreActor',
  MY_ACCOUNT_FILE_ATTACHMENT_MANAGEMENT = 'MyAccountFileAttachmentManagement',
  ORDER_MANAGEMENT = 'OrderManagement',
  OWNER_ACCOUNT = 'OwnerAccount',
  OWNER_MANAGEMENT = 'OwnerManagement',
  QUOTE_PAYMENT_VALIDATION = 'QuotePaymentValidation',

  REFUND = 'Refund',
  REPAIRER_INFO = 'RepairerInfo',
  ROLE_MANAGEMENT = 'RoleManagement',
  SANITY_TESTS = 'SanityTests',
  SEARCH_FILE = 'SearchFile',
  SEARCH_PARTNER_FILE = 'SearchPartnerFile',
  SELLER_CONFIGURATION = 'SellerConfiguration',
  SELLER_MANAGEMENT = 'SellerManagement',
  SET_CREATED_QUOTE = 'SetCreatedQuote',
  SET_USER_ASSIGNED_STORES_IDS = 'SetUserAssignedStoresIds',
  SHIPMENT_TRACKING = 'ShipmentTracking',
  SKIP_ALERT = 'SkipAlert',
  SPLIT_FILE = 'SplitFile',
  STRIPE_ACCOUNT_MANAGEMENT = 'StripeAccountManagement',
  TECHNICAL = 'Technical',
  TICKET_CLOSING = 'TicketClosing',
  TICKET_CREATION = 'TicketCreation',
  TICKET_READING = 'TicketReading',
  TICKET_SNOOZING = 'TicketSnoozing',
  TICKET_TRACKING_ACTIVITY = 'TicketTrackingActivity',
  TRANSPORT_ADDRESS_MANAGEMENT = 'TransportAddressManagement',
  UPGRADE_WORKFLOW = 'UpgradeWorkflow',
  UPSERT_USER_PROFILE = 'UpsertUserProfile',
  USER_ACCOUNT = 'UserAccount',
  USER_INFORMATION = 'UserInformation',
  USER_MANGEMENT = 'UserMangement',
  VERIFY_ADDRESS = 'VerifyAddress',
  VIEW_ANALYTICS = 'ViewAnalytics',
  WORKFLOW_MANAGEMENT = 'WorkflowManagement',
  VIEW_WORKFLOW_RIGHTS = 'ViewWorkflowRights',
  REOPEN_FILE = 'ReopenFile',
  MANAGE_SELLER_HELP_URL = 'ManageSellerHelpUrl',
  COLLECT_PAYMENT = 'CollectPayment',
  VIEW_HISTORICAL_FILE = 'ViewHistoricalFiles',
  MAKE_PAYMENT_REFUND = 'MakePaymentRefund',
  BULK_CHANGE_ACCESS_RIGHTS = 'BulkChangeAccessRights',
  MAKE_COMMERCIAL_GESTURE = 'MakeCommercialGesture',
  CONSOLE_ACCESS_RIGHTS = 'ConsoleAccessRights',
  DISPLAY_QUOTATION_BUTTON = 'DisplayQuotationButton',
  CONSOLE_PUBLISH_RIGHTS = 'ConsolePublishRights',

  MANAGE_BILLING_DOCUMENT = 'ManageBillingDocument',
  DISPLAY_BILLING_DOCUMENT = 'DisplayBillingDocument',
  INSPECTOR_ACCESS_RIGHTS = 'InspectorAccessRights',
  UPDATE_REFUND_ITEMS = 'UpdateRefundItems',
  CHANGE_REFUND_AMOUNT = 'ChangeRefundAmount',
  CHANGE_REFUND_METHOD = 'ChangeRefundMethod',
  CHANGE_COMMERCIAL_GESTURE_AMOUNT = 'ChangeCommercialGestureAmount',
  CHANGE_COMMERCIAL_GESTURE_METHOD = 'ChangeCommercialGestureMethod',
  REFERENCE_SPAREPART_AND_SUPPLIER = 'ReferenceSparePartAndSupplier',
  ASK_FOR_REFERENCE_SPAREPART_AND_SUPPLIER = 'AskForReferenceSparePartAndSupplier',
  CONSOLE_DELETE_RIGHTS = 'ConsoleDeleteRights',

  CREATE_VIDEO_CALL = 'CreateVideoCall',
  EXPORT_TO_EXCEL_RIGHTS = 'ExportToExcelRights',
  HIDE_QUOTATION_DISPLAY_SPARE_PARTS_REFERENCE = 'HideQuotationDisplaySparePartsReference',
  BILLING_ACCESS_RIGHTS = 'BillingAccessRights',
  WRITE_THEME = 'WriteTheme',
}

export default PERMISSIONS;
