import find from 'lodash/find';
import get from 'lodash/get';
import head from 'lodash/head';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import uniq from 'lodash/uniq';
import uniqBy from 'lodash/uniqBy';
import moment from 'moment';
import {
  all,
  call,
  delay,
  fork,
  put,
  race,
  select,
  take,
  takeEvery,
} from 'redux-saga/effects';

import {
  logCritical,
  logVerbose,
} from '@savgroup-front-common/configuration/src/appInsights/AppInsights';
import { ACTOR_TYPES, DOCUMENT_STATUS } from '@savgroup-front-common/constants';
import { FILE_STATES } from '@savgroup-front-common/constants/src/shared';
import {
  ActionCreators as AttachmentsActionCreators,
  ActionTypes as AttachmentsActionTypes,
  Selectors as AttachmentsSelectors,
} from '@savgroup-front-common/core/src/domains/attachments';
import { SET_FILE_ATTACHMENT_INFO } from '@savgroup-front-common/core/src/domains/attachments/actionTypes';
import {
  ActionTypes as CarriersActionTypes,
  ActionCreators as CarriersCreators,
  Selectors as CarriersSelectors,
} from '@savgroup-front-common/core/src/domains/carriers';
import {
  ActionCreators as ClaimActionCreators,
  Selectors as ClaimsSelectors,
} from '@savgroup-front-common/core/src/domains/claims';
import * as ClaimActionTypes from '@savgroup-front-common/core/src/domains/claims/actionTypes';
import { selectUserId } from '@savgroup-front-common/core/src/domains/selectors';
import { storeActionCreators } from '@savgroup-front-common/core/src/domains/store';
import { ActionCreators as WorkflowCreators } from '@savgroup-front-common/core/src/domains/workflow';
import { fibonacci } from '@savgroup-front-common/core/src/helpers';

import { ActionCreators, ActionTypes } from '../actions';
import {
  ActionTypes as CustomerActionTypes,
  ActionCreators as CustomerCreators,
  Selectors as CustomerSelectors,
} from '../Customer';
import { customerInfosSelector } from '../Customer/selectors';
import { loadOwnerProductInfo } from '../Orders/actionCreators';
import { loadOrdersDataWorker } from '../Orders/saga';

import {
  loadFileTrackingItemData,
  setFileTrackingPageLoadingStatus,
} from './actionCreators';
import {
  APPROVE_REJECT_QUOTE,
  FORK_LOAD_FILE_TRACKING_PAGE,
  LOAD_CURRENT_FILE_TRACKING_ITEM_DATA,
  SUBMIT_ADDITIONAL_INFO,
} from './actionTypes';

const LONG_RELOAD_DELAY = 10000;
const SHORT_RELOAD_DELAY = 1000;
const MEDIUM_RELOAD_DELAY = 1500;
const INITIAL_POLL_DELAY = 100;
const MAX_STEP_COUNT = 6;

function* getLatestState() {
  const states = yield select(CustomerSelectors.fileStates);

  return head(states);
}

function* reloadFileTrackingInfoWorker({ payload }) {
  const { fileId } = payload;

  const ownerId = yield select(selectUserId);
  let latestState = yield call(getLatestState);

  let oldLatestStateName = get(latestState, ['state', 'name']);

  if (!latestState || oldLatestStateName === FILE_STATES.CLOSED) {
    return;
  }

  let oldLatestStateDate = get(latestState, 'stateDate');

  let reloadDelay = LONG_RELOAD_DELAY;

  while (true) {
    const { isStateUpdatePending } = yield race({
      delay: delay(reloadDelay),
      isStateUpdatePending: take([
        CustomerActionTypes.SUBMIT_WORKFLOW_ACTION.END,
        ActionTypes.SET_FILE_ACTION.END,
      ]),
    });

    if (isStateUpdatePending) {
      reloadDelay = SHORT_RELOAD_DELAY;
      yield delay(INITIAL_POLL_DELAY);
    }

    yield all([
      put(CustomerCreators.loadFileStates(fileId)),
      take(CustomerActionTypes.LOAD_FILE_STATES.END),
    ]);

    latestState = yield call(getLatestState);
    const newLatestStateDate = get(latestState, 'stateDate');
    const stateName = get(latestState, ['state', 'name']);

    if (
      moment(newLatestStateDate).isAfter(moment(oldLatestStateDate)) ||
      oldLatestStateName !== stateName
    ) {
      oldLatestStateDate = newLatestStateDate;
      oldLatestStateName = stateName;
      reloadDelay = LONG_RELOAD_DELAY;

      yield all([
        put(CustomerCreators.loadCustomerFile({ fileId, ownerId })),
        take(CustomerActionTypes.LOAD_CUSTOMER_FILE.END),
        put(CustomerCreators.loadCustomerFileSummary({ fileId })),
        take(CustomerActionTypes.LOAD_CUSTOMER_FILE_SUMMARY.END),
      ]);
      yield all([
        put(CustomerCreators.getAvailableFileActions(fileId)),
        take(CustomerActionTypes.LOAD_FILE_STATE_HISTORY_LINES.END),
      ]);
      yield all([
        put(CustomerCreators.loadAvailableWorkflowActions(fileId)),
        take(CustomerActionTypes.LOAD_WORKFLOW_ACTIONS.END),
      ]);

      yield put(loadFileTrackingItemData({ fileId }));
      yield put(CustomerCreators.stateUpdateSucceeded());
    }

    if (stateName === FILE_STATES.CLOSED) {
      break;
    }
  }
}

function* pollDocumentsStatus({ fileId }) {
  yield take(AttachmentsActionTypes.GET_DOCUMENTS_STATUS_BY_FILE_ID.END);

  let documentsStatusByFileId = yield select(
    AttachmentsSelectors.getDocumentsStatusByFileIdValue,
    fileId,
  );

  let i = 0;
  const documentWithInProgressStatus = (document) =>
    document.status === DOCUMENT_STATUS.IN_PROGRESS;

  let labels = yield select(CarriersSelectors.labelsValue, {
    fileId,
  });

  while (
    i < 10 ||
    find(documentsStatusByFileId, documentWithInProgressStatus)
  ) {
    yield delay(3000);
    if (isEmpty(get(labels, 'value'))) {
      yield put(CarriersCreators.loadLabels({ fileId }));
    }
    for (const [key] of Object.entries(documentsStatusByFileId)) {
      yield all([
        put(
          AttachmentsActionCreators.getDocumentsStatusByFileId({
            fileId,
            type: key,
          }),
        ),
        take(AttachmentsActionTypes.GET_DOCUMENTS_STATUS_BY_FILE_ID.END),
      ]);
    }
    documentsStatusByFileId = yield select(
      AttachmentsSelectors.getDocumentsStatusByFileIdValue,
      fileId,
    );
    labels = yield select(CarriersSelectors.labelsValue, {
      fileId,
    });
    i += 1;
  }
  for (const [key, value] of Object.entries(documentsStatusByFileId)) {
    if (value === DOCUMENT_STATUS.UNKNOWN) {
      const meta = { fileId, type: key };

      yield put(
        AttachmentsActionTypes.GET_DOCUMENTS_STATUS_BY_FILE_ID.cancel(meta),
      );
    }
  }
}

function* loadCustomerFile({ fileId, ownerId }) {
  let stepCount = 1;
  let customerInfos;

  do {
    yield put(CustomerCreators.loadCustomerFile({ fileId, ownerId }));
    yield take(CustomerActionTypes.LOAD_CUSTOMER_FILE.END);

    customerInfos = yield select(customerInfosSelector);

    if (stepCount === 2) {
      logVerbose(new Error(`Got an empty currentStatus on GetOwnerFileQuery.`));
    }

    stepCount += 1;

    yield delay(fibonacci(stepCount) * MEDIUM_RELOAD_DELAY);
  } while (
    !customerInfos?.workflowCurrentStatus &&
    stepCount <= MAX_STEP_COUNT
  );
}

export function* loadFileTrackingPage({ payload }) {
  const { fileId } = payload;

  yield fork(loadOrdersDataWorker);
  yield put(setFileTrackingPageLoadingStatus(true));

  const ownerId = yield select(selectUserId);

  yield loadCustomerFile({ fileId, ownerId });

  const file = get(yield select(CustomerSelectors.customerState), ['file']);

  if (file) {
    const fileProducts = get(file, 'fileProducts') || [];
    const claimGroupId = get(file, 'claimGroupId');
    const sellerId = get(file, 'sellerId');
    // load raw product data

    yield all([
      put(ClaimActionCreators.loadConfirmationInfoByClaimGroup(claimGroupId)),
      take(ClaimActionTypes.LOAD_CONFIRMATION_INFO_BY_CLAIM_GROUP.END),
    ]);
    const confirmation = yield select(
      ClaimsSelectors.getClaimGroupsConfirmationValue,
      claimGroupId,
    );
    const storeId = get(confirmation, ['value', 'actorId']) || null;
    const actorType = get(confirmation, ['value', 'actorType']) || null;

    if (storeId && sellerId && actorType === ACTOR_TYPES.STORE) {
      yield put(storeActionCreators.getStore({ storeId }));
    } else if (actorType === ACTOR_TYPES.SUPPLIER) {
      const claimId = get(file, 'claimId');

      yield all([
        put(ClaimActionCreators.getRmaSupplierInfos(claimId)),
        take(ClaimActionTypes.GET_RMA_SUPPLIER_INFOS.END),
      ]);
    }
    for (const { ownerProductId } of fileProducts) {
      yield all([put(loadOwnerProductInfo(ownerProductId))]);
    }
    // load model data (model & brand names)
    const models = uniq(map(fileProducts, (p) => p.modelId));

    for (const modelId of models) {
      yield put(CustomerCreators.loadModelInformations(modelId));
    }
    // Load irsh data (translation for issue/reason/solution)
    const irshIds = uniqBy(
      map(
        fileProducts,
        ({ issueId, reasonId, solutionTypeId, warrantyTypeId }) => ({
          issueId,
          reasonId,
          solutionId: solutionTypeId,
          warrantyTypeId,
        }),
      ),
      JSON.stringify,
    );

    for (const irsh of irshIds) {
      yield put(CustomerCreators.getIssueReasonSolutionNames(irsh));
    }
  }

  yield fork(pollDocumentsStatus, { fileId });

  yield all([
    put(CustomerCreators.loadCustomerFileSummary({ fileId })),
    take(CustomerActionTypes.LOAD_CUSTOMER_FILE_SUMMARY.END),
  ]);

  yield all([
    put(CustomerCreators.getAvailableFileActions(fileId)),
    take(CustomerActionTypes.LOAD_FILE_STATE_HISTORY_LINES.END),
  ]);
  yield all([
    put(CustomerCreators.loadAvailableWorkflowActions(fileId)),
    take(CustomerActionTypes.LOAD_WORKFLOW_ACTIONS.END),
  ]);

  yield all([
    put(CustomerCreators.loadFileStates(fileId)),
    take(CustomerActionTypes.LOAD_FILE_STATES.END),
  ]);

  yield all([
    put(loadFileTrackingItemData({ fileId })),
    put(CarriersCreators.loadLabels({ fileId })),
    put(WorkflowCreators.loadFileHandling({ fileId })),
    take(CarriersActionTypes.LOAD_LABELS.END),
  ]);

  yield put(setFileTrackingPageLoadingStatus(false));

  yield fork(reloadFileTrackingInfoWorker, { payload });
}

function* actOnQuoteWorker({ payload }) {
  const {
    answer,
    targetStateName,
    comment,
    quoteId,
    module,
    fromState,
    fileId,
  } = payload;

  yield all([
    put(CustomerCreators.actOnQuote({ answer, quoteId })),
    put(
      ActionCreators.setFileAction({
        module,
        fromState,
        toState: targetStateName,
        fileId,
        comment,
      }),
    ),
    take(CustomerActionTypes.ACT_ON_QUOTE.END),
    take(ActionTypes.SET_FILE_ACTION.END),
  ]);
}
function* actOnQuoteWatcher() {
  yield takeEvery(APPROVE_REJECT_QUOTE.BASE, actOnQuoteWorker);
}

function* loadFileTrackingItemDataWorker({ payload }) {
  const { fileId } = payload;

  const customerFileSummary = yield select(
    CustomerSelectors.selectCustomerFileSummary,
  );
  const { claimId } = get(customerFileSummary, 'claimInfoSummary');
  const { workflowCurrentStatus, moduleSummary } = yield select(
    CustomerSelectors.customerInfosSelector,
  );

  switch (get(workflowCurrentStatus, 'name')) {
    case FILE_STATES.CLAIM_VALIDATED:
    case FILE_STATES.REFUND:
    case FILE_STATES.CLAIM_TO_VALIDATE:
    case FILE_STATES.WAITING_FOR_HANDLING:
      yield all([
        put(CustomerCreators.loadClaimConfirmationScreenData(claimId)),
        take(CustomerActionTypes.LOAD_CLAIM_CONFIRMATION_SCREEN_DATA.END),
      ]);
      break;

    case FILE_STATES.AWAITING_PAYMENT:
      yield all([
        put(CustomerCreators.loadQuotesInfos(fileId)),
        take(CustomerActionTypes.LOAD_QUOTES_DATA.SUCCEEDED),
        put(CustomerCreators.getInvoice({ fileId, moduleSummary })),
        take(CustomerActionTypes.LOAD_INVOICE_DATA.END),
      ]);
      break;

    default:
      yield all([
        put(CustomerCreators.loadClaimConfirmationScreenData(claimId)),
        take(CustomerActionTypes.LOAD_CLAIM_CONFIRMATION_SCREEN_DATA.END),
      ]);
  }
}
export function* loadFileTrackingItemDataWatcher() {
  yield takeEvery(
    LOAD_CURRENT_FILE_TRACKING_ITEM_DATA.BASE,
    loadFileTrackingItemDataWorker,
  );
}

function* submitAdditionalInfoWorker({ payload }) {
  const { fileId } = payload;

  const attachedFiles = yield select(
    AttachmentsSelectors.getUploadedAttachmentsByFileId,
    fileId,
  );
  const attachedFile = head(attachedFiles);

  const file = {
    fileId: payload.fileId,
    transitionInfo: {
      from: payload.fromState,
      to: payload.toState,
      moduleId: get(payload.module, 'id'),
      moduleDefinitionId: get(payload.module, 'definitionId'),
      wave: get(payload.module, 'wave'),
    },
    name: get(attachedFile, ['value', 'name']),
    fileAttachmentId: get(attachedFile, ['value', 'fileAttachmentId']),
    service: 'myaccount',
    extension: get(attachedFile, ['value', 'extension']),
  };

  yield put(AttachmentsActionCreators.setFileAttachmentInfo(file));
  const [, error] = yield race([
    take(SET_FILE_ATTACHMENT_INFO.SUCCEEDED),
    take(SET_FILE_ATTACHMENT_INFO.ERRORED),
  ]);

  if (!error) {
    yield put(ActionCreators.setFileAction(payload));
  }
}
function* submitAdditionalInfoWatcher() {
  yield takeEvery(SUBMIT_ADDITIONAL_INFO.BASE, submitAdditionalInfoWorker);
}

export function* forkLoadFileTrackingPageWatcher() {
  yield takeEvery(FORK_LOAD_FILE_TRACKING_PAGE.BASE, loadFileTrackingPage);
}

export function* fileTrackingPageSaga() {
  try {
    yield all([
      actOnQuoteWatcher(),
      submitAdditionalInfoWatcher(),
      loadFileTrackingItemDataWatcher(),
      forkLoadFileTrackingPageWatcher(),
    ]);
  } catch (error) {
    logCritical(error);
  }
}
