import { defineMessages } from 'react-intl';

export default defineMessages({
  solutionTitle: {
    id: 'components.myaccount.claims.solution.cards.solutionTitle',
    defaultMessage: 'Choose a solution',
  },
  repairProduct: {
    id: 'components.myaccount.claims.handling.cards.repairProduct',
    defaultMessage: 'Repair my product in the workshop',
  },
  estimatedTime: {
    id: 'components.myaccount.claims.handling.cards.estimatedTime',
    defaultMessage: 'estimated average time {estimatedTime}',
  },
  free: {
    id: 'components.myaccount.claims.handling.cards.free',
    defaultMessage: 'Free',
  },
  solutionIsRequired: {
    id: 'components.myaccount.selectSolutionStep.solutionIsRequired',
    defaultMessage: 'Solution is required',
  },

  previousButton: {
    id: 'global.stepsOrchestrator.previousButton',
    defaultMessage: 'Previous',
  },
  nextButton: {
    id: 'view.myaccount.component.nextButton',
    defaultMessage: 'Next',
  },
});
