import { all } from 'redux-saga/effects';

import { logCritical } from '@savgroup-front-common/configuration/src/appInsights/AppInsights';
import { saga as actorsSaga } from '@savgroup-front-common/core/src/domains/actors';
import { saga as attachmentsSaga } from '@savgroup-front-common/core/src/domains/attachments';
import { saga as carriersSaga } from '@savgroup-front-common/core/src/domains/carriers';
import { saga as claimsSaga } from '@savgroup-front-common/core/src/domains/claims';
import { saga as fileUploadSaga } from '@savgroup-front-common/core/src/domains/fileUpload';
import { saga as i18nSaga } from '@savgroup-front-common/core/src/domains/i18n';
import { saga as sellerConfigurationSaga } from '@savgroup-front-common/core/src/domains/sellerConfiguration';
import { sellerSaga } from '@savgroup-front-common/core/src/domains/sellers';
import { storeSaga } from '@savgroup-front-common/core/src/domains/store';
import { technicalInterventionsSaga } from '@savgroup-front-common/core/src/domains/technicalInterventions';
import { saga as workflowSaga } from '@savgroup-front-common/core/src/domains/workflow';

import { saga as actionSaga } from './actions';
import { claimSaga } from './Claim/saga';
import { customerSaga } from './Customer/saga';
import { fileTrackingPageSaga } from './FileTrackingPage/fileTrackingView';
import ordersSaga from './Orders/saga';
import { saga as ownerSaga } from './owner';

export default function* rootSaga() {
  try {
    yield all([
      i18nSaga(),
      workflowSaga(),
      actionSaga(),
      carriersSaga(),
      claimsSaga(),
      ownerSaga(),
      sellerConfigurationSaga(),
      fileUploadSaga(),
      storeSaga(),
      attachmentsSaga(),
      technicalInterventionsSaga(),
      ordersSaga(),
      actorsSaga(),
      sellerSaga(),

      claimSaga(),
      customerSaga(),
      fileTrackingPageSaga(),
    ]);
  } catch (error) {
    logCritical(error);
  }
}
