import React, { PropsWithChildren } from 'react';
import { Grid } from 'react-styled-flexboxgrid';

import { BUTTON_TYPES } from '@savgroup-front-common/constants';
import { Button } from '@savgroup-front-common/core/src/atoms/button';
import { SafeFormattedMessageWithoutSpread } from '@savgroup-front-common/core/src/formatters';
import { SUPPORTED_ICON_POSITIONS } from '@savgroup-front-common/core/src/helpers';
import ArrowNakedLeftIcon from '@savgroup-front-common/core/src/protons/icons/ArrowNaked/ArrowNakedLeft.icon';
import { ArrowNakedRightIcon } from '@savgroup-front-common/core/src/protons/icons/ArrowNaked/ArrowNakedRight.icon';

import { $Footer, $RowFooter } from '../../../../components/common/Container';

import messages from './messages';

interface StepFooterProps {
  onSubmit?: () => void;
  handlePreviousStep?: () => void;
  isFirstStep?: boolean;
  isDisabled?: boolean;
  isPreviousButtonDisabled?: boolean;
  isLoading?: boolean;
  noConfirmButton?: boolean;
  enableServicePortal?: boolean;
}

const StepFooter: React.FC<PropsWithChildren<StepFooterProps>> = ({
  onSubmit = () => undefined,
  handlePreviousStep,
  isFirstStep = false,
  isDisabled = false,
  isPreviousButtonDisabled = false,
  isLoading = false,
  noConfirmButton = false,
  enableServicePortal = false,
  children,
}) => {
  return (
    <$Footer $enableServicePortal={enableServicePortal}>
      <Grid fluid={enableServicePortal}>
        <$RowFooter between="xs">
          {!isFirstStep && handlePreviousStep && (
            <Button
              type={BUTTON_TYPES.BUTTON}
              hollow
              naked
              onClick={handlePreviousStep}
              icon={<ArrowNakedLeftIcon />}
              position={SUPPORTED_ICON_POSITIONS.LEFT}
              disabled={isPreviousButtonDisabled}
            >
              <SafeFormattedMessageWithoutSpread
                message={messages.previousButton}
              />
            </Button>
          )}

          {children}

          {!noConfirmButton && (
            <Button
              type={BUTTON_TYPES.SUBMIT}
              primary
              icon={<ArrowNakedRightIcon />}
              position={SUPPORTED_ICON_POSITIONS.RIGHT}
              disabled={isDisabled}
              isLoading={isLoading}
              onClick={() => onSubmit()}
            >
              <SafeFormattedMessageWithoutSpread
                message={messages.nextButton}
              />
            </Button>
          )}
        </$RowFooter>
      </Grid>
    </$Footer>
  );
};

export default StepFooter;
