import React, { FC } from 'react';
import { Grid } from 'react-styled-flexboxgrid';

import { ExternalPortal } from '@savgroup-front-common/core/src/protons/ExternalPortal/ExternalPortal';

import { $Footer, $RowFooter } from './PageFooterContainer.styles';

export const PageFooterContainer: FC<React.PropsWithChildren> = ({
  children,
}) => {
  return (
    <ExternalPortal>
      <$Footer>
        <Grid fluid={false}>
          <$RowFooter between="xs">{children}</$RowFooter>
        </Grid>
      </$Footer>
    </ExternalPortal>
  );
};
