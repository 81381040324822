import React from 'react';
import { FormattedDate } from 'react-intl';

import { SafeFormattedMessageWithoutSpread } from '@savgroup-front-common/core/src/formatters';
import { useRoutedStepsOrchestratorContext } from '@savgroup-front-common/core/src/molecules/RoutedStepsOrchestrator/RoutedStepsOrchestrator.context';
import { FileShortSummary, Product } from '@savgroup-front-common/types';

import { IssueSummaryDto } from '../../../../types/IssueSummaryDto';

import useChooseProductContent from './ChooseProductPageContent.hooks';
import ChooseProductCard from './components/ChooseProductCard/ChooseProductCard';
import messages from './messages';
import {
  $ProductBillingNumber,
  $ProductContainer,
  $ProductHeader,
} from './NewChooseProductPage.styles';

interface ChooseProductPageContentProps {
  orderId: string;
  openedFiles: FileShortSummary[];
}

export const ChooseProductPageContent: React.FC<
  ChooseProductPageContentProps
> = ({ orderId, openedFiles }) => {
  const { values } = useRoutedStepsOrchestratorContext<{
    issue?: IssueSummaryDto;
  }>();
  const { issue } = values;

  const {
    orderDetail,
    isLoading,
    eligibleProducts,
    notEligibleProducts,
    notEligibleProductsFollowUpClaim,
    isGenericOrder,
  } = useChooseProductContent({ orderId, issue });

  if (!orderDetail || isLoading) {
    return <></>;
  }

  return (
    <$ProductContainer>
      <$ProductHeader>
        {isGenericOrder && (
          <SafeFormattedMessageWithoutSpread
            message={messages.isGenericHeader}
          />
        )}

        {!isGenericOrder && (
          <>
            <$ProductBillingNumber>
              <SafeFormattedMessageWithoutSpread
                message={messages.billingLabel}
                values={{
                  value: orderDetail.orderReference,
                }}
              />{' '}
              <SafeFormattedMessageWithoutSpread
                message={messages.additionalDateText}
              />{' '}
              <FormattedDate value={orderDetail.purchaseDate} />
            </$ProductBillingNumber>
            <SafeFormattedMessageWithoutSpread
              message={messages.numberItems}
              values={{
                value: orderDetail.productsCount,
              }}
            />
          </>
        )}
      </$ProductHeader>

      {eligibleProducts.map((product: Product, index) => {
        return (
          <ChooseProductCard
            key={product.ownerProductId}
            openedFiles={openedFiles}
            product={product}
            dataTestId={`eligible-product-${index}`}
          />
        );
      })}

      {notEligibleProductsFollowUpClaim.map((product: Product, index) => {
        return (
          <ChooseProductCard
            key={product.ownerProductId}
            openedFiles={openedFiles}
            product={product}
            isDisabled
            isInProgress
            notEligible
            dataTestId={`not-eligible-followup-claim-product-${index}`}
          />
        );
      })}

      {notEligibleProducts.map((product: Product, index) => {
        return (
          <ChooseProductCard
            key={product.ownerProductId}
            openedFiles={openedFiles}
            product={product}
            isDisabled
            notEligible
            dataTestId={`not-eligible-product-${index}`}
          />
        );
      })}
    </$ProductContainer>
  );
};
