import { yupResolver } from '@hookform/resolvers/yup';
import { Reducer, useContext, useEffect, useReducer } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { generatePath, useHistory } from 'react-router-dom';

import { REVALIDATE_MODES } from '@savgroup-front-common/constants';
import { useToasts } from '@savgroup-front-common/core/src/molecules/NotificationsProvider';
import { useRoutedStepsOrchestratorContext } from '@savgroup-front-common/core/src/molecules/RoutedStepsOrchestrator/RoutedStepsOrchestrator.context';
import { RoutedStepProps } from '@savgroup-front-common/core/src/molecules/RoutedStepsOrchestrator/RoutedStepsOrchestrator.types';
import { ClaimService } from 'myaccount/api';

import { ROUTES_NEW_DESIGN } from '../../../app';
import { InitContext } from '../../../app/NewLayout/InitProvider/InitProvider.context';
import useGetClaimGroupSummary from '../hooks/useGetClaimGroupSummary';
import { useInitializeClaimGroupId } from '../hooks/useInitializeClaimGroupId';
import { IrshStepValues } from '../IrshPages.types';

import newClaimGroupDiagnosticTreePageReducer, {
  ClaimGroupDiagnosticTreePageReducerAction,
  ClaimGroupDiagnosticTreePageReducerState,
  initDiagnosticTreeState,
} from './NewClaimGroupDiagnosticTreePage.reducer';
import { ClaimGroupDiagnosticTreeSchema } from './NewClaimGroupDiagnosticTreePage.schema';
import {
  ClaimGroupDiagnosticTreeValue,
  DIAGNOSTIC_ACTION_TYPES,
} from './NewClaimGroupDiagnosticTreePage.types';

const GET_START_OR_CONTINUE_DIAGNOSTIC_TREE =
  'getStartOrContinueDiagnosticTree';

const HANDLE_DIAGNOSTIC_RESPONSE_COMMAND_QUERY =
  'handleDiagnosticResponseCommandQuery';

interface UseClaimGroupDiagnosticTreePageArgs {
  diagnosticTreeUniqueName: string;
  onPreviousStep: RoutedStepProps<IrshStepValues>['onPreviousStep'];
}

const useClaimGroupDiagnosticTreePage = ({
  diagnosticTreeUniqueName,
  onPreviousStep,
}: UseClaimGroupDiagnosticTreePageArgs) => {
  const { enableServicePortal } = useContext(InitContext);

  const [diagnosticTreeState, diagnosticTreeDispatch] = useReducer<
    Reducer<
      ClaimGroupDiagnosticTreePageReducerState,
      ClaimGroupDiagnosticTreePageReducerAction
    >
  >(newClaimGroupDiagnosticTreePageReducer, initDiagnosticTreeState());
  const { removeAllNotifications, pushErrors } = useToasts();
  const history = useHistory();

  const { values, onValuesUpdate } =
    useRoutedStepsOrchestratorContext<IrshStepValues>();

  const claimGroupId = values?.claimGroupId;

  useInitializeClaimGroupId();

  const { claims } = useGetClaimGroupSummary({
    claimGroupId,
    suspense: true,
  });

  const claimId = claims?.at(0)?.claimId || '';

  const formContext = useForm<ClaimGroupDiagnosticTreeValue>({
    resolver: yupResolver(ClaimGroupDiagnosticTreeSchema),
    mode: REVALIDATE_MODES.ON_CHANGE,
  });

  const { handleSubmit, reset } = formContext;

  const {
    mutateAsync: handleStartOrContinueDiagnosticTree,
    isLoading: isStartOrContinueDiagnosticTreeLoading,
  } = useMutation(
    [GET_START_OR_CONTINUE_DIAGNOSTIC_TREE],
    async ({
      claimId,
      treeUniqueName,
    }: {
      claimId: string;
      treeUniqueName: string;
    }) => {
      if (!claimId) {
        return undefined;
      }

      removeAllNotifications();

      const response = await ClaimService.startOrContinueDiagnosticTreeQuery({
        claimId,
        treeUniqueName,
      });

      if (response.failure) {
        pushErrors(response.errors);

        return undefined;
      }

      diagnosticTreeDispatch({
        type: DIAGNOSTIC_ACTION_TYPES.START,
        payload: response.value,
      });

      return response.value;
    },
  );

  const {
    mutateAsync: handleUpdateDiagnosticTree,
    isLoading: ishandleDiagnosticResponseCommandLoading,
  } = useMutation(
    [HANDLE_DIAGNOSTIC_RESPONSE_COMMAND_QUERY],
    async ({
      claimId,
      treeUniqueName,
      nodeUniqueName,
      responseUniqueName,
    }: {
      claimId: string;
      treeUniqueName: string;
      nodeUniqueName: string;
      responseUniqueName: string;
    }) => {
      if (!responseUniqueName) {
        return undefined;
      }

      removeAllNotifications();

      const response = await ClaimService.handleDiagnosticResponseCommandQuery({
        claimId,
        treeUniqueName,
        nodeUniqueName,
        responseUniqueName,
        force: diagnosticTreeState.isPrevious,
      });

      if (response.failure) {
        pushErrors(response.errors);

        return undefined;
      }

      diagnosticTreeDispatch({
        type: DIAGNOSTIC_ACTION_TYPES.NEXT_STEP,
        payload: response.value,
      });

      return response.value;
    },
  );

  const handlePreviousStep = () => {
    const shouldExitFromDiagnosticTree = !diagnosticTreeState.latestStep.length;

    if (shouldExitFromDiagnosticTree) {
      onValuesUpdate({
        newValues: {
          diagnosticTree: {
            isDiagnosticIsAlreadyMade: false,
          },
          reason: undefined,
        },
      });

      if (claimGroupId) {
        onPreviousStep({});
      }

      return undefined;
    }

    reset();

    diagnosticTreeDispatch({
      type: DIAGNOSTIC_ACTION_TYPES.PREVIOUS_STEP,
    });

    history.push(
      generatePath(ROUTES_NEW_DESIGN.CLAIM_GROUP_DIAGNOSTIC_TREE, {
        claimGroupId,
        questionId: diagnosticTreeUniqueName,
        step: `step${diagnosticTreeState.currentStepNumber - 1}`,
      }),
    );

    return undefined;
  };

  const handleSubmitResponse = handleSubmit(async ({ response }) => {
    if (response?.uniqueName) {
      await handleUpdateDiagnosticTree({
        claimId,
        treeUniqueName: diagnosticTreeUniqueName,
        nodeUniqueName: diagnosticTreeState?.currentStep?.uniqueName || '',
        responseUniqueName: response?.uniqueName,
      });

      reset();

      history.push(
        generatePath(ROUTES_NEW_DESIGN.CLAIM_GROUP_DIAGNOSTIC_TREE, {
          claimGroupId,
          questionId: diagnosticTreeUniqueName,
          step: `step${diagnosticTreeState.currentStepNumber + 1}`,
        }),
      );

      return undefined;
    }

    return undefined;
  });

  useEffect(() => {
    if (claimId) {
      handleStartOrContinueDiagnosticTree({
        claimId,
        treeUniqueName: diagnosticTreeUniqueName,
      });
    }
  }, [handleStartOrContinueDiagnosticTree, claimId, diagnosticTreeUniqueName]);

  return {
    diagnosticTreeState,
    isLoading:
      isStartOrContinueDiagnosticTreeLoading ||
      ishandleDiagnosticResponseCommandLoading,
    formContext,
    handleSubmitResponse,
    handlePreviousStep,
    enableServicePortal,
  };
};

export default useClaimGroupDiagnosticTreePage;
