import { IssueSummaryDto } from '../../../../types/IssueSummaryDto';

export interface ChoiceProductValues {
  selectedOwnerProductIds: string[];
}

export enum PRODUCT_STATE {
  UPDATE = 'Update',
}

export interface ChooseProductPageStepValues {
  issue: IssueSummaryDto;
  claimGroupId?: string;
}
