import { useEffect } from 'react';

import { useRoutedStepsOrchestratorContext } from '@savgroup-front-common/core/src/molecules/RoutedStepsOrchestrator/RoutedStepsOrchestrator.context';
import { ClaimContext } from '@savgroup-front-common/types';

import useGetIssuesByOrder from '../../../app/hooks/useGetIssuesByOrder';
import { IrshStepValues } from '../IrshPages.types';

export const useInitializeIssue = ({ claim }: { claim?: ClaimContext }) => {
  const { values, onValuesUpdate } =
    useRoutedStepsOrchestratorContext<IrshStepValues>();

  const { issues } = useGetIssuesByOrder({
    orderId: claim?.orderId,
    suspense: true,
  });

  const selectedIssueFromUrl = issues?.find(
    (issue) => claim && issue.id === claim.issueId,
  );

  const issue = values?.issue;

  useEffect(() => {
    if (!issue && selectedIssueFromUrl && claim?.orderId) {
      onValuesUpdate({
        newValues: {
          issue: selectedIssueFromUrl,
        },
      });
    }
  }, [onValuesUpdate, selectedIssueFromUrl, issue, claim?.orderId]);

  return { issues };
};
