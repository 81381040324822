import { useQuery } from 'react-query';

import { OwnerService, WorkflowService } from 'myaccount/api';

interface UseLoadOrderArgs {
  ownerId: string;
}

const GetOrder = 'getOrder';
const GetOpenedFiles = 'getOpenedFiles';
const GetClosedFiles = 'getClosedFiles';

const useLoadOrder = ({ ownerId }: UseLoadOrderArgs) => {
  const { data: orders, isLoading: isOrderLoading } = useQuery(
    [GetOrder, { ownerId }],
    async () => {
      const response = await OwnerService.getOrderByOwnerId({
        ownerId,
      });

      if (response.failure) {
        return undefined;
      }

      return response.value;
    },
    { staleTime: Infinity, enabled: !!ownerId },
  );

  const { data: openedFiles, isLoading: isOpenfilesLoading } = useQuery(
    [GetOpenedFiles, { ownerId }],
    async () => {
      const response = await WorkflowService.getOpenedFilesQuery({
        ownerId,
      });

      if (response.failure) {
        return undefined;
      }

      return response.value;
    },
    { staleTime: Infinity, enabled: !!ownerId },
  );

  const { data: closedFiles, isLoading: isClosedfilesLoading } = useQuery(
    [GetClosedFiles, { ownerId }],
    async () => {
      const response = await WorkflowService.getClosedFilesQuery({
        ownerId,
      });

      if (response.failure) {
        return undefined;
      }

      return response.value;
    },
    { staleTime: Infinity, enabled: !!ownerId },
  );

  const isLoading =
    isOrderLoading || isOpenfilesLoading || isClosedfilesLoading;

  return {
    orders: orders || [],
    openedFiles: openedFiles || [],
    closedFiles: closedFiles || [],
    isLoading,
  };
};

export default useLoadOrder;
