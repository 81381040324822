import { useQuery } from 'react-query';

import { useToasts } from '@savgroup-front-common/core/src/molecules/NotificationsProvider';
import {
  ENTITY_TYPE,
  PAYMENT_METHOD,
  PAYMENT_STATUS,
} from '@savgroup-front-common/types';
import { PaymentService } from 'myaccount/api';

import { PaymentStatus } from '../../../../../components/Payments/CashRegisterPayment/CashRegisterPayment.types';

interface UseGetPaymentUrlReturnArgs {
  claimGroupId?: string;
  paymentUrl?: string;
}

const useGetPaymentStatus = ({
  claimGroupId,
  paymentUrl,
}: UseGetPaymentUrlReturnArgs): PaymentStatus | undefined => {
  const { pushErrors, removeAllNotifications } = useToasts();

  const { data: paymentStatus } = useQuery(
    ['useGetPaymentStatus', { claimGroupId, paymentUrl }],
    async () => {
      if (!claimGroupId) {
        return undefined;
      }

      removeAllNotifications();

      const createPaymentResponse = await PaymentService.createPaymentCommand({
        entityId: claimGroupId,
        entityType: ENTITY_TYPE.CLAIM_GROUP,
        paymentMethod: PAYMENT_METHOD.EXTERNAL_WEBSITE,
      });

      if (createPaymentResponse.failure) {
        pushErrors(createPaymentResponse.errors);

        return undefined;
      }

      const lastPaymentResponse =
        await PaymentService.getClaimGroupLastPaymentQuery({
          claimGroupId,
        });

      if (lastPaymentResponse.failure) {
        pushErrors(lastPaymentResponse.errors);

        return undefined;
      }

      if (lastPaymentResponse.value?.status !== PAYMENT_STATUS.CREATED) {
        return { status: PAYMENT_STATUS.CREATED };
      }
      const issuePaymentResponse = await PaymentService.issuePaymentCommand({
        paymentId: lastPaymentResponse.value.paymentId,
      });

      if (issuePaymentResponse.failure) {
        pushErrors(issuePaymentResponse.errors);

        return undefined;
      }

      return {
        status: PAYMENT_STATUS.CREATED,
        paymentUrlWithExternalId: paymentUrl
          ?.replace('{externalId}', issuePaymentResponse.value)
          .replace(
            '{callback}',
            window.location.href.concat('?paymentConfirmed'),
          ),
      };
    },
    {
      staleTime: Infinity,
      suspense: true,
    },
  );

  return paymentStatus;
};

export default useGetPaymentStatus;
