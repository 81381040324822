import get from 'lodash/get';

const { hostname } = window.location;

const clientIdByHostname: Record<string, string> = {
  'dev-etam-newui.revers.io': 'dev-etam.revers.io',
  'dev-myaccount.revers.io': 'dev-revers.io',
  'qualif-myaccount.revers.io': 'dev-revers.io',
  'dev-newui.revers.io': 'dev-revers.io',
  'sav.dev.auchan.fr': 'dev-auchan.revers.io',
  'qualif-ocp.revers.io': 'dev-ocp.revers.io',
};

const authorityByHostname: Record<string, string> = {
  'dev-auchan.revers.io': 'https://dev-auchan-login.revers.io',
  'sav.dev.auchan.fr': 'https://sav-login.dev.auchan.fr',
  'qualif-ocp.revers.io': 'https://dev-ocp-login.revers.io',
};

const authorityByClientId: Record<string, string> = {
  'dev-revers.io': 'https://dev-myaccount-login.revers.io',
  'dev-auchan.revers.io': 'https://sav-login.dev.auchan.fr',
  'dev-ocp.revers.io': 'https://dev-ocp-login.revers.io',
  'reversio.dev.revers.io': 'https://reversio-login.dev.revers.io',
};

const defaultClientId = 'dev-revers.io';

function computeClientId() {
  if (import.meta.env.VITE_CLIENTID) {
    return import.meta.env.VITE_CLIENTID;
  }

  if (
    hostname !== 'localhost' &&
    !hostname.includes('.dev-selfcare.pages.dev')
  ) {
    return clientIdByHostname[hostname] || window.location.hostname;
  }

  return defaultClientId;
}

const clientId = computeClientId();

function setAuthority(authority: string | undefined = undefined) {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { clientId } = this;
  const defaultAuthority = authorityByClientId[clientId];

  const defaultAuthorityFromHostname = get(authorityByHostname, hostname);

  if (hostname.includes('.dev-selfcare.pages.dev')) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.authority = 'https://dev-myaccount-login.revers.io';

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return this;
  }

  if (hostname !== 'localhost' && defaultAuthorityFromHostname) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.authority = defaultAuthorityFromHostname;

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return this;
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  this.authority = authority || defaultAuthority;

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return this;
}

const AuthConfiguration = {
  clientId,
  authority: authorityByClientId[clientId],
  scope: 'openid email api',
  setAuthority,
};

export default AuthConfiguration;
