import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';

import {
  NOTIFICATION_TYPES,
  REVALIDATE_MODES,
} from '@savgroup-front-common/constants';
import { buildNotification } from '@savgroup-front-common/core/src/helpers';
import { useToasts } from '@savgroup-front-common/core/src/molecules/NotificationsProvider';
import { useRoutedStepsOrchestratorContext } from '@savgroup-front-common/core/src/molecules/RoutedStepsOrchestrator/RoutedStepsOrchestrator.context';
import { RoutedStepProps } from '@savgroup-front-common/core/src/molecules/RoutedStepsOrchestrator/RoutedStepsOrchestrator.types';
import { ADDITIONAL_INFORMATION_TYPES } from '@savgroup-front-common/types';
import {
  AdditionalInformation,
  AdditionalInformationExtended,
  RELATED_TO,
} from 'myaccount/types';

import useGetClaimGroupSummary from '../hooks/useGetClaimGroupSummary';
import { IrshStepValues } from '../IrshPages.types';

import { uploadAdditionalInformationFilesToClaim } from './helpers/document.adapters';
import { reasonAdapter } from './helpers/reason.adapters';
import messages from './messages';
import newClaimGroupDocumentPageRelatedToClaimSchema from './NewClaimGroupDocumentPageRelatedToClaim.schema';
import { ClaimGroupDocumentRelatedToClaimValues } from './NewClaimGroupDocumentPageRelatedToClaim.types';

interface Args {
  onNextStep: RoutedStepProps<IrshStepValues>['onNextStep'];
}

const useClaimGroupDocumentRelatedToClaim = ({ onNextStep }: Args) => {
  const { pushNotification, removeAllNotifications, pushErrors } = useToasts();

  const { values } = useRoutedStepsOrchestratorContext<IrshStepValues>();
  const claimGroupId = values?.claimGroupId;

  const { claims } = useGetClaimGroupSummary({
    claimGroupId,
    suspense: true,
  });

  const reason = values?.reason;
  const isMultiProduct = claims ? claims?.length > 1 : undefined;

  const reasonSelectedAdapted = reasonAdapter({
    reasonSelected: reason,
    claimIds: claims?.map((claim) => claim.claimId) || [],
  });

  const filterFilePredicate = (item: AdditionalInformationExtended) =>
    item.type === ADDITIONAL_INFORMATION_TYPES.FILE ||
    item.type === ADDITIONAL_INFORMATION_TYPES.MULTI_FILES;

  const neededInformationOnlyFileType =
    isMultiProduct && reasonSelectedAdapted
      ? reasonSelectedAdapted?.neededInformation
          ?.filter(filterFilePredicate)
          ?.filter((item) => item.relatedTo === RELATED_TO.CLAIM)
      : reasonSelectedAdapted?.neededInformation?.filter(filterFilePredicate);

  const formContext = useForm<ClaimGroupDocumentRelatedToClaimValues>({
    resolver: yupResolver(
      newClaimGroupDocumentPageRelatedToClaimSchema({
        reasonSelected: reasonSelectedAdapted,
        isMultiProduct,
      }),
    ),
    mode: REVALIDATE_MODES.ON_CHANGE,
  });

  const { handleSubmit } = formContext;

  const {
    mutateAsync: handleUploadDocument,
    isLoading: isLoadingUploadDocument,
  } = useMutation(
    ['setUploadDocument'],
    async ({
      neededInformations,
      reasonAdditionalInformationDocument,
      claimId,
    }: {
      neededInformations?: ({
        internalId: string;
        fileUploadEndpoints?: Record<string, { uploadEndpoint: string }>;
      } & AdditionalInformation)[];
      reasonAdditionalInformationDocument?: Record<
        string,
        { value: File } | { value: File }[]
      >;
      claimId: string;
    }) => {
      removeAllNotifications();
      const responseAdditionalInformationDocument =
        await uploadAdditionalInformationFilesToClaim({
          neededInformations,
          reasonAdditionalInformationDocument,
          claimId,
        });

      if (responseAdditionalInformationDocument?.some((res) => res.failure)) {
        pushNotification(
          buildNotification({
            message: messages.uploadDocumentError,
            notificationType: NOTIFICATION_TYPES.ERROR,
          }),
        );

        return undefined;
      }

      return responseAdditionalInformationDocument;
    },
  );

  const onSubmit = handleSubmit(async (reasonDocument) => {
    if (!claims) {
      return undefined;
    }

    try {
      await Promise.all(
        claims.map(async (claim) => {
          const responseAdditionalInformationDocument =
            await handleUploadDocument({
              neededInformations: neededInformationOnlyFileType,
              reasonAdditionalInformationDocument:
                reasonDocument.reasonAdditionalInformationDocumentRelatedToClaim as any,
              claimId: claim.claimId,
            });

          if (!responseAdditionalInformationDocument) {
            return undefined;
          }

          return responseAdditionalInformationDocument;
        }),
      );

      onNextStep({});
    } catch (err: any) {
      pushErrors(err);

      return undefined;
    }
  });

  return {
    neededInformationOnlyFileType,
    formContext,
    onSubmit,
    isLoadingSubmit: isLoadingUploadDocument,
  };
};

export default useClaimGroupDocumentRelatedToClaim;
