import React from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';

import {
  FormGroup,
  Textarea,
} from '@savgroup-front-common/core/src/atoms/Form';
import { useRoutedStepsOrchestratorContext } from '@savgroup-front-common/core/src/molecules/RoutedStepsOrchestrator/RoutedStepsOrchestrator.context';

import { IrshStepValues } from '../IrshPages.types';

import messages from './messages';
import { ClaimGroupReasonValues } from './NewClaimGroupReasonPage.types';

interface ClaimGroupReasonPageCommentContentProps {
  formContext: UseFormReturn<ClaimGroupReasonValues>;
}

const ClaimGroupReasonPageCommentContent: React.FC<
  ClaimGroupReasonPageCommentContentProps
> = ({ formContext }) => {
  const { values } = useRoutedStepsOrchestratorContext<IrshStepValues>();

  const reason = values?.reason;

  const { control } = formContext;

  if (!reason?.mandatoryComment) {
    return <></>;
  }

  return (
    <FormGroup>
      <Controller
        control={control}
        name="reasonComment"
        render={({ field }) => (
          <Textarea
            isRequired
            name={field.name}
            label={messages.reasonCommentLabel}
            value={field.value}
            onChange={field.onChange}
            dataTestId="comment-text-area"
            isScrollIntoView
          />
        )}
      />
    </FormGroup>
  );
};

export default ClaimGroupReasonPageCommentContent;
