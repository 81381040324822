import styled from 'styled-components';

import { Heading } from '@savgroup-front-common/core/src/atoms/Heading';

export const $ProductContainer = styled.div`
  display: flex;
  flex-direction: column;
  background: #f7f7f7;
  border-radius: 8px;
`;

export const $ProductHeader = styled(Heading)`
  padding: 24px 32px;
`;

export const $ProductBillingNumber = styled.div`
  span:first-child {
    font-weight: bold;
  }
`;
