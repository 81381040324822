import { opacify } from 'polished';
import styled, { css } from 'styled-components';

import { media } from '@savgroup-front-common/constants/src/shared';

import { ChevronLeftIcon, ChevronRightIcon } from '../../protons/icons';

export const LeftArrowButton = styled(ChevronLeftIcon)`
  position: absolute;
  top: 50%;
  left: -10px;
  transform: translateY(-50%);
  cursor: pointer;
  @media ${media.minWidth.md} {
    left: 10px;
  }
`;

export const RightArrowButton = styled(ChevronRightIcon)`
  position: absolute;
  top: 50%;
  right: -10px;
  transform: translateY(-50%);
  cursor: pointer;
  @media ${media.minWidth.md} {
    right: 10px;
  }
`;

export const CalendarDayBlock = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  & *::first-letter {
    text-transform: uppercase;
  }
`;

export const CalendarDayName = styled.span`
  color: ${({ theme }) => theme.colors.mainTextColor};
  font-weight: ${({ theme }) => theme.fonts.weight.bold};
`;

export const CalendarDayDate = styled('p')`
  color: ${({ theme }) => theme.colors.paragraphTextColor};
`;

const clickableMixin = ({ theme, clickable, active }: any) => {
  if (!clickable) {
    return css``;
  }

  return css`
    cursor: pointer;

    & {
      background-color: ${active
        ? theme.colors.primary
        : opacify(-0.9, theme.colors.primary)};
      color: ${active ? theme.colors.white : 'inherit'};
    }

    &:hover {
      background-color: ${active
        ? opacify(-0.3, theme.colors.primary)
        : opacify(-0.7, theme.colors.primary)};
    }
  `;
};

export const CalendarTimeBlock = styled.div<{
  $fullHeight?: boolean;
  clickable: boolean;
  active: boolean;
}>`
  border-radius: 0.25rem;

  transition: background-color 300ms ease, color 300ms ease-out;

  color: ${({ theme }) => theme.colors.headingTextColor};
  padding: 5px 10px;
  font-size: ${({ theme }) => theme.fonts.size.small};
  font-weight: ${({ theme }) => theme.fonts.weight.mediumFont};
  line-height: 15px;
  text-align: center;

  height: ${({ $fullHeight }) => ($fullHeight ? '100%' : null)};

  margin-bottom: 0.5rem;

  ${clickableMixin};

  & > div {
    @media ${media.minWidth.lg} {
      display: flex;
      justify-content: center;
      gap: 2px;
    }
  }
`;

export const TimeSlotSelectorContainer = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const $AppointmentCalendar = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

export const AppointmentCalendarContainer = styled.div`
  display: flex;
  width: 100%;
  gap: 2rem;
  min-height: 300px;
`;
