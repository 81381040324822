import React, { FC } from 'react';

import { CURRENCIES, HANDLING_TYPES } from '@savgroup-front-common/constants';
import { SafeFormattedMessageWithoutSpread } from '@savgroup-front-common/core/src/formatters';
import { AttachmentIcon } from '@savgroup-front-common/core/src/protons/icons';
import { ClaimGroupConfirmation } from 'myaccount/api/Claim/getClaimGroupConfirmationQuery';

import messages from './messages';
import {
  getDeliveryTitle,
  getDepositTitle,
  getDisplayPrice,
} from './ResumeCard.helpers';
import { useResumeCard } from './ResumeCard.hooks';
import {
  $ResumeCardContainer,
  $ResumePriceProduct,
  $ResumePriceProductInfoAmountLabel,
  $ResumePriceProductInfoAmountTotalDescriptoinTax,
  $ResumePriceProductInfoAmountTotalLabel,
  $ResumePriceProductInfoAmountTotalValue,
  $ResumePriceProductInfoAmountValue,
  $ResumePriceProductInfoAmountWrapper,
  $ResumeProductTitle,
  $ResumeProductTitleWrapper,
  $ResumeSeparator,
  $ResumeStateLabel,
  $ResumeStateProduct,
  $ResumeStateProductDocuments,
  $ResumeStateProductKeyValue,
  $ResumeStateProductModify,
  $ResumeStateProductReason,
  $ResumeStateValue,
  $ResumeStateValueDocument,
} from './ResumeCard.styles';
import ResumeCardHeader from './ResumeCardHeader/ResumeCardHeader';

const ResumeCard: FC<{
  claimGroupConfirmation?: ClaimGroupConfirmation;
  hasPrice?: boolean;
  hasDepositPrice?: boolean;
  hasDeliveryPrice?: boolean;
  customLinkModify?: string;
}> = ({
  claimGroupConfirmation,
  hasPrice = false,
  hasDepositPrice = false,
  hasDeliveryPrice = false,
  customLinkModify,
}) => {
  const {
    products,
    claims,
    description,
    pairLabelValue,
    pairStringKeyValues,
    documents,
    linkModify,
  } = useResumeCard({
    claimGroupConfirmation,
  });

  const price = getDisplayPrice(
    claimGroupConfirmation?.solutionPriceWithTax,
    claimGroupConfirmation?.solutionPriceWithTaxCurrencyCode as CURRENCIES,
    messages.free,
  );

  const depositPrice = getDisplayPrice(
    claimGroupConfirmation?.carrierDepositPriceWithTax,
    claimGroupConfirmation?.carrierDepositPriceWithTaxCurrencyCode as CURRENCIES,
    messages.free,
  );

  const deliveryPrice = getDisplayPrice(
    claimGroupConfirmation?.carrierDeliveryPriceWithTax,
    claimGroupConfirmation?.carrierDeliveryPriceWithTaxCurrencyCode as CURRENCIES,
    messages.free,
  );

  const totalPrice = getDisplayPrice(
    (hasPrice ? claimGroupConfirmation?.solutionPriceWithTax || 0 : 0) +
      (hasDepositPrice
        ? claimGroupConfirmation?.carrierDepositPriceWithTax || 0
        : 0) +
      (hasDeliveryPrice
        ? claimGroupConfirmation?.carrierDeliveryPriceWithTax || 0
        : 0),
    claimGroupConfirmation?.carrierDeliveryPriceWithTaxCurrencyCode as CURRENCIES,
  );

  return (
    <$ResumeCardContainer data-testid="ResumeCard">
      {products &&
        products?.map((product, index) => {
          const currentClaim = claims.find(
            (claim) => claim.ownerProductId === product.ownerProductId,
          );

          if (!product) {
            return <></>;
          }

          return (
            <ResumeCardHeader
              key={product.ownerProductId}
              product={product}
              currentClaim={currentClaim}
              index={index}
            />
          );
        })}

      <$ResumeStateProduct>
        <$ResumeProductTitleWrapper>
          <$ResumeProductTitle>
            <SafeFormattedMessageWithoutSpread
              message={messages.requestTitleResume}
            />
          </$ResumeProductTitle>
          <$ResumeStateProductModify href={customLinkModify ?? linkModify}>
            <SafeFormattedMessageWithoutSpread message={messages.modifyLink} />
          </$ResumeStateProductModify>
        </$ResumeProductTitleWrapper>

        <$ResumeStateProductReason data-testid="ResumeCard_reasonName">
          {claimGroupConfirmation?.claims[0].reasonName}
        </$ResumeStateProductReason>

        {pairLabelValue &&
          pairLabelValue.map(({ label, value }, index) => (
            <$ResumeStateProductKeyValue key={label}>
              <$ResumeStateLabel>
                <SafeFormattedMessageWithoutSpread message={label || ''} />
              </$ResumeStateLabel>
              <$ResumeStateValue data-testid={`ResumeCard_value_${index}`}>
                {value}
              </$ResumeStateValue>
            </$ResumeStateProductKeyValue>
          ))}
        {pairStringKeyValues &&
          pairStringKeyValues.map(({ label, value }, index) => (
            <$ResumeStateProductKeyValue key={label}>
              <$ResumeStateLabel>
                <SafeFormattedMessageWithoutSpread message={label || ''} />
              </$ResumeStateLabel>
              <$ResumeStateValue
                data-testid={`ResumeCard_StringKey_value_${index}`}
              >
                {value}
              </$ResumeStateValue>
            </$ResumeStateProductKeyValue>
          ))}
        <$ResumeStateProductKeyValue>
          <$ResumeStateLabel>
            <SafeFormattedMessageWithoutSpread message={messages.description} />
          </$ResumeStateLabel>
          <$ResumeStateValue data-testid="ResumeCard_description">
            {description ? `${description} / ` : ''}
            {
              claimGroupConfirmation?.claims[0].comments.find(
                (comment) => comment.type === 'ReasonComment',
              )?.content
            }
          </$ResumeStateValue>
        </$ResumeStateProductKeyValue>
        {documents.length > 0 && (
          <$ResumeStateProductDocuments>
            <$ResumeStateLabel>
              <SafeFormattedMessageWithoutSpread message={messages.documents} />
            </$ResumeStateLabel>
            <$ResumeStateValue>
              {documents.map((document, index) => (
                <$ResumeStateValueDocument key={`${document}_${index}`}>
                  <AttachmentIcon size="12px" />
                  {document}
                </$ResumeStateValueDocument>
              ))}
            </$ResumeStateValue>
          </$ResumeStateProductDocuments>
        )}
      </$ResumeStateProduct>

      {hasPrice && totalPrice && (
        <>
          <$ResumeSeparator />
          <$ResumePriceProduct>
            <$ResumeProductTitleWrapper>
              <$ResumeProductTitle>
                <SafeFormattedMessageWithoutSpread
                  message={messages.priceTitleResume}
                />
              </$ResumeProductTitle>
            </$ResumeProductTitleWrapper>
            {price && (
              <$ResumePriceProductInfoAmountWrapper>
                <$ResumePriceProductInfoAmountLabel>
                  {claimGroupConfirmation?.solutionTypeName && (
                    <SafeFormattedMessageWithoutSpread
                      message={claimGroupConfirmation?.solutionTypeName}
                    />
                  )}
                </$ResumePriceProductInfoAmountLabel>
                <$ResumePriceProductInfoAmountValue>
                  <SafeFormattedMessageWithoutSpread message={price} />
                </$ResumePriceProductInfoAmountValue>
              </$ResumePriceProductInfoAmountWrapper>
            )}
            {hasDepositPrice && depositPrice && (
              <$ResumePriceProductInfoAmountWrapper>
                <$ResumePriceProductInfoAmountLabel>
                  {claimGroupConfirmation?.carrierDepositProductType && (
                    <SafeFormattedMessageWithoutSpread
                      message={getDepositTitle(
                        claimGroupConfirmation?.carrierDepositProductType as HANDLING_TYPES,
                      )}
                    />
                  )}
                </$ResumePriceProductInfoAmountLabel>
                <$ResumePriceProductInfoAmountValue>
                  <SafeFormattedMessageWithoutSpread message={depositPrice} />
                </$ResumePriceProductInfoAmountValue>
              </$ResumePriceProductInfoAmountWrapper>
            )}
            {hasDeliveryPrice && deliveryPrice && (
              <$ResumePriceProductInfoAmountWrapper>
                <$ResumePriceProductInfoAmountLabel>
                  {claimGroupConfirmation?.carrierDeliveryProductType && (
                    <SafeFormattedMessageWithoutSpread
                      message={getDeliveryTitle(
                        claimGroupConfirmation?.carrierDeliveryProductType as HANDLING_TYPES,
                      )}
                    />
                  )}
                </$ResumePriceProductInfoAmountLabel>
                <$ResumePriceProductInfoAmountValue>
                  <SafeFormattedMessageWithoutSpread message={deliveryPrice} />
                </$ResumePriceProductInfoAmountValue>
              </$ResumePriceProductInfoAmountWrapper>
            )}
            <$ResumePriceProductInfoAmountWrapper>
              <$ResumePriceProductInfoAmountTotalLabel>
                <SafeFormattedMessageWithoutSpread message={messages.total} />
                <$ResumePriceProductInfoAmountTotalDescriptoinTax>
                  <SafeFormattedMessageWithoutSpread message={messages.tax} />
                </$ResumePriceProductInfoAmountTotalDescriptoinTax>
              </$ResumePriceProductInfoAmountTotalLabel>
              <$ResumePriceProductInfoAmountTotalValue>
                <SafeFormattedMessageWithoutSpread message={totalPrice} />
              </$ResumePriceProductInfoAmountTotalValue>
            </$ResumePriceProductInfoAmountWrapper>
          </$ResumePriceProduct>
        </>
      )}
    </$ResumeCardContainer>
  );
};

ResumeCard.displayName = 'ResumeCard';
export default ResumeCard;
