import React from 'react';

import { ClaimGroupDiagnosticTreePageReducerState } from '../../NewClaimGroupDiagnosticTreePage.reducer';

import { $Paragraphe, $Question } from './StepInformations.styles';

interface StepInformationsProps {
  currentDiagnosticTreeStep: ClaimGroupDiagnosticTreePageReducerState;
}

const StepInformations: React.FC<StepInformationsProps> = ({
  currentDiagnosticTreeStep,
}) => {
  return (
    <>
      {currentDiagnosticTreeStep?.currentStep?.title &&
        currentDiagnosticTreeStep.currentStep.title.length && (
          <$Question>{currentDiagnosticTreeStep.currentStep.title}</$Question>
        )}
      {currentDiagnosticTreeStep?.currentStep?.instructions &&
        currentDiagnosticTreeStep.currentStep.instructions.length && (
          <$Paragraphe>
            {currentDiagnosticTreeStep.currentStep.instructions}
          </$Paragraphe>
        )}
    </>
  );
};

export default StepInformations;
