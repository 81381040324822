import { Reason } from '@savgroup-front-common/types';

export const findNeededAdditionalInformationById = ({
  id,
  neededAdditionalInformations = [],
}: {
  id: string;
  neededAdditionalInformations?: Reason['neededInformation'];
}) => {
  return neededAdditionalInformations.find(
    (neededAdditionalInformation) => neededAdditionalInformation.id === id,
  );
};
