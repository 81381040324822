import { yupResolver } from '@hookform/resolvers/yup';
import { useContext } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';

import { REVALIDATE_MODES } from '@savgroup-front-common/constants';
import { useToasts } from '@savgroup-front-common/core/src/molecules/NotificationsProvider';
import { useRoutedStepsOrchestratorContext } from '@savgroup-front-common/core/src/molecules/RoutedStepsOrchestrator/RoutedStepsOrchestrator.context';
import { RoutedStepProps } from '@savgroup-front-common/core/src/molecules/RoutedStepsOrchestrator/RoutedStepsOrchestrator.types';
import {
  ADDITIONAL_INFORMATION_TYPES,
  ClaimContext,
  OrderProductSummary,
} from '@savgroup-front-common/types';
import { ClaimService } from 'myaccount/api';
import {
  AdditionalInformationExtended,
  Reason,
  RELATED_TO,
} from 'myaccount/types';

import { InitContext } from '../../../app/NewLayout/InitProvider/InitProvider.context';
import useGetClaimGroupSummary from '../hooks/useGetClaimGroupSummary';
import { IrshStepValues } from '../IrshPages.types';

import {
  prepareReasonAdditionalInformation,
  reasonAdapter,
} from './helpers/reason.adapters';
import claimGroupProductInfoSchema from './NewClaimGroupProductInfoPage.schema';
import { ClaimGroupProductInfoValues } from './NewClaimGroupProductInfoPage.types';

interface Args {
  onNextStep: RoutedStepProps<IrshStepValues>['onNextStep'];
}

const useClaimGroupProductInfo = ({ onNextStep }: Args) => {
  const { removeAllNotifications, pushErrors } = useToasts();

  const { orders } = useContext(InitContext);
  const { values } = useRoutedStepsOrchestratorContext<IrshStepValues>();
  const claimGroupId = values?.claimGroupId;

  const { claims } = useGetClaimGroupSummary({
    claimGroupId,
    suspense: true,
  });

  const reason = values?.reason;

  const firstClaim = claims?.at(0);

  const order = orders?.find(
    (order) => firstClaim && order.orderId === firstClaim.orderId,
  );

  const products = claims?.reduce<Record<string, OrderProductSummary>>(
    (acc, claim) => {
      const product = order?.products.find(
        (product) => product.ownerProductId === claim.ownerProductId,
      );

      if (!product) {
        return acc;
      }

      return {
        ...acc,
        [claim.ownerProductId]: product,
      };
    },
    {},
  );

  const claimIds = claims?.map((claim) => claim.claimId) || [];

  const neededInformationOnlyRelatedToProduct = claims?.reduce<
    AdditionalInformationExtended[]
  >((acc, curr) => {
    const adaptedReason = reasonAdapter({
      reasonSelected: reason as Reason,
      claimIds,
      currentClaimId: curr.claimId,
    });

    const neededInformation = adaptedReason?.neededInformation
      .filter((item) => item.type !== ADDITIONAL_INFORMATION_TYPES.FILE)
      .filter((item) => item.relatedTo === RELATED_TO.PRODUCT);

    return [...acc, ...neededInformation];
  }, []);

  const adaptAdditionalInformation = (claim: ClaimContext) => {
    const { claimId } = claim;

    return claim.additionalClaimInformation?.reduce((acc, curr) => {
      if (curr.additionalInformationEnumValue) {
        return {
          ...acc,
          [`${curr.additionalInformationId}_${claimId}`]: {
            value: curr.additionalInformationEnumValue,
          },
        };
      }
      if (curr.additionalInformationStringValue) {
        return {
          ...acc,
          [`${curr.additionalInformationId}_${claimId}`]:
            curr.additionalInformationStringValue,
        };
      }

      return acc;
    }, {});
  };

  const formContext = useForm<ClaimGroupProductInfoValues>({
    resolver: yupResolver(
      claimGroupProductInfoSchema({
        claims,
        neededInformationOnlyRelatedToProduct,
      }),
    ),
    defaultValues: claims?.reduce((acc, curr) => {
      if (curr) {
        return {
          ...acc,
          [curr.claimId]: {
            reasonAdditionalInformationProductInfo:
              adaptAdditionalInformation(curr),
          },
        };
      }

      return acc;
    }, {}),
    mode: REVALIDATE_MODES.ON_CHANGE,
  });

  const { handleSubmit } = formContext;

  const {
    mutateAsync: handleAdditionalInformation,
    isLoading: isLoadingAdditionalInformation,
  } = useMutation(
    ['setAdditionalInformation'],
    async ({
      claimId,
      additionalClaimInformationValues,
    }: {
      claimId: string;
      additionalClaimInformationValues: { id: string; value: string }[];
    }) => {
      removeAllNotifications();
      const responseAdditionalInformation =
        await ClaimService.setClaimAdditionalInformation({
          claimId,
          additionalClaimInformationValues,
        });

      if (responseAdditionalInformation.failure) {
        pushErrors(responseAdditionalInformation.errors);

        return undefined;
      }

      return responseAdditionalInformation;
    },
  );

  const onSubmit = handleSubmit(async (reasonsResult) => {
    if (!claims) {
      throw new Error('Claims are not defined');
    }

    try {
      await Promise.all(
        claims.map(async (claim) => {
          const neededInformationOnlyRelatedToProductWithoutFile =
            neededInformationOnlyRelatedToProduct
              ?.filter(
                (item) => item.type !== ADDITIONAL_INFORMATION_TYPES.FILE,
              )
              .filter((item) => item.relatedTo === RELATED_TO.PRODUCT);

          const responseAdditionalInformation =
            await handleAdditionalInformation({
              claimId: claim.claimId,
              additionalClaimInformationValues:
                prepareReasonAdditionalInformation(
                  claim.claimId,
                  reasonsResult[claim.claimId]
                    ?.reasonAdditionalInformationProductInfo,
                  neededInformationOnlyRelatedToProductWithoutFile,
                ),
            });

          if (!responseAdditionalInformation) {
            return undefined;
          }

          return responseAdditionalInformation;
        }),
      );

      onNextStep({});

      return undefined;
    } catch (err: any) {
      pushErrors(err);

      return undefined;
    }
  });

  return {
    claims,
    products,
    neededInformationOnlyRelatedToProduct,
    formContext,
    onSubmit,
    isLoadingSubmit: isLoadingAdditionalInformation,
  };
};

export default useClaimGroupProductInfo;
