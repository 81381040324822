import { yupResolver } from '@hookform/resolvers/yup';
import { useContext } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';

import {
  NOTIFICATION_TYPES,
  REVALIDATE_MODES,
} from '@savgroup-front-common/constants';
import { buildNotification } from '@savgroup-front-common/core/src/helpers';
import { useToasts } from '@savgroup-front-common/core/src/molecules/NotificationsProvider';
import { useRoutedStepsOrchestratorContext } from '@savgroup-front-common/core/src/molecules/RoutedStepsOrchestrator/RoutedStepsOrchestrator.context';
import { RoutedStepProps } from '@savgroup-front-common/core/src/molecules/RoutedStepsOrchestrator/RoutedStepsOrchestrator.types';
import {
  ADDITIONAL_INFORMATION_TYPES,
  FullClaim,
  OrderProductSummary,
} from '@savgroup-front-common/types';
import {
  AdditionalInformation,
  AdditionalInformationExtended,
  RELATED_TO,
} from 'myaccount/types';

import { InitContext } from '../../../app/NewLayout/InitProvider/InitProvider.context';
import useGetClaimGroupSummary from '../hooks/useGetClaimGroupSummary';
import { IrshStepValues } from '../IrshPages.types';

import { uploadAdditionalInformationFilesToClaim } from './helpers/document.adapters';
import { reasonAdapter } from './helpers/reason.adapters';
import messages from './messages';
import newClaimGroupDocumentPageRelatedToProductSchema from './NewClaimGroupDocumentPageRelatedToProduct.schema';
import { ClaimGroupDocumentRelatedToProductValues } from './NewClaimGroupDocumentPageRelatedToProduct.types';

interface Args {
  onNextStep: RoutedStepProps<IrshStepValues>['onNextStep'];
}

const useClaimGroupDocumentRelatedToProduct = ({ onNextStep }: Args) => {
  const { pushNotification, removeAllNotifications, pushErrors } = useToasts();

  const { orders } = useContext(InitContext);
  const { values } = useRoutedStepsOrchestratorContext<IrshStepValues>();
  const claimGroupId = values?.claimGroupId;

  const { claims } = useGetClaimGroupSummary({
    claimGroupId,
    suspense: true,
  });

  const reason = values?.reason;

  const firstClaim = claims?.at(0);

  const order = orders?.find(
    (order) => firstClaim && order.orderId === firstClaim.orderId,
  );

  const products = claims?.reduce<Record<string, OrderProductSummary>>(
    (acc, claim) => {
      const product = order?.products.find(
        (product) => product.ownerProductId === claim.ownerProductId,
      );

      if (!product) {
        return acc;
      }

      return {
        ...acc,
        [claim.ownerProductId]: product,
      };
    },
    {},
  );

  const filterFilePredicate = (item: AdditionalInformationExtended) =>
    item.type === ADDITIONAL_INFORMATION_TYPES.FILE ||
    item.type === ADDITIONAL_INFORMATION_TYPES.MULTI_FILES;

  const neededInformationDocumentOnlyRelatedToProduct = claims?.reduce<
    AdditionalInformationExtended[]
  >((acc, curr) => {
    const adaptedReason = reasonAdapter({
      reasonSelected: reason,
      currentClaimId: curr.claimId,
    });

    const neededInformation = adaptedReason?.neededInformation
      ?.filter(filterFilePredicate)
      .filter((item) => item.relatedTo === RELATED_TO.PRODUCT);

    return [...acc, ...(neededInformation ?? [])];
  }, []);

  const adaptAdditionalInformation = (claim: FullClaim) => {
    const { claimId } = claim;

    return claim.additionalClaimInformation?.reduce((acc, curr) => {
      if (curr.additionalInformationFileValue) {
        return {
          ...acc,
          [`${curr.additionalInformationId}_${claimId}`]: {
            value: {
              name: curr.additionalInformationFileValue.fileName,
            },
          },
        };
      }

      return acc;
    }, {});
  };

  const formContext = useForm<ClaimGroupDocumentRelatedToProductValues>({
    resolver: yupResolver(
      newClaimGroupDocumentPageRelatedToProductSchema({
        claims: claims as any,
        neededInformationDocumentOnlyRelatedToProduct,
      }),
    ),
    defaultValues: claims?.reduce((acc, curr: any) => {
      if (curr) {
        return {
          ...acc,
          [curr.claimId]: {
            reasonAdditionalInformationDocumentRelatedToProduct:
              adaptAdditionalInformation(curr),
          },
        };
      }

      return acc;
    }, {}),
    mode: REVALIDATE_MODES.ON_CHANGE,
  });

  const { handleSubmit } = formContext;

  const {
    mutateAsync: handleUploadDocument,
    isLoading: isLoadingUploadDocument,
  } = useMutation(
    ['setUploadDocument'],
    async ({
      neededInformations,
      reasonAdditionalInformationDocument,
      claimId,
    }: {
      neededInformations?: ({
        internalId: string;
        fileUploadEndpoints: Record<string, { uploadEndpoint: string }>;
      } & AdditionalInformation)[];
      reasonAdditionalInformationDocument?: Record<string, unknown>;
      claimId: string;
    }) => {
      removeAllNotifications();
      const responseAdditionalInformationDocument =
        await uploadAdditionalInformationFilesToClaim({
          neededInformations,
          reasonAdditionalInformationDocument,
          claimId,
        });

      if (responseAdditionalInformationDocument?.some((res) => res.failure)) {
        pushNotification(
          buildNotification({
            message: messages.uploadDocumentError,
            notificationType: NOTIFICATION_TYPES.ERROR,
          }),
        );

        return undefined;
      }

      return responseAdditionalInformationDocument;
    },
  );

  const onSubmit = handleSubmit(async (reasonsResult) => {
    try {
      if (!claims) {
        throw new Error('No claims found');
      }

      const responses = (
        await Promise.all(
          claims.map(async (claim) => {
            const neededInformationOnlyRelatedToProductWithFile =
              neededInformationDocumentOnlyRelatedToProduct?.filter(
                (item) =>
                  item.type === ADDITIONAL_INFORMATION_TYPES.FILE &&
                  item.relatedTo === RELATED_TO.PRODUCT,
              );

            const responseAdditionalInformationDocument =
              await handleUploadDocument({
                neededInformations:
                  neededInformationOnlyRelatedToProductWithFile as any,
                reasonAdditionalInformationDocument: reasonsResult[
                  claim.claimId
                ].reasonAdditionalInformationDocumentRelatedToProduct as any,
                claimId: claim.claimId,
              });

            if (!responseAdditionalInformationDocument) {
              return undefined;
            }

            return responseAdditionalInformationDocument;
          }),
        )
      ).flat();

      const someResponseFailed = responses.some(
        (response) => response && response?.failure,
      );

      if (someResponseFailed) {
        return undefined;
      }

      onNextStep({});
    } catch (err: any) {
      pushErrors(err);

      return undefined;
    }
  });

  return {
    claims,
    products,
    neededInformationDocumentOnlyRelatedToProduct,
    formContext,
    onSubmit,
    isLoadingSubmit: isLoadingUploadDocument,
  };
};

export default useClaimGroupDocumentRelatedToProduct;
