import styled from 'styled-components';

export const $ProductContainer = styled.div`
  display: flex;
  flex-direction: column;
  background: #f7f7f7;
  border-radius: 8px;
`;

export const $ProductHeader = styled.div`
  padding: 24px 32px;
  color: ${({ theme }) => theme.colors.mainTextColor};
`;

export const $ProductBillingNumber = styled.div`
  span:first-child {
    font-weight: bold;
  }
`;
