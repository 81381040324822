import React, { PropsWithChildren, useContext } from 'react';
import { Row } from 'react-styled-flexboxgrid';

import { StepContext } from '../StepsProvider/StepsProvider.context';

import StepFooter from './StepFooter/StepFooter';
import StepHeader from './StepHeader/StepHeader';
import { $ColContainer, $StepContainer } from './StepLayout.styles';

interface StepLayoutProps {
  onSubmit?: () => void;
  noConfirmButton?: boolean;
  isFinalStep?: boolean;
  hasFooter?: boolean;
  isLoading?: boolean;
  minHeight?: boolean;
  isStepEmpty?: boolean;
}

const StepLayout: React.FC<PropsWithChildren<StepLayoutProps>> = ({
  children,
  onSubmit = () => undefined,
  noConfirmButton = false,
  isFinalStep = false,
  hasFooter = true,
  isLoading = false,
  minHeight = undefined,
  isStepEmpty = false,
}) => {
  const { form, handlePreviousStep, isValidatedStep, enableServicePortal } =
    useContext(StepContext);

  const shouldDisplayFooter = hasFooter && !isFinalStep;

  return (
    <Row center="xs">
      <$ColContainer xs={12} sm={12} enableServicePortal={enableServicePortal}>
        {form?.currentStep?.stepName && (
          <StepHeader
            stepName={form?.currentStep.stepName}
            isFinalStep={isFinalStep}
            isStepEmpty={isStepEmpty}
          />
        )}

        <$StepContainer $minHeight={minHeight}>{children}</$StepContainer>

        {shouldDisplayFooter && (
          <StepFooter
            onSubmit={onSubmit}
            handlePreviousStep={handlePreviousStep}
            isDisabled={!isValidatedStep}
            noConfirmButton={noConfirmButton}
            isLoading={isLoading}
            enableServicePortal={enableServicePortal}
          />
        )}
      </$ColContainer>
    </Row>
  );
};

StepLayout.displayName = 'StepLayout';

export default StepLayout;
