import React, { createContext, PropsWithChildren } from 'react';

import {
  FileShortSummary,
  Login,
  OrderSummary,
} from '@savgroup-front-common/types';

export type InitContextValues = {
  ownerId: string | undefined;
  login: Login | undefined;
  orders?: OrderSummary[];
  openedFiles: FileShortSummary[];
  closedFiles: FileShortSummary[];
  enableServicePortal: boolean;
};

export const InitContext = createContext<InitContextValues>({
  ownerId: undefined,
  login: undefined,
  orders: [],
  openedFiles: [],
  closedFiles: [],
  enableServicePortal: true,
});

interface InitContextProps {
  ownerId: string;
  login: Login;
  orders?: OrderSummary[];
  openedFiles: FileShortSummary[];
  closedFiles: FileShortSummary[];
  enableServicePortal?: boolean;
}

const InitProvider: React.FC<PropsWithChildren<InitContextProps>> = ({
  ownerId,
  login,
  orders,
  openedFiles,
  closedFiles,
  enableServicePortal = false,
  children,
}) => {
  return (
    <InitContext.Provider
      value={{
        ownerId,
        login,
        orders,
        openedFiles,
        closedFiles,
        enableServicePortal,
      }}
    >
      {children}
    </InitContext.Provider>
  );
};

export default InitProvider;
