import * as Yup from 'yup';

import { AdditionalInformationSchema } from '@savgroup-front-common/core/src/components/AdditionalInformationWithReactHookForm';
import { ClaimContext } from '@savgroup-front-common/types';
import { AdditionalInformationExtended } from 'myaccount/types';

const NewClaimGroupProductInfoPageSchema = ({
  claims,
  neededInformationOnlyRelatedToProduct,
}: {
  claims?: ClaimContext[];
  neededInformationOnlyRelatedToProduct?: AdditionalInformationExtended[];
}) => {
  const schema = claims?.reduce((acc, curr) => {
    if (curr) {
      const neededInformationOnlyRelatedToProductFiltered =
        neededInformationOnlyRelatedToProduct?.filter(
          (item) =>
            item.currentClaimId === curr.claimId &&
            item.claimIds.includes(curr.claimId),
        );

      const reasonAdditionalInformationSchema = AdditionalInformationSchema({
        neededInformationList: neededInformationOnlyRelatedToProductFiltered,
      });

      return {
        ...acc,
        [curr.claimId]: Yup.object().shape({
          reasonAdditionalInformationProductInfo:
            reasonAdditionalInformationSchema,
        }),
      };
    }

    return acc;
  }, {});

  return Yup.object().shape({
    ...schema,
  });
};

export default NewClaimGroupProductInfoPageSchema;
