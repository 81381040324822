import React from 'react';
import { Col } from 'react-styled-flexboxgrid';

import { STEP_CONFIG } from '../../ClassiqueRoutes.config';
import { PROFESSIONAL_STEP_CONFIG } from '../../ProfessionalRoutes.config';

import { $StepHeaderContainer } from './StepHeader.styles';
import StepProgress from './StepProgress/StepProgress';
import StepSubtitle from './StepSubtitle/StepSubtitle';
import StepTitle from './StepTitle/StepTitle';

interface StepHeaderProps {
  customSteps?: Record<string, string[]>[];
  stepName?: STEP_CONFIG | PROFESSIONAL_STEP_CONFIG;
  isFinalStep?: boolean;
  isStepEmpty?: boolean;
}

const StepHeader: React.FC<StepHeaderProps> = ({
  customSteps,
  stepName,
  isFinalStep = false,
  isStepEmpty = false,
}) => {
  if (!stepName) {
    return <>Error stepName</>;
  }

  return (
    <$StepHeaderContainer>
      <StepProgress stepName={stepName} customSteps={customSteps} />

      {!isFinalStep && !isStepEmpty && (
        <Col xs={12}>
          <StepTitle stepName={stepName} />
          <StepSubtitle stepName={stepName} />
        </Col>
      )}
    </$StepHeaderContainer>
  );
};

export default StepHeader;
