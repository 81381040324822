import { useRoutedStepsOrchestratorContext } from '@savgroup-front-common/core/src/molecules/RoutedStepsOrchestrator/RoutedStepsOrchestrator.context';
import { useGetSellerConfiguration } from 'myaccount/view/app/hooks';
import { useGetOpenedInvoiceWithClaimGroup } from 'myaccount/view/app/hooks/useGetOpenedInvoiceWithClaimGroup';

import { IrshStepValues } from '../../../IrshPages.types';

interface UsePaymentCardArgs {
  sellerId: string;
}

export const usePaymentCard = ({ sellerId }: UsePaymentCardArgs) => {
  const { values } = useRoutedStepsOrchestratorContext<IrshStepValues>();

  const claimGroupId = values?.claimGroupId;

  const { sellerConfiguration } = useGetSellerConfiguration({ sellerId });

  const { invoice } = useGetOpenedInvoiceWithClaimGroup({
    claimGroupId,
  });

  return {
    isCashRegisterConnected: sellerConfiguration?.isCashRegisterConnected,
    paymentUrl: sellerConfiguration?.paymentUrl,
    invoice,
  };
};
