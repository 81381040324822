import { defineMessages } from 'react-intl';

export default defineMessages({
  reference: {
    id: 'components.myaccount.multiProduct.reference',
    defaultMessage: 'Réference : {value}',
  },

  previousButton: {
    id: 'global.stepsOrchestrator.previousButton',
    defaultMessage: 'Previous',
  },
  nextButton: {
    id: 'view.myaccount.component.nextButton',
    defaultMessage: 'Next',
  },
});
