import styled from 'styled-components';

import { media } from '@savgroup-front-common/constants';

export const $Wrapper = styled.div`
  margin-top: 3.25rem;
`;

export const $Header = styled.div`
  display: flex;
  align-items: center;
  padding: 0 0.4rem;
`;

export const $FileHeader = styled.div`
  font-size: ${({ theme }) => theme.fonts.size.large};
  margin-bottom: 0.5rem;
  margin-right: 1rem;
  color: ${({ theme }) => theme.newUI.defaultColors.heading};
`;

export const $Divider = styled.div`
  width: 100%;
  height: 1px;
  background: #e6eaee;
  margin: 0.25rem 0 1.25rem;
`;

export const $DocumentsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 1.15rem 2rem;

  @media ${media.maxWidth.sm} {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 1.15rem 0;
  }
`;

export const $NoDocumentAvailable = styled.div`
  color: #8a94a6;
  padding: 0 0.5rem;
`;
