import React, { FC, Suspense } from 'react';

import { CURRENCIES } from '@savgroup-front-common/constants';
import { Skeleton } from '@savgroup-front-common/core/src/atoms/Skeleton';
import { SafeFormattedMessageWithoutSpread } from '@savgroup-front-common/core/src/formatters';
import { useRoutedStepsOrchestratorContext } from '@savgroup-front-common/core/src/molecules/RoutedStepsOrchestrator/RoutedStepsOrchestrator.context';
import { MyAccountIcon } from '@savgroup-front-common/core/src/protons/IconsNewDesign/MyAccount.icon';
import { ICONS_TYPE } from '@savgroup-front-common/types/src/Icon';

import { Payment } from '../../../../components/Payments/Payment';
import { IrshStepValues } from '../../IrshPages.types';
import {
  $Heading,
  $HeadingIcon,
} from '../NewClaimGroupConfirmationPage.styles';

import { ClaimGroupConfirmationCashRegisterPayment } from './ClaimGroupConfirmationCashRegisterPayment/ClaimGroupConfirmationCashRegisterPayment';
import { usePaymentCard } from './hooks/usePaymentCard';
import messages from './messages';

interface PaymentCardProps {
  sellerId: string;
  onSubmitSuccess?: () => void;
  isFormCompleted?: boolean;
}

const PaymentCard: FC<PaymentCardProps> = ({
  sellerId,
  onSubmitSuccess = () => undefined,
  isFormCompleted = true,
}) => {
  const { paymentUrl, invoice } = usePaymentCard({ sellerId });

  const { values } = useRoutedStepsOrchestratorContext<IrshStepValues>();

  const claimGroupId = values?.claimGroupId;

  return (
    <Suspense fallback={<Skeleton width="100%" height="20rem" />}>
      <$Heading>
        <$HeadingIcon>
          <MyAccountIcon icon={ICONS_TYPE.CART_ICON} />
        </$HeadingIcon>
        <SafeFormattedMessageWithoutSpread message={messages.paymentTitle} />
      </$Heading>

      {paymentUrl && claimGroupId && invoice && (
        <ClaimGroupConfirmationCashRegisterPayment
          paymentUrl={paymentUrl}
          claimGroupId={claimGroupId}
          invoice={{
            id: invoice.id,
            priceWithTax: invoice.priceWithTax,
            currencyTrigram:
              invoice.item?.at(0)?.currencyCode ?? CURRENCIES.EUR,
          }}
          isFormCompleted={isFormCompleted}
        />
      )}

      {!paymentUrl && invoice && claimGroupId && (
        <Payment
          invoice={{
            id: invoice.id,
            priceWithTax: invoice.priceWithTax,
            currencyTrigram:
              invoice.item?.at(0)?.currencyCode ?? CURRENCIES.EUR,
          }}
          onSubmitSuccess={onSubmitSuccess}
          isFormCompleted={isFormCompleted}
          claimGroupId={claimGroupId}
        />
      )}
    </Suspense>
  );
};

PaymentCard.displayName = 'PaymentCard';

export default PaymentCard;
