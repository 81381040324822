import { Reducer, useCallback, useReducer } from 'react';
import { useQuery } from 'react-query';

import { OrderDetails, Product } from '@savgroup-front-common/types';
import { OwnerService } from 'myaccount/api';

import ChooseProductPageContentReducer, {
  ChooseProductPageContentReducerAction,
  ChooseProductPageContentReducerState,
  initDiagnosticTreeState,
} from './ChooseProductPageContent.reducer';
import { PRODUCT_STATE } from './NewChooseProductPage.types';

const GET_ORDER_DETAIL = 'getOrderDetail';

interface UseChooseProductArgs {
  orderId: string;
  issue?: {
    id: string;
    winningWarrantyTypeId: string;
    name: string;
    didactic: string;
    fromProductWarranty: boolean;
    displayOrder: number;
    ownerProductIds?: string[];
    sellerProductIds?: string[];
    uniqueName: string;
    key: string;
    iconKey: string;
  };
}

interface UseChooseProductReturnValues {
  orderDetail: OrderDetails | undefined;
  isLoading: boolean;
  eligibleProducts: Product[];
  notEligibleProducts: Product[];
  notEligibleProductsFollowUpClaim: Product[];
  isGenericOrder?: boolean;
}

const useChooseProductContent = ({
  orderId,
  issue,
}: UseChooseProductArgs): UseChooseProductReturnValues => {
  const [chooseProductContentState, chooseProductContentStateDispatch] =
    useReducer<
      Reducer<
        ChooseProductPageContentReducerState,
        ChooseProductPageContentReducerAction
      >
    >(ChooseProductPageContentReducer, initDiagnosticTreeState());

  const handleProducts = useCallback(
    (orderDetail: OrderDetails) => {
      const eligibleProducts = orderDetail?.products.filter(
        (product: Product) =>
          issue?.ownerProductIds?.includes(product.ownerProductId) &&
          product.isAvailableForClaims,
      );
      const notEligibleProducts = orderDetail?.products.filter(
        (product: Product) =>
          issue?.ownerProductIds?.includes(product.ownerProductId) &&
          !product.isAvailableForClaims &&
          !product.claimGroupId,
      );
      const notEligibleProductsFollowUpClaim = orderDetail?.products.filter(
        (product: Product) =>
          issue?.ownerProductIds?.includes(product.ownerProductId) &&
          !product.isAvailableForClaims &&
          product.claimGroupId,
      );

      chooseProductContentStateDispatch({
        type: PRODUCT_STATE.UPDATE,
        payload: {
          eligibleProducts,
          notEligibleProducts,
          notEligibleProductsFollowUpClaim,
        },
      });
    },
    [issue],
  );

  const { data: orderDetail, isLoading } = useQuery(
    [GET_ORDER_DETAIL, { orderId }],
    async () => {
      const response = await OwnerService.getOrderDetailsQuery({
        orderId,
      });

      if (response.failure) {
        return undefined;
      }

      return response.value;
    },
    {
      enabled: !!orderId,
      onSuccess: (orderDetail) => orderDetail && handleProducts(orderDetail),
    },
  );

  return {
    orderDetail,
    isLoading,
    eligibleProducts: chooseProductContentState?.eligibleProducts || [],
    notEligibleProducts: chooseProductContentState?.notEligibleProducts || [],
    notEligibleProductsFollowUpClaim:
      chooseProductContentState?.notEligibleProductsFollowUpClaim || [],
    isGenericOrder: orderDetail?.isGenericOrder || false,
  };
};

export default useChooseProductContent;
